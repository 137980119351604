import { EmployeeCommutingFields, Transport } from '../../interface/formFields.interface'
import validateNumericInput from './number.validation'

export default function getErrors(values: EmployeeCommutingFields) {
  const errors: string[] = []
  const { transports } = values
  transports.forEach((transport: Transport, i: number) => {
    if (!transport.transportModeCode) {
      errors.push(`Mode of Transport ${i + 1} - Transport Type: Please select and option.`)
    }
    const validation = validateNumericInput(transport.value, { greaterThanZero: true })
    if (validation !== true) {
      errors.push(`Mode of Transport ${i + 1} - Travelled Distance: ${validation}`)
    }
  })
  return errors
}
