import { ElectricityConsumptionLocation } from './../../interface/formFields.interface'
import { ElectricityConsumptionFields } from '../../interface/formFields.interface'
import validateNumericInput from './number.validation'

export default function getErrors(values: ElectricityConsumptionFields) {
  const errors: string[] = []
  const { locations } = values
  locations.forEach((location: ElectricityConsumptionLocation, i: number) => {
    const { isSefKnown, supplierEmissionsFactor, value, gridCode } = location
    if (!gridCode) {
      errors.push(`Location ${i + 1} - Electricity grid:  Please select an option.`)
    }
    if (isSefKnown) {
      const supplierValidation = validateNumericInput(supplierEmissionsFactor)
      if (supplierValidation !== true) {
        errors.push(`Location ${i + 1} - Supplier Emissions Factor: ${supplierValidation}`)
      }
    }
    const valueValidation = validateNumericInput(value, { greaterThanZero: true })
    if (valueValidation !== true) {
      errors.push(`Location ${i + 1} - Electricity Consumption: ${valueValidation}`)
    }
  })
  return errors
}
