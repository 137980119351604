import { faHouse, faInfoCircle, faMobileScreen, faTree } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EmissionsOverviewResults } from '../../interface/result.interface'
import formatResult from '../../utils/formatResult'

export default function Overview({ data }: { data: EmissionsOverviewResults }) {
  return (
    <>
      <div className="sectionBox emissionsOverview" id="pdfOverview">
        <OverviewBox />
        <OverviewEquivalents />
      </div>
    </>
  )

  function OverviewBox() {
    return (
      <div className="infoBoxDark" id="pdfOverviewBox">
        <div className="totalEmissions" data-cy="total-emissions-value">
          {formatResult(Math.round(data.kg_CO2e), 0).toLocaleString()}
        </div>{' '}
        <div className="textLarge">Total Emissions</div>
        <div className="textLarge">kg CO2e</div>
        <div className="mt4 textSmall" id="pdfOverviewDisclaimer">
          <FontAwesomeIcon icon={faInfoCircle} />{' '}
          <span id="pdfOverviewDisclaimerText">
            This figure includes emissions from your operations, your purchased energy and your supply chain.
          </span>
        </div>
      </div>
    )
  }

  function OverviewEquivalents() {
    return (
      <div className="infoBox" id="pdfOverviewEquivalents">
        <div className="textLarge">This is equivalent to...</div>
        <div className="mt4">
          <FontAwesomeIcon icon={faTree} /> The CO2 absorbed by{' '}
          <span className="bold">
            {formatResult(data.results_text?.carbon_equivalents?.variables?.n_acres_forest, 2)}
          </span>{' '}
          acres of forest
        </div>
        <div className="mt4">
          <FontAwesomeIcon icon={faHouse} /> Powering{' '}
          <span className="bold">
            {formatResult(data.results_text?.carbon_equivalents?.variables?.n_homes_electricity, 2)}
          </span>{' '}
          houses for a year
        </div>
        <div className="mt4">
          <FontAwesomeIcon icon={faMobileScreen} /> Charging{' '}
          <span className="bold">
            {formatResult(data.results_text?.carbon_equivalents?.variables?.n_smart_phones, 2)}
          </span>{' '}
          smart phones
        </div>
      </div>
    )
  }
}
