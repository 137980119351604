import { useEffect, useImperativeHandle, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { getData, saveData } from '../../../api/reportStore'
import { useClient } from '../../../context/ClientProvider'
import { useRegion } from '../../../context/RegionProvider'
import { useSession } from '../../../context/SessionProvider'
import { purchasedGoodCategories } from '../../../data/inputs/purchasedGoods'
import getErrors from '../../../data/validation/purchasedGoods.validation'
import { PurchasedGood, PurchasedGoodsFields } from '../../../interface/formFields.interface'
import OpenBankingReview from '../../open-banking/OpenBankingReview'
import ReloadTransactionsButton from '../../ReloadTransactionsButton'
import SectionHeader from '../../shared/SectionHeader'
import CategoryItem from './components/CategoryItem'
import PurchasedGoodsInfoHeader from './components/PurchasedGoodsHeaderInfo'

const sectionName = 'PurchasedGoods'

export default function PurchasedGoods() {
  const { sessionId } = useSession()
  const { country } = useRegion()
  const { isOpenBankingEnabled, isFreeUser } = useClient()
  const navigate = useNavigate()
  const [transactionData, setTransactionData] = useState<any>({
    totalAmount: 0,
    totalKgCO2e: 0,
    categories: {},
  })
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [reloadTransactionsParams, setReloadTransactionsParams] = useState<{
    countryCode?: string
    reportingStartDate?: string
    reportingEndDate?: string
  }>({})
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)

  const { control, register, handleSubmit, getValues, watch } = useForm<PurchasedGoodsFields>({
    defaultValues: {
      purchasedGoods: initializeCategories(),
    },
  })

  const { fields, update } = useFieldArray({
    control,
    name: 'purchasedGoods',
  })
  function initializeCategories() {
    const categories: PurchasedGood[] = []
    for (let key in purchasedGoodCategories) {
      categories.push({
        spendCategoryCode: key,
        value: 0,
        currencyCode: country.defaultUnits.currency,
      })
    }
    return categories
  }

  function updateData(data: PurchasedGood[]) {
    const codes = fields.map((field: PurchasedGood) => field.spendCategoryCode)
    data.forEach((category: PurchasedGood) => {
      const idx = codes.indexOf(category.spendCategoryCode)
      if (idx !== -1) {
        update(idx, category)
      }
    })
  }

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      navigate('/?sessionId=' + sessionId)
    }
    getData(sessionId).then((data: any) => {
      const { countryCode, reportingStartDate, reportingEndDate } = data['CompanyInformation']
      setReloadTransactionsParams({ countryCode, reportingStartDate, reportingEndDate })
      const additionalSpends = data['PurchasedGoods']
      if (additionalSpends) {
        updateData(additionalSpends)
      }
      // sortCategoriesByMostSpend(data['Scope_3_1'], fields)
      addAdditionalSpendsToTransactionData(data['Scope_3_1'], additionalSpends || [])
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
    })
  }, [sessionId, update])

  useEffect(() => {
    const subscription = watch((data) => {
      const prevData = { ...transactionData }
      // Timeout to avoid blocking the input while the user is inserting the value
      setTimeout(() => {
        addAdditionalSpendsToTransactionData(prevData, data['purchasedGoods'] || [])
      }, 3000)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [watch, addAdditionalSpendsToTransactionData])

  function addAdditionalSpendsToTransactionData(txnData: any, additionalSpends: any) {
    additionalSpends.forEach((category: PurchasedGood) => {
      if (txnData?.categories?.[category.spendCategoryCode]) {
        txnData.categories[category.spendCategoryCode].additionalSpend = category.value
      } else {
        txnData.categories[category.spendCategoryCode] = {
          totalAmount: 0,
          totalKgCO2e: 0,
          additionalSpend: category.value,
        }
      }
    })
    return setTransactionData(txnData)
  }

  async function save(values: PurchasedGoodsFields, params?: { sectionCompleted: boolean }) {
    const data: any = {
      PurchasedGoods: values.purchasedGoods,
      Scope_3_1: transactionData,
    }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    await saveData(sessionId, data)
  }

  async function onSubmit(values: PurchasedGoodsFields, navigationOverride?: string) {
    const errors = getErrors(getValues())
    setErrors(errors)
    if (!errors.length) {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/review?sessionId=' + sessionId)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async function goBack() {
    const previousRoute = '/employee-commuting?sessionId' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  return (
    <div className="formContainer">
      <SectionHeader title="Purchased Goods and Services" errors={errors} />
      <PurchasedGoodsInfoHeader country={country} />
      {(isOpenBankingEnabled || isFreeUser) && <OpenBankingReview params={reloadTransactionsParams} />}
      <div className="sectionBox">
        <div className="pgSectionBody">
          {!isOpenBankingEnabled && (
            <div className="reloadButtonContainer mb8">
              <ReloadTransactionsButton params={reloadTransactionsParams} />
            </div>
          )}
          {fields.map((field, index) => (
            <div key={'category' + index}>
              <CategoryItem
                index={index}
                field={field}
                key={field.id}
                transactionData={transactionData}
                setTransactionData={setTransactionData}
                register={register}
              />
            </div>
          ))}
        </div>
      </div>
      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="buttonContainer">
          <button onClick={goBack} type="button">
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  )
}
