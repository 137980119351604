import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'

export default function ErrorBox({ errors }: { errors: string[] }) {
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (errors.length > 0) {
      setShow(true)
    }
  }, [errors])

  if (show) {
    return (
      <div className="floatingBox error" data-cy="error-box">
        <div className="boxHeader">
          <div>Please fix the following errors before moving to another section:</div>
          <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => setShow(false)} />
        </div>
        <ul>
          {errors.map((error: string, i: number) => (
            <li key={'error' + i}>{error}</li>
          ))}
        </ul>
      </div>
    )
  }
  return null
}
