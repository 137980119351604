import { useState } from 'react'
import { recommendations, categories, actions } from '../fixtures/recommendations'
import React from 'react'
import SectionHeader from './shared/SectionHeader'

export default function Insights() {
  const [categoryClicked, setCategoryClicked] = useState('Electronics/Applications')
  const [recommendationClicked, setRecommendationClicked] = useState('')
  const [openRecommendation, setOpenRecommendation] = useState(false)

  const openRecommendationWindow = () => {
    setOpenRecommendation(true)
  }

  const onCategoryClicked = (category: any) => {
    setCategoryClicked(category)
  }

  const onRecommendationClicked = (recommendation: any) => {
    setRecommendationClicked(recommendation)
    openRecommendationWindow()
  }

  const recommendationList = []

  const selectedRecommendations = recommendations[categoryClicked]

  for (let i = 0; i < selectedRecommendations.length; i += 3) {
    recommendationList.push(selectedRecommendations.slice(i, i + 3))
  }

  return (
      <div className="insightsContainer">
        <div className="categories">
          <div data-cy={'actionCategoryTitle'}>Action Categories</div>
          {categories?.ActionCategories.map((category: any, index: number) => (
            <div
              className={`categoryBox ${categoryClicked === category ? 'highlight' : ''}`}
              key={category}
              onClick={() => onCategoryClicked(category)}
              data-cy={`action-category-${index + 1}`}
            >
              <div>{category}</div>
            </div>
          ))}
          <div data-cy={'generalEducationTitle'}>General Education</div>
          {categories?.GeneralEducation.map((category: any, index: number) => (
            <div
              className={`categoryBox ${categoryClicked === category ? 'highlight' : ''}`}
              key={category}
              onClick={() => onCategoryClicked(category)}
              data-cy={`general-education-category-${index + 1}`}
            >
              <div>{category}</div>
            </div>
          ))}
        </div>
        <div className="recommendations">
          {recommendationList?.map((recommendationRow: any, recommendationIndex: number) => (
            <React.Fragment key={recommendationIndex}>
              {recommendationRow.map((recommendation: any, index: number) => (
                <div className="recommendationWrapper" key={index}>
                  <div
                    className={`actionBox ${recommendationClicked === recommendation ? 'highlight' : ''}`}
                    onClick={() => onRecommendationClicked(recommendation)}
                    data-cy={`action-box-${index + 1}`}
                  >
                    {recommendation}
                  </div>
                </div>
              ))}

              {openRecommendation && recommendationRow.includes(recommendationClicked) && <RecommendationPopup />}
            </React.Fragment>
          ))}
        </div>
      </div>
  )

  function RecommendationPopup() {
    return (
      <div className="recommendationBox" data-cy="recommendationPopup">
        <div className="recommendationHeader">
          <div>Potential Carbon Decrease</div>
          <div>Effort required</div>
          <div>Payback</div>
        </div>
        <div className="recommendationStats">
          <div>{`${Math.floor(Math.random() * 400)} kg CO2e`}</div>
          <div>Medium</div>
          <div>Medium</div>
        </div>
        <div className="recommendationContent">{actions[recommendationClicked]}</div>
      </div>
    )
  }
}
