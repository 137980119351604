import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'

export default function LockedTextBox({ dataCy }: { dataCy: string }) {
  return (
    <div className="lockedTextBox" data-cy={dataCy}>
      <ReactTooltip place="top" type="dark" effect="float" className="tooltip" id={'locked-box-id'} />
      <a href="https://connect.earth/contact-us/" target="_blank">
        <span data-tip="To unlock this feature, please contact the team at Connect Earth" data-for={'locked-box-id'}>
          <FontAwesomeIcon icon={faLock} size="4x" className="gray4Color" />
        </span>
      </a>
    </div>
  )
}
