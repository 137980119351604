import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRegion } from '../../../../context/RegionProvider'
import { processDelete } from '../../../../functions/transaction-data-processing'
import { MccGroup } from '../../../../interface/transactionData.interface'
import { formatMoney } from '../../../../utils/formatMoney'

export default function SubcategoryList({
  mccs,
  code,
  transactionData,
  setTransactionData,
}: {
  mccs: any
  code: string
  transactionData: any
  setTransactionData: any
}) {
  const { country } = useRegion()

  const processAction = (code: string, mcc: string) => {
    const prevData = { ...transactionData }
    const newData = processDelete(prevData, code, mcc)
    setTransactionData(newData)
  }
  if (mccs) {
    return (
      <div className="mccContainer">
        {mccs.map((mcc: [string, MccGroup]) => (
          <div key={code + mcc[0]} className={'mccItem ' + (mcc[1].userDeleted ? 'userDeleted' : '')}>
            <div className="name">{mcc[1].name}</div>
            <div className="spend">
              {formatMoney(mcc[1].spend)} {country.defaultUnits.currency}
            </div>
            <div className="action" onClick={() => processAction(code, mcc[0])}>
              {mcc[1].userDeleted ? (
                <FontAwesomeIcon icon={faPlusCircle} className="clickable gray6Color" />
              ) : (
                <FontAwesomeIcon icon={faTrash} className="clickable gray6Color" />
              )}
            </div>
          </div>
        ))}
      </div>
    )
  }
  return <div className="noTransactions">No transactions for this category.</div>
}
