import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { getPlaidUserAccounts } from '../../api/open-banking/getPlaidUserAccounts'
import { InstitutionsAccounts, Item } from '../../api/open-banking/interface/institutionsAccounts.interface'
import { useSession } from '../../context/SessionProvider'
import CollapsibleBox from '../boxes/CollapsibleBox'
import ReloadTransactionsButton from '../ReloadTransactionsButton'
import LockedTextBox from '../boxes/LockedTextBox'
import { useClient } from '../../context/ClientProvider'

export default function OpenBankingReview({ params }: any) {
  const { sessionId } = useSession()
  const { isFreeUser } = useClient()
  const exampleitem = {
    institution: {
      name: 'Example Bank',
    },
    item_id: 'test',
    request_id: 'test',
  }
  const [accountDataLoading, setAccountDataLoading] = useState<Boolean>(true)
  const [connectedItems, setConnectedItems] = useState<InstitutionsAccounts>([])

  useEffect(() => {
    const getConnectedItems = async () => {
      try {
        const items = await getPlaidUserAccounts(sessionId)
        items.push(exampleitem)
        setConnectedItems(items)
        setAccountDataLoading(false)
      } catch (error) {
        console.error(error)
      }
    }
    getConnectedItems()
  }, [sessionId])

  return (
    <>
      <CollapsibleBox
        header={<div className="textLarge mb-2">Connected Banks</div>}
        body={
          isFreeUser ? (
            <>
              <div className="textSmallSection">
                This section provides a review of your connected bank accounts through open banking
              </div>
              <LockedTextBox dataCy="open-banking-review-locked" />
            </>
          ) : (
            <>
              {accountDataLoading ? (
                <div className="mt2">Loading...</div>
              ) : !accountDataLoading && !connectedItems.length ? (
                <div className="mt2">No connected accounts yet.</div>
              ) : (
                <div className="institutionsGrid mt4 mb4">
                  {connectedItems.map((item: Item) => (
                    <PlaidItem item={item} key={item.institution.institution_id} />
                  ))}
                </div>
              )}
              <div className="buttonContainer">
                <ReloadTransactionsButton params={params} />
              </div>
            </>
          )
        }
      />
    </>
  )

  function PlaidItem({ item }: { item: Item }) {
    return (
      <div className="institutionBox">
        <div className="bold institutionTitle">
          {item.institution.logo ? (
            <img className="bankLogo" src={'data:image/png;base64,' + item.institution.logo} alt="" />
          ) : (
            <div className="bankLogo gray6Color">
              <FontAwesomeIcon icon={faBuildingColumns} size="2x"></FontAwesomeIcon>
            </div>
          )}
          {item.institution.name}
        </div>
      </div>
    )
  }
}
