export const modeOfTransports: { [key: string]: string } = {
  AVCAR: 'Car',
  MOTO: 'Motorcycle',
  LGV: 'Van',
  TAXI: 'Taxi',
  BUS: 'Bus',
  NATRAIL: 'National Rail',
  ITLRAIL: 'International Rail',
  TRANRAIL: 'Transit Rail (i.e. Underground, Tram)',
  AIRDOM: 'Air Travel - Domestic (UK)',
  AIRSHO: 'Air Travel - International Short Haul (< 3700 kilometres)',
  AIRLON: 'Air Travel - International Long Haul (>= 3700 kilometres)',
  FERR: 'Ferry',
}

export const employeeCommutingInputsUK = { modeOfTransports }
