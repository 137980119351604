import { countriesList } from '../../data/countriesList'
import { businessSectors } from '../../data/inputs/companyInformation'
import { areaUnits } from '../../data/units/areaUnits'
import { CompanyInformationFields } from '../../interface/formFields.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function CompanyInformationReview({ fields }: { fields: CompanyInformationFields }) {
  const {
    companyName,
    countryCode,
    businessSector,
    reportingStartDate,
    reportingEndDate,
    numberOfEmployees,
    yearlyRevenueValue,
    yearlyRevenueUnit,
    facilityArea,
    facilityAreaUnit,
  } = fields
  return (
    <CollapsibleBox
      header={<div className="subtitle">Company Information</div>}
      body={
        <div className="reviewTable">
          <div className="tableItem">Company Name</div>
          <div className="tableItem">
            {!!companyName ? companyName : <div className="notSpecified">Not specified</div>}
          </div>

          <div className="tableItem">Country</div>
          <div className="tableItem">
            {!!countryCode ? countriesList[countryCode].name : <div className="notSpecified">Not specified</div>}
          </div>

          <div className="tableItem">Business Sector</div>
          <div className="tableItem">
            {!!businessSector ? businessSectors[businessSector] : <div className="notSpecified">Not specified</div>}
          </div>

          <div className="tableItem">Reporting Year</div>
          <div className="tableItem">
            From {reportingStartDate} to {reportingEndDate}
          </div>

          <div className="tableItem">Number Of Employees</div>
          <div className="tableItem">
            {!!numberOfEmployees ? numberOfEmployees : <div className="notSpecified">Not specified</div>}
          </div>

          <div className="tableItem">Yearly Revenue</div>
          <div className="tableItem">
            {!!yearlyRevenueValue ? (
              yearlyRevenueValue + ' ' + yearlyRevenueUnit
            ) : (
              <div className="notSpecified">Not specified</div>
            )}
          </div>

          <div className="tableItem">Facility Area</div>
          <div className="tableItem">
            {!!facilityArea ? (
              facilityArea + ' ' + areaUnits[facilityAreaUnit]
            ) : (
              <div className="notSpecified">Not specified</div>
            )}
          </div>
        </div>
      }
    />
  )
}
