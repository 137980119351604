import axios from 'axios'
import { Client } from '../interface/reportClient.interface'

export function getClient(sessionId: string): Promise<Client | null> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/get/client',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        sessionId,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        console.error(`Error getting the client. Reason: ${err}`)
        resolve(null)
      })
  })
}
