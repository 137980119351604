import { purchasedGoodCategories } from '../../data/inputs/purchasedGoods'
import { PurchasedGood } from '../../interface/inputData.interface'
import formatResult from '../../utils/formatResult'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function PurchasedGoodsReview({ purchasedGoods }: { purchasedGoods: PurchasedGood[] }) {
  return (
    <CollapsibleBox
      header={<div className="subtitle">Purchased Goods and Services</div>}
      body={
        <>
          {!purchasedGoods.length ? (
            <div className="grayBox notSpecified">No purchased goods and services specified</div>
          ) : (
            <div className="reviewTable mt2">
              {purchasedGoods.map((category, index) => (
                <CategorySection category={category} key={'category' + index}/>
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function CategorySection({ category }: { category: PurchasedGood }) {
    const { spendCategoryCode, mccSpend, additionalSpend, currencyCode } = category
    return (
      <>
        <div className="tableHeader">{purchasedGoodCategories[spendCategoryCode]}</div>
        <div className="tableHeader"></div>

        <div className="tableItem">Estimated Spend (based on transactions)</div>
        <div className="tableItem">{formatResult(mccSpend, 2) + ' ' + currencyCode}</div>

        <div className="tableItem">Additional Spend</div>
        <div className="tableItem" data-cy={`add-spend-${spendCategoryCode}-table-item`}>{formatResult(additionalSpend, 2) + ' ' + currencyCode}</div>

        <div className="tableItem bold">Total Spend</div>
        <div className="tableItem bold">{formatResult(mccSpend + additionalSpend, 2) + ' ' + currencyCode}</div>
      </>
    )
  }
}
