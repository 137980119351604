import { useRegion } from '../../context/RegionProvider'
import { heatConsumptionMapping } from '../../data/inputs/heat-consumption/heat-consumption.mapping'
import { HeatConsumption } from '../../interface/inputData.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function HeatConsumptionReview({ locations }: { locations: HeatConsumption[] }) {
  const { country } = useRegion()
  const { heatConsumptionUnits, supplierEmissionsFactorUnits } = heatConsumptionMapping[country.code]

  return (
    <CollapsibleBox
      header={<div className="subtitle">Heat Consumption</div>}
      body={
        <>
          {!locations.length ? (
            <div className="grayBox notSpecified">No heat consumption specified</div>
          ) : (
            <div className="reviewTable mt2">
              {locations.map((location, index) => (
                <LocationSection location={location} index={index} key={'location' + index} />
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function LocationSection({ location, index }: { location: HeatConsumption; index: number }) {
    const { supplierEmissionsFactor, supplierUnitCode, value, unitCode } = location
    return (
      <>
        <div className="tableHeader" data-cy={`review-location-${index}-heat-consumption-header`}>
          Location {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Supplier Emissions Factor</div>
        <div className="tableItem">
          {supplierEmissionsFactor && supplierEmissionsFactor !== 'Unknown' ? (
            supplierEmissionsFactor + ' ' + supplierEmissionsFactorUnits[supplierUnitCode]
          ) : (
            <div className="notSpecified">Not specified</div>
          )}
        </div>

        <div className="tableItem">Heat Consumption</div>
        <div className="tableItem">
          {value ? value + ' ' + heatConsumptionUnits[unitCode] : <div className="notSpecified">Not specified</div>}
        </div>
      </>
    )
  }
}
