import { useClient } from '../../context/ClientProvider'
import { disclaimerList } from '../../data/disclaimerList'
import BoxHeader from '../boxes/BoxHeader'

export default function Disclaimer({ closeDisclaimer }: { closeDisclaimer: Function }) {
  const { companyName, appName } = useClient()

  return (
    <BoxHeader closePopup={closeDisclaimer} subtitle={`${companyName || 'Connect Earth'} Carbon Footprint Calculator`}>
      <div className="grayBox">
        {disclaimerList(appName).map((disclaimer) => (
          <div key={disclaimer} className="disclaimer">
            <div className="listItem" />
            <p>{disclaimer}</p>
          </div>
        ))}
      </div>
      <div className="boxHeader subtitle">
        Information on the benchmarks provided within {appName || 'Connect Report'}
      </div>
      <div className="grayBox">
        <p>
          The emissions benchmarks in {appName || 'Connect Report'} are based on industry average data and sourced from
          trusted third-party sources and an extensive emissions database. Benchmarks are useful tools for evaluating
          emissions performance and can aid in gauging a company's position relative to their industry standard.
          However, benchmarks should only be used as one tool among many as they may have limitations. The benchmarks in{' '}
          {appName || 'Connect Report'} are general indicators only. You can expect benchmarks to change over time as
          the available data and methodologies improve.
        </p>
      </div>
      <p>
        By using {appName || 'Connect Report'}, you acknowledge the above disclosures and limitations. We recommend
        consulting with a qualified expert for a more detailed and accurate assessment of your company's carbon
        footprint for official reporting purposes. Before making any important decisions, we recommend carrying out a
        thorough analysis.
      </p>
      <div className="buttonContainer">
        <button type="button" onClick={() => closeDisclaimer()}>
          Close
        </button>
      </div>
    </BoxHeader>
  )
}
