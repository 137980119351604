import { HeatConsumptionFields, HeatConsumptionLocation } from './../../interface/formFields.interface'
import validateNumericInput from './number.validation'
export default function getErrors(values: HeatConsumptionFields) {
  const errors: string[] = []
  const { locations } = values
  locations.forEach((location: HeatConsumptionLocation, i: number) => {
    const { isSefKnown, supplierEmissionsFactor, value } = location
    if (isSefKnown) {
      const supplierValidation = validateNumericInput(supplierEmissionsFactor)
      if (supplierValidation !== true) {
        errors.push(`Location ${i + 1} - Supplier Emissions Factor: ${supplierValidation}`)
      }
    }
    const valueValidation = validateNumericInput(value, { greaterThanZero: true })
    if (valueValidation !== true) {
      errors.push(`Location ${i + 1} - Heat Consumption: ${valueValidation}`)
    }
  })
  return errors
}
