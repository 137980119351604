import { useEffect, useState } from 'react'
import { getData, calculateResults } from '../api/reportStore'
import { useSession } from '../context/SessionProvider'
import { InputData } from '../interface/inputData.interface'
import { buildInput } from '../data/builders/inputBuilder'
import Overview from './results/Overview'
import GhgReport from './results/GhgReport'
import { ResultsData } from '../interface/result.interface'
import HowDoICompare from './results/HowDoICompare'
import Scope1 from './results/scope1/Scope1'
import Scope2 from './results/scope2/Scope2'
import Scope3 from './results/scope3/Scope3'
import { createPDF } from '../functions/pdf'
import { useTheme } from '../context/ThemeProvider'
import { useClient } from '../context/ClientProvider'
import useMediaQuery from '../hooks/useMediaQuery'
import SectionHeader from './shared/SectionHeader'
import { useReportDates } from '../context/ReportDatesProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export default function Results() {
  const { sessionId } = useSession()
  const [results, setResults] = useState<ResultsData>()
  const [inputData, setInputData] = useState<InputData>()
  const { logo } = useTheme()
  const { hideTopBar, isFreeUser } = useClient()
  const isMobile = useMediaQuery('(max-width: 780px)')
  const { startDate, endDate } = useReportDates()
  const navigate = useNavigate()

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      navigate('/?sessionId=' + sessionId)
    }
    getData(sessionId).then(async (data: InputData) => {
      setInputData(buildInput(data))
      const results = await displayResults(buildInput(data))
      setResults(results)
    })
  }, [sessionId])

  async function displayResults(data: any) {
    return await calculateResults(sessionId, data)
  }

  async function onClickCalculatePdf() {
    // Disable button
    const button = document.getElementById('download-pdf-button') as HTMLButtonElement
    button.disabled = true

    await createPDF(logo)
    // Enable button again
    button.disabled = false
  }

  if (!!results) {
    return (
      <div className="resultsContainer" id="results-container">
        <div className="formContainer">
          {isMobile && hideTopBar && <SectionHeader title="Results" />}
          <div className="resultTitleContainer" id="pdfOverviewTitle">
            <div>
              <div className="resultTitle" id="pdfOverviewTitle">
                Emissions Overview
              </div>
              {!(isMobile && hideTopBar) && (
                <div className="reportDates" id="reportDates">
                  <FontAwesomeIcon icon={faCalendar} /> {startDate} to {endDate}
                </div>
              )}
            </div>
            <div className="buttonContainerPdf">
              <button
                type="button"
                data-cy="download-pdf-button"
                id="download-pdf-button"
                onClick={onClickCalculatePdf}
              >
                Download PDF
              </button>
            </div>
          </div>
          <Overview data={results?.emissions_overview_results} />
          <GhgReport data={results?.ghg_protocol_results} />
          <HowDoICompare data={results?.how_do_I_compare_results} isFreeUser={isFreeUser} />
          <Scope1
            data={results?.scope_1_results}
            dataAvailable={
              (inputData?.DistanceTravelledMobile.length || inputData?.FuelConsumptionStationary.length) &&
              results?.scope_1_results.kg_CO2e !== 0
            }
            isFreeUser={isFreeUser}
          />
          <Scope2
            data={results?.scope_2_results}
            dataAvailable={
              (inputData?.ElectricityConsumption.length || inputData?.HeatConsumption.length) &&
              results?.scope_2_results.kg_CO2e !== 0
            }
            isFreeUser={isFreeUser}
          />
          <Scope3
            data={results?.scope_3_results}
            dataAvailable={
              (inputData?.PurchasedGoods.length ||
                inputData?.BusinessTravel.length ||
                inputData?.EmployeeCommuting.length ||
                inputData?.HotelStays.length) &&
              results?.scope_3_results.kg_CO2e !== 0
            }
            isFreeUser={isFreeUser}
          />
        </div>
      </div>
    )
  }
  return null
}
