interface BusinessSectors {
  [key: string]: string
}
export const businessSectors: BusinessSectors = {
  AGRIFORFISH: 'Agriculture, Forestry, Fishing and Hunting',
  MINE: 'Mining, Quarrying, and Oil and Gas Extraction',
  CONSTR: 'Construction',
  MANUF: 'Manufacturing',
  WHOLETRD: 'Wholesale Trade',
  RETTRD: 'Retail Trade',
  TRNSPRT: 'Transportation and Warehousing',
  IT: 'Information',
  REALEST: 'Real Estate and Rental and Leasing',
  PROSCITECH: 'Professional, Scientific, and Technical Services',
  MNGMNT: 'Management of Companies and Enterprises',
  SUPADMINWASTE: 'Administrative and Support and Waste Management and Remediation Services',
  HEALTHSOCIAL: 'Health Care and Social Assistance',
  RECREA: 'Arts, Entertainment, and Recreation',
  ACCOMFOOD: 'Accommodation and Food Services',
  PUBADMIN: 'Public Administration',
}
