import { Scope1Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'
import DoughnutChart from '../../../graphs/DoughnutChart'

export default function PieChart({ data }: { data: Scope1Results }) {
  const chartData = [
    { name: 'Distance Travelled Mobile', value: data.sections_results.DistanceTravelledMobile.kg_CO2e },
    { name: 'Fuel Consumption Stationary', value: data.sections_results.FuelConsumptionStationary.kg_CO2e },
  ]
  const {
    largest_subscope_display_name,
    largest_subscope_value,
    largest_subscope_pct,
    smallest_subscope_display_name,
    smallest_subscope_value,
    smallest_subscope_pct,
  } = data.results_text.variables
  return (
    <div className="chartSection40" id="pdfScope1PieChart">
      <div className="chartContainer">
        <DoughnutChart id="Scope1PieChart" data={chartData} />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          Your Scope 1 emissions are greatest in <b>{largest_subscope_display_name}</b> at{' '}
          {formatResult(largest_subscope_value, 0)} kg CO2e (<b>{formatResult(largest_subscope_pct, 2)}</b> % of your
          Scope 1 emissions) compared to <b>{formatResult(smallest_subscope_display_name, 2)}</b> with{' '}
          {formatResult(smallest_subscope_value, 0)} kg CO2e (<b>{formatResult(smallest_subscope_pct, 2)}</b> % of your
          Scope 1 emissions).
        </div>
      </div>
    </div>
  )
}
