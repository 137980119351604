import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRegion } from '../../../../context/RegionProvider'
import { stationaryCombustionMapping } from '../../../../data/inputs/stationary-combustion/stationary-combustion.mapping'
import { Machine } from '../../../../interface/formFields.interface'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function OwnedMachines({
  machineFields,
  appendMachine,
  getValues,
  register,
  removeMachine,
  watch,
}: {
  machineFields: any
  appendMachine: any
  getValues: any
  register: any
  removeMachine: any
  watch: any
}) {
  const defaultMachine: Machine = {
    fuelCode: 'NONE',
    value: 0,
    unitCode: 'KWH',
  }
  const { country } = useRegion()
  const { machineFuelTypes, stationaryFuelUnits, unitsPerFuel } = stationaryCombustionMapping[country.code || 'US']

  const machineFuelTypeOptions: Array<JSX.Element> = objectToOptions(machineFuelTypes)
  let stationaryFuelUnitOptions: Array<JSX.Element> = []
  const getUnitsPerStationaryFuelOptions = (fuelCode: string) => {
    const unitOptions = []
    for (let key in stationaryFuelUnits) {
      if (unitsPerFuel[fuelCode]?.includes(key)) {
        unitOptions.push(
          <option value={key} key={key}>
            {stationaryFuelUnits[key]}
          </option>,
        )
      }
    }
    return unitOptions
  }

  return (
    <div>
      {!machineFields.length && (
        <div className="emptyPlaceholder">Add machines or leave blank if you do not own any machines.</div>
      )}
      {machineFields.map((field: any, index: number) => (
        <MachineTemplate index={index} key={field.id} />
      ))}
      <div className="addButton" data-cy="add-machine-button" onClick={() => appendMachine(defaultMachine)}>
        <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add machine
      </div>
    </div>
  )

  function MachineTemplate({ index }: { index: number }) {
    watch(`machines.${index}.fuelCode`)
    const machine = getValues(`machines.${index}`)
    stationaryFuelUnitOptions = getUnitsPerStationaryFuelOptions(machine.fuelCode)
    return (
      <div className="itemCard">
        <div className="itemTitle">
          <div>Machine {index + 1}</div>
          <FontAwesomeIcon
            data-cy={`delete-machine-${index}-icon`}
            icon={faTrash}
            onClick={() => removeMachine(index)}
            className="clickable gray6Color"
          ></FontAwesomeIcon>
        </div>
        <div className="itemInputsContainer">
          <div className="itemInput width100">
            <div className="itemLabel">Fuel Type</div>
            <select {...register(`machines.${index}.fuelCode`)}>{machineFuelTypeOptions}</select>
          </div>
          <div className="itemInput">
            <div className="itemLabel">Fuel Consumption</div>
            <div className="inlineInputs">
              <input
                className="width100"
                type="number"
                step="any"
                inputMode="decimal"
                {...register(`machines.${index}.value`, {
                  valueAsNumber: true,
                })}
              />
              <select className="width100" {...register(`machines.${index}.unitCode`)}>
                {stationaryFuelUnitOptions}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
