import React, { createContext, useContext, useEffect, useState } from 'react'
import { useClient } from './ClientProvider'
import { useSession } from './SessionProvider'

interface Theme {
  primaryColor: string
  secondaryColor: string
  logo: string
}

const ThemeContext = createContext<Theme>({
  primaryColor: '',
  secondaryColor: '',
  logo: '',
})

export function useTheme() {
  return useContext(ThemeContext)
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { clientId } = useSession()
  const [theme, setTheme] = useState<Theme>({
    primaryColor: '',
    secondaryColor: '',
    logo: '',
  })

  const { fontName } = useClient()

  useEffect(() => {
    //TODO: Grab this from the client db
    if (!!clientId) {
      Promise.all([fetch(`theme/${clientId}/colors.json`), fetch(`theme/${clientId}/logo.png`)]).then(
        async ([colorsRes, logoRes]) => {
          const colors = await colorsRes.json()
          const logo = logoRes.url
          document.documentElement.style.setProperty('--primary-color', colors.primaryColor)
          document.documentElement.style.setProperty('--highlight-color', colors.highlightColor)
          document.documentElement.style.setProperty('--light-primary-color', colors.lightPrimaryColor)
          document.documentElement.style.setProperty('--dark-primary-color', colors.darkPrimaryColor)
          document.documentElement.style.setProperty('--secondary-color', colors.secondaryColor)
          setTheme({ ...colors, logo })
        },
      )
    }
    document.documentElement.style.setProperty('--primary-font', fontName || 'Gilroy')
  }, [clientId, fontName])

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}
