import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useImperativeHandle, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { getData, saveData } from '../../../api/reportStore'
import { useRegion } from '../../../context/RegionProvider'
import { useSession } from '../../../context/SessionProvider'
import getErrors from '../../../data/validation/fuelConsumption.validation'
import { FuelConsumptionFields } from '../../../interface/formFields.interface'
import VehiclesInfo from '../../popups/VehiclesInfo'
import SectionHeader from '../../shared/SectionHeader'
import FuelConsumptionInfoHeader from './components/FuelConsumptionInfoHeader'
import OwnedMachines from './components/OwnedMachines'
import OwnedVehicles from './components/OwnedVehicles'

const sectionName = 'FuelConsumption'

export default function FuelConsumption() {
  const { sessionId } = useSession()
  const navigate = useNavigate()
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)
  const { country } = useRegion()
  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const { control, register, watch, getValues, handleSubmit } = useForm<FuelConsumptionFields>({
    defaultValues: {
      vehicles: [],
      machines: [],
    },
  })

  const {
    fields: vehicleFields,
    append: appendVehicle,
    update: updateVehicle,
    remove: removeVehicle,
  } = useFieldArray({
    control,
    name: 'vehicles',
  })

  const {
    fields: machineFields,
    append: appendMachine,
    update: updateMachine,
    remove: removeMachine,
  } = useFieldArray({
    control,
    name: 'machines',
  })

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      navigate('/?sessionId=' + sessionId)
    }
    getData(sessionId).then((data: any) => {
      if (data['vehicles']) {
        for (let idx = 0; idx < data['vehicles'].length; idx++) {
          updateVehicle(idx, data['vehicles'][idx])
        }
      }
      if (data['machines']) {
        for (let idx = 0; idx < data['machines'].length; idx++) {
          updateMachine(idx, data['machines'][idx])
        }
      }
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
    })
  }, [sessionId, updateVehicle, updateMachine])

  async function save(values: FuelConsumptionFields, params?: { sectionCompleted: boolean }) {
    const data: any = { ...values }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    await saveData(sessionId, data)
  }

  async function onSubmit(values: FuelConsumptionFields, navigationOverride?: string) {
    const errors = getErrors(values, country.code)
    setErrors(errors)
    if (!errors.length) {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/purchased-goods?sessionId=' + sessionId)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async function goBack() {
    const previousRoute = '/company-information?sessionId=' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  return (
    <div className="formContainer">
      <SectionHeader title="Fuel Consumption" errors={errors} />
      <FuelConsumptionInfoHeader openPopup={openPopup} country={country} />
      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="sectionBox">
          <div className="sectionTitle">Owned Vehicles</div>
          {showPopup && <VehiclesInfo closePopup={closePopup} country={country} />}
          <OwnedVehicles
            vehicleFields={vehicleFields}
            appendVehicle={appendVehicle}
            getValues={getValues}
            register={register}
            removeVehicle={removeVehicle}
            watch={watch}
          />
          <div className="sectionTitle">
            Owned Machines{' '}
            <span data-tip="This category includes all stationary (i.e. non-moving) machinery directly controlled by your company that consume fuel to run. Examples include boilers, generators, furnaces, and turbines.">
              <FontAwesomeIcon icon={faInfoCircle} size={'xs'} className="gray4Color" />
            </span>
          </div>
          <OwnedMachines
            machineFields={machineFields}
            appendMachine={appendMachine}
            getValues={getValues}
            register={register}
            removeMachine={removeMachine}
            watch={watch}
          />
        </div>
        <div className="buttonContainer">
          <button onClick={goBack} type="button">
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
      <ReactTooltip place="top" type="dark" effect="float" className="tooltip" />
    </div>
  )
}
