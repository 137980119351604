import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useOutletContext } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { getData, resetForm, saveData } from '../../../api/reportStore'
import { useRegion } from '../../../context/RegionProvider'
import { useSession } from '../../../context/SessionProvider'
import { areaUnits } from '../../../data/units/areaUnits'
import { countriesList } from '../../../data/countriesList'
import { currenciesList } from '../../../data/currenciesList'
import { processTransactions } from '../../../api/processTransactions'
import { objectToOptions } from '../../../utils/objectToOptions'
import { businessSectors } from '../../../data/inputs/companyInformation'
import CollapsibleBox from '../../boxes/CollapsibleBox'
import { CompanyInformationFields } from '../../../interface/formFields.interface'
import getErrors from '../../../data/validation/companyInformation.validation'
import moment from 'moment'
import OpenBankingSection from '../../open-banking/OpenBankingSection'
import { useClient } from '../../../context/ClientProvider'
import { useReportDates } from '../../../context/ReportDatesProvider'
import SectionHeader from '../../shared/SectionHeader'
import { ConfirmCountryPopup } from './components/ConfirmCountryPopup'
import { ConfirmDatesPopup } from './components/ConfirmDatesPopup'

const sectionName = 'CompanyInformation'

export default function CompanyInformation() {
  const { setCountry } = useRegion()
  const { sessionId } = useSession()
  const { isOpenBankingEnabled, isFullScreenEnabled, isFreeUser } = useClient()
  const { setDates } = useReportDates()

  const navigate = useNavigate()
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [initialDates, setInitialDates] = useState<{ startDate: string | undefined; endDate: string | undefined }>({
    startDate: undefined,
    endDate: undefined,
  })
  const [initialCountry, setInitialCountry] = useState<string | undefined>(undefined)
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)

  const { register, getValues, watch, setValue, handleSubmit } = useForm<CompanyInformationFields>({
    defaultValues: {
      countryCode: 'US',
      businessSector: '',
    },
  })

  const [showConfirmDatesPopup, setShowConfirmDatesPopup] = useState(false)

  const openConfirmDatesPopup = () => {
    setShowConfirmDatesPopup(true)
  }

  const closeConfirmDatesPopup = () => {
    setValue('reportingStartDate', initialDates.startDate || '')
    setValue('reportingEndDate', initialDates.endDate || '')
    setShowConfirmDatesPopup(false)
  }

  const [showConfirmCountryPopup, setShowConfirmCountryPopup] = useState(false)

  const openConfirmCountryPopup = () => {
    setShowConfirmCountryPopup(true)
  }

  const closeConfirmCountryPopup = () => {
    setValue('countryCode', initialCountry || '')
    setShowConfirmCountryPopup(false)
  }

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  // Workaround to avoid typescript error: Argument of type 'string' is not assignable to parameter of type '"companyName" | "city" | "country" ...
  function isKey(x: CompanyInformationFields, k: PropertyKey): k is keyof CompanyInformationFields {
    return k in x
  }

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      navigate('/?sessionId=' + sessionId)
    }
    getData(sessionId).then((data: any) => {
      const fields = data[sectionName]
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
      setInitialDates({ startDate: fields?.reportingStartDate, endDate: fields?.reportingEndDate })
      setInitialCountry(fields?.countryCode)
      for (let key in fields) {
        if (isKey(fields, key)) {
          setValue(key, fields[key])
        }
      }
    })
  }, [sessionId, setValue])

  const reportingStartDate = watch('reportingStartDate')
  useEffect(() => {
    if (reportingStartDate) {
      const endDate = moment(reportingStartDate).add(1, 'year').subtract(1, 'day')
      setValue('reportingEndDate', endDate.format('YYYY-MM-DD'))
    }
  }, [reportingStartDate, setValue])

  const countryCode = watch('countryCode')
  useEffect(() => {
    const country = countriesList[countryCode]
    if (!initialCountry) {
      setValue('yearlyRevenueUnit', country?.defaultUnits.currency || 'USD')
      setValue('facilityAreaUnit', country?.defaultUnits.area || 'FT2')
    }
  }, [countryCode, initialCountry, setValue])

  async function save(values: CompanyInformationFields, params?: { sectionCompleted: boolean }) {
    processTransactions(
      sessionId,
      moment(values.reportingStartDate).startOf('day').toISOString(),
      moment(values.reportingEndDate).endOf('day').toISOString(),
      values.countryCode,
    )
    const data: any = {
      [sectionName]: values,
    }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    setDates(values.reportingStartDate, values.reportingEndDate)
    await saveData(sessionId, data)
  }

  async function onSubmit(values: CompanyInformationFields, navigationOverride?: string) {
    setCountry(countriesList[values.countryCode])
    const errors = getErrors(values)
    setErrors(errors)
    const datesChanged =
      (values.reportingStartDate !== initialDates.startDate || values.reportingEndDate !== initialDates.endDate) &&
      initialDates.startDate
    const countryChanged = initialCountry && initialCountry !== values.countryCode
    if (errors.length) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else if (datesChanged) {
      openConfirmDatesPopup()
    } else if (countryChanged) {
      openConfirmCountryPopup()
    } else {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/fuel-consumption?sessionId=' + sessionId)
    }
  }

  async function confirmReset() {
    await save(getValues(), { sectionCompleted: true })
    await resetForm(sessionId)
    navigate('/fuel-consumption?sessionId=' + sessionId)
  }

  async function goBack() {
    const previousRoute = '/?sessionId=' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  const countriesOptions: Array<JSX.Element> = objectToOptions(countriesList)
  const businessSectorOptions: Array<JSX.Element> = objectToOptions(businessSectors)
  const currenciesOptions: Array<JSX.Element> = objectToOptions(currenciesList)
  const areaOptions: Array<JSX.Element> = objectToOptions(areaUnits)

  return (
    <div className="formContainer">
      <SectionHeader title="Company Information" errors={errors} />
      <CollapsibleBox
        header={<div>In this section, please enter information about your company</div>}
        body={
          <>
            <div className="listItem mt2">Providing this information enables us to:</div>
            <div className="grayBox">
              <p>1. Tailor the emissions factors used in the calculation to your business</p>
              <p>2. Show you a comparison between your emissions and similar companies' emissions</p>
            </div>
          </>
        }
        isCollapsed={true}
      ></CollapsibleBox>

      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="companyGrid sectionBox">
          <label className="item1">Company Name</label>
          <input className="item2" {...register('companyName')} />
          <label className="item5">
            Business Sector{' '}
            <span data-tip="Professional, Scientific, and Technical Services includes consulting and research services">
              <FontAwesomeIcon icon={faInfoCircle} className="gray4Color" />
            </span>
          </label>
          <select className="item6" {...register('businessSector')}>
            {businessSectorOptions}
          </select>
          <label className="item7">Reporting Year (Start / End Date)</label>
          <input className="item8" type="date" disabled {...register('reportingStartDate')}></input>
          <input className="item9" type="date" disabled {...register('reportingEndDate')}></input>
          <label className="item10">
            Number of Employees{' '}
            <span data-tip="If the number of employees at your company has changed throughout the year, enter the average number for the year.">
              <FontAwesomeIcon icon={faInfoCircle} className="gray4Color" />
            </span>
          </label>
          <input
            className="item11"
            type="number"
            step="any"
            inputMode="decimal"
            {...register('numberOfEmployees', {
              valueAsNumber: true,
            })}
          ></input>
          <label className="item12">Yearly Revenue</label>
          <input
            className="item13"
            type="number"
            step="any"
            inputMode="decimal"
            {...register('yearlyRevenueValue', {
              valueAsNumber: true,
            })}
          ></input>
          <select className="item14" {...register('yearlyRevenueUnit')}>
            {currenciesOptions}
          </select>

          <label className="item15">Facility Area</label>
          <input
            className="item16"
            type="number"
            step="any"
            inputMode="decimal"
            {...register('facilityArea', {
              valueAsNumber: true,
            })}
          ></input>
          <select className="item17" {...register('facilityAreaUnit')}>
            {areaOptions}
          </select>
        </div>
        {(isOpenBankingEnabled || isFreeUser) && <OpenBankingSection />}
        <div className="buttonContainer">
          <button className="back" onClick={goBack} type="button">
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
      <ReactTooltip place="right" type="dark" effect="float" className="tooltip" />
      {showConfirmDatesPopup && (
        <ConfirmDatesPopup
          isFullScreenEnabled={isFullScreenEnabled}
          closePopup={closeConfirmDatesPopup}
          initialDates={initialDates}
          values={getValues()}
          confirmReset={confirmReset}
        />
      )}
      {showConfirmCountryPopup && (
        <ConfirmCountryPopup
          isFullScreenEnabled={isFullScreenEnabled}
          closePopup={closeConfirmCountryPopup}
          initialCountry={initialCountry}
          values={getValues()}
          confirmReset={confirmReset}
        />
      )}
    </div>
  )
}
