import { Country } from '../../../../data/countriesList'
import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function FuelConsumptionInfoHeader({ openPopup, country }: { openPopup: any; country: Country }) {
  return (
    <CollapsibleBox
      isCollapsed={true}
      header={
        <div className="textLarge">
          In this section, please enter details of fuel consumption from owned or controlled machines/vehicles
        </div>
      }
      body={
        <>
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>
              1. Include machinery and vehicles under your operational control, and fuel expenses for rented equipment
              you're responsible for. For instance, if you manage natural gas intake of your boiler, record it’s fuel
              consumption here, but if you purchase hot water for your heating, it’s classified as purchased heat and
              should be recorded under <i>Heat Consumption</i>.
            </p>
            <p>
              2. Vehicle and machinery contracting services should be{' '}
              {country.code === 'UK' ? 'categorised' : 'categorized'} under <i>Purchased Goods and Services</i>.
            </p>
            <p>
              3. Excludes electric-powered vehicles or machinery. Only fuel-powered vehicles and machines should be
              accounted for here.
            </p>
            <p>
              4. Check the{' '}
              <span className="link" onClick={openPopup}>
                vehicles classification here
              </span>
              .
            </p>
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
