import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { electricityConsumptionMapping } from '../../../../data/inputs/electricity-consumption/electricity-consumption.mapping'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function LocationItem({
  index,
  countryCode,
  watch,
  remove,
  register,
  getValues,
}: {
  index: number
  countryCode: string
  watch: any
  remove: any
  register: any
  getValues: any
}) {
  const { electricityGrids, electricityConsumptionUnits, supplierEmissionsFactorUnits } =
    electricityConsumptionMapping[countryCode]

  const gridOptions: Array<JSX.Element> = objectToOptions(electricityGrids)
  const electricityConsumptionUnitOptions: Array<JSX.Element> = objectToOptions(electricityConsumptionUnits)
  const supplierEmissionsFactorUnitOptions: Array<JSX.Element> = objectToOptions(supplierEmissionsFactorUnits)
  watch(`locations.${index}.isSefKnown`)
  return (
    <div className="itemCard" key={'location' + index}>
      <div className="itemTitle">
        <div>Location {index + 1}</div>
        <FontAwesomeIcon
          className="clickable gray6Color"
          data-cy={`delete-location-${index}-icon`}
          icon={faTrash}
          onClick={() => remove(index)}
        ></FontAwesomeIcon>
      </div>
      {countryCode === 'US' && (
        <div className="locationInput">
          <label className="label">Electricity Grid {'  '}</label>
          <select className="input" {...register(`locations.${index}.gridCode`)}>
            {gridOptions}
          </select>
        </div>
      )}
      <div className="checkboxFlex">
        <label className="checkboxContainer">
          <input type="checkbox" {...register(`locations.${index}.isSefKnown`)}></input>
          <span className="checkmark"></span>
        </label>
        <div>I know my supplier's emissions factor</div>
      </div>
      {getValues(`locations.${index}.isSefKnown`) && (
        <div className="locationInput">
          <label className="label">Supplier Emission Factor</label>
          <div className="input inlineInputs">
            <input
              className="width100"
              type="number"
              step="any"
              inputMode="decimal"
              {...register(`locations.${index}.supplierEmissionsFactor`, {
                valueAsNumber: true,
              })}
            ></input>
            <select className="width100" {...register(`locations.${index}.supplierUnitCode`)}>
              {supplierEmissionsFactorUnitOptions}
            </select>
          </div>
        </div>
      )}
      <div className="locationInput">
        <label className="label">Electricity Consumption</label>
        <div className="input inlineInputs">
          <input
            className="width100"
            type="number"
            step="any"
            inputMode="decimal"
            {...register(`locations.${index}.value`, {
              valueAsNumber: true,
            })}
          ></input>
          <select className="width100" {...register(`locations.${index}.unitCode`)}>
            {electricityConsumptionUnitOptions}
          </select>
        </div>
      </div>
    </div>
  )
}
