import { BusinessTravelFields, Transport, HotelStay } from '../../interface/formFields.interface'
import validateNumericInput from './number.validation'
export default function getErrors(values: BusinessTravelFields) {
  const errors: string[] = []
  const { transports, hotelStays } = values
  transports.forEach((transport: Transport, i: number) => {
    if (!!!transport.transportModeCode) {
      errors.push(`Mode of Transport ${i + 1} - Transport Type: This field cannot be empty.`)
    }
    const validation = validateNumericInput(transport.value, { greaterThanZero: true })
    if (validation !== true) {
      errors.push(`Mode of Transport ${i + 1} - Travelled Distance: ${validation}`)
    }
  })
  hotelStays.forEach((hotelStay: HotelStay, i: number) => {
    if (!hotelStay.hotelRegionCode) {
      errors.push(`Hotel Stay Region ${i + 1} - Region: This field cannot be empty.`)
    }
    const validation = validateNumericInput(hotelStay.value, { wholeNumber: true, greaterThanZero: true })
    if (validation !== true) {
      errors.push(`Hotel Stay Region ${i + 1} - Number of Nights: ${validation}`)
    }
  })
  return errors
}
