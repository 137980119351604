import { useEffect, useImperativeHandle, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import { getData, saveData } from '../../../api/reportStore'
import { useSession } from '../../../context/SessionProvider'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { HeatConsumptionLocation, HeatConsumptionFields } from '../../../interface/formFields.interface'
import getErrors from '../../../data/validation/heatConsumption.validation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import SectionHeader from '../../shared/SectionHeader'
import HeatConsumptionInfoHeader from './components/HeatConsumptionInfoHeader'
import LocationItem from './components/LocationItem'

const sectionName = 'HeatConsumption'

const defaultLocation: HeatConsumptionLocation = {
  unitCode: 'KWH',
  supplierUnitCode: 'KGKWH',
  value: 0,
  isSefKnown: false,
  supplierEmissionsFactor: NaN,
  heatCode: 'HEAT',
}

export default function HeatConsumption() {
  const { sessionId } = useSession()
  const navigate = useNavigate()
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)

  const { control, register, watch, getValues, handleSubmit } = useForm<HeatConsumptionFields>({
    defaultValues: {
      locations: [],
    },
  })

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'locations',
  })

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  async function save(values: HeatConsumptionFields, params?: { sectionCompleted: boolean }) {
    const data: any = {
      [sectionName]: values.locations,
    }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    await saveData(sessionId, data)
  }

  async function onSubmit(values: HeatConsumptionFields, navigationOverride?: string) {
    const errors = getErrors(getValues())
    setErrors(errors)
    if (!errors.length) {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/business-travel?sessionId=' + sessionId)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async function goBack() {
    const previousRoute = '/electricity-consumption?sessionId=' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  useEffect(() => {
    getData(sessionId).then((data: any) => {
      if (data[sectionName]) {
        for (let idx = 0; idx < data[sectionName].length; idx++) {
          const location = data[sectionName][idx]
          update(idx, location)
        }
      }
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
    })
  }, [sessionId, update])

  return (
    <div className="formContainer">
      <SectionHeader title="Heat Consumption" errors={errors} />
      <HeatConsumptionInfoHeader />
      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="sectionBox">
          {!fields.length && (
            <div className="emptyPlaceholder">
              Add the heat consumption of your different locations or leave blank if it doesn't apply.
            </div>
          )}
          {fields.map((field, index) => (
            <LocationItem
              index={index}
              key={field.id}
              watch={watch}
              remove={remove}
              register={register}
              getValues={getValues}
            />
          ))}
          <div className="addButton" data-cy="add-location-button" onClick={() => append(defaultLocation)}>
            <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add location
          </div>
        </div>
        <div className="buttonContainer">
          <button onClick={goBack} type="button">
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
      <ReactTooltip place="right" type="dark" effect="float" />
    </div>
  )
}
