import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { employeeCommutingMapping } from '../../../../data/inputs/employee-commuting/employee-commuting.mapping'
import { distanceUnits } from '../../../../data/units/distanceUnits'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function TransportItem({
  index,
  countryCode,
  remove,
  register,
}: {
  index: number
  countryCode: string
  remove: any
  register: any
}) {
  const { modeOfTransports } = employeeCommutingMapping[countryCode]
  const transportModeOptions: Array<JSX.Element> = objectToOptions(modeOfTransports)
  const unitOptions: Array<JSX.Element> = objectToOptions(distanceUnits)
  return (
    <div className="itemCard">
      <div className="itemTitle">
        <div>Mode of Transport {index + 1}</div>
        <FontAwesomeIcon
          className="clickable gray6Color"
          data-cy={`delete-mode-of-transport-${index}-icon`}
          icon={faTrash}
          onClick={() => remove(index)}
        ></FontAwesomeIcon>
      </div>
      <div className="itemInputsContainer" key={'transport' + index}>
        <div className="itemInput">
          <div className="itemLabel">Transport Type</div>
          <select {...register(`transports.${index}.transportModeCode`)}>{transportModeOptions}</select>
        </div>
        <div className="itemInput">
          <div className="itemLabel">Travelled Distance</div>
          <div className="inlineInputs">
            <input
              className="width100"
              type="number"
              step="any"
              inputMode="decimal"
              {...register(`transports.${index}.value`, {
                valueAsNumber: true,
              })}
            />
            <select className="width100" {...register(`transports.${index}.unitCode`)}>
              {unitOptions}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
