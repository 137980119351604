import { Country } from '../../data/countriesList'
import BoxHeader from '../boxes/BoxHeader'

export default function ModeOfTransportsInfo({ closePopup, country }: { closePopup: Function; country: Country }) {
  if (country.code === 'UK') {
    return (
      <BoxHeader closePopup={closePopup} subtitle={'Transport Type Information'}>
        <div className="listItem"> Car</div>
        <div className="grayBox">
          <p>Small to large passenger cars.</p>
        </div>
        <div className="listItem">Van</div>
        <div className="grayBox">
          <p>Large goods vehicles with a maximum weight or gross vehicle weight of less than 3.5 tonnes.</p>
        </div>
        <div className="listItem">National Rail</div>
        <div className="grayBox">
          <p>Operated passenger rail networks that are operated by a national authority.</p>
        </div>
        <div className="listItem">International Rail</div>
        <div className="grayBox">
          <p>Eurostar rail network.</p>
        </div>
        <div className="listItem">Transit Rail</div>
        <div className="grayBox">
          <p>Rail typically within an urban centre, such as the underground, metros, light rails and trams.</p>
        </div>
        <div className="buttonContainer">
          <button type="button" onClick={() => closePopup()}>
            Close
          </button>
        </div>
      </BoxHeader>
    )
  }
  return (
    <BoxHeader closePopup={closePopup} subtitle={'Transport Type Information'}>
      <div className="listItem">Passenger Car</div>
      <div className="grayBox">
        <p>
          {
            'Includes passenger cars with a wheelbase <121 inches (e.g. a sedan), and vehicles like minivans, SUVs, and small pickup trucks with the same wheelbase limit.'
          }
        </p>
        <p>
          Most passenger cars be categorized here, but it is always good to check. The Land Rover Discovery Sport is on
          the higher end of the passenger car classification, whilst the Volkswagen Routan SE and Ford Ranger are
          classified under light-duty trucks due to their wheelbase being over 121 inches.{' '}
        </p>
      </div>
      <div className="listItem">Light-Duty Truck</div>
      <div className="grayBox">
        <p>
          This refers to vehicles with a Gross Vehicle Weight Rating (GVWR) below 10,000 lbs and a wheelbase over 121
          inches.
        </p>
        <p>
          This includes full-size pickup trucks, vans, and SUVs like Nissan Frontier, Volkswagen Routan SE, and Ford
          Transits.
        </p>
      </div>
      <div className="listItem">Intercity Rail</div>
      <div className="grayBox">
        <p>Long-distance trains connecting major cities and regions. </p>
      </div>
      <div className="listItem">Commuter Rail</div>
      <div className="grayBox">
        <p>
          Operated by regional transit authorities, with shorter trains making frequent stops in suburban and urban
          areas, and sharing tracks with freight and other commuter rail services.
        </p>
      </div>
      <div className="listItem">Transit Rail</div>
      <div className="grayBox">
        <p>
          Rail typically within an urban center, such as subways, elevated railways, metros, streetcars, trolley cars,
          and tramways.
        </p>
      </div>
      <div className="buttonContainer">
        <button type="button" onClick={() => closePopup()}>
          Close
        </button>
      </div>
    </BoxHeader>
  )
}
