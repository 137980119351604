import { useEffect, useImperativeHandle, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import { getData, saveData } from '../../../api/reportStore'
import { useSession } from '../../../context/SessionProvider'
import GridMap from '../../popups/GridMap'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ElectricityConsumptionFields, ElectricityConsumptionLocation } from '../../../interface/formFields.interface'
import getErrors from '../../../data/validation/electricityConsumption.validation'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SectionHeader from '../../shared/SectionHeader'
import { useRegion } from '../../../context/RegionProvider'
import ElectricityConsumptionInfoHeader from './components/ElectricityConsumptionInfoHeader'
import ElectricityConsumptionHelper from './components/ElectricityConsumptionHelper'
import useMediaQuery from '../../../hooks/useMediaQuery'
import LocationItem from './components/LocationItem'
import { ElectricityTransaction } from '../../../interface/transactionData.interface'

const sectionName = 'ElectricityConsumption'

export default function ElectricityConsumption() {
  const isMobile = useMediaQuery('(max-width: 900px)')
  const { sessionId } = useSession()
  const navigate = useNavigate()
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [electricityTransactions, setElectricityTransactions] = useState<ElectricityTransaction[]>([])
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)
  const { country } = useRegion()

  const defaultLocation: ElectricityConsumptionLocation = {
    gridCode: 'ELEC',
    unitCode: 'KWH',
    supplierUnitCode: 'KGKWH',
    value: 0,
    isSefKnown: false,
    supplierEmissionsFactor: NaN,
  }

  const { control, register, handleSubmit, watch, getValues } = useForm<ElectricityConsumptionFields>({
    defaultValues: {
      locations: [],
    },
  })

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'locations',
  })

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  async function save(values: ElectricityConsumptionFields, params?: { sectionCompleted: boolean }) {
    const data: any = {
      [sectionName]: values.locations,
    }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    await saveData(sessionId, data)
  }

  async function onSubmit(values: ElectricityConsumptionFields, navigationOverride?: string) {
    const errors = getErrors(getValues())
    setErrors(errors)
    if (!errors.length) {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/heat-consumption?sessionId=' + sessionId)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async function goBack() {
    const previousRoute = '/fuel-consumption?sessionId=' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  useEffect(() => {
    getData(sessionId).then((data: any) => {
      if (data[sectionName]) {
        for (let idx = 0; idx < data[sectionName].length; idx++) {
          const location = data[sectionName][idx]
          update(idx, location)
        }
      }
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
      setElectricityTransactions(data?.Scope_2_Electricity)
    })
  }, [sessionId, update])

  const [showPopup, setShowPopup] = useState(false)

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <div className="formContainer">
      {showPopup && <GridMap closePopup={closePopup} />}
      <SectionHeader title="Electricity Consumption" errors={errors} />
      <ElectricityConsumptionInfoHeader openPopup={openPopup} countryCode={country.code} />
      {isMobile ? '' : <ElectricityConsumptionHelper electricityTransactions={electricityTransactions} />}
      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="sectionBox">
          {!fields.length && (
            <div className="emptyPlaceholder">
              Add the electricity consumption of your different locations or leave blank if it doesn't apply.
            </div>
          )}
          {fields.map((field, index) => (
            <LocationItem
              index={index}
              key={field.id}
              countryCode={country.code}
              watch={watch}
              remove={remove}
              register={register}
              getValues={getValues}
            />
          ))}
          <div className="addButton" data-cy="add-location-button" onClick={() => append(defaultLocation)}>
            <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add location
          </div>
        </div>
        <div className="buttonContainer">
          <button onClick={goBack} type="button">
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
      <ReactTooltip place="right" type="dark" effect="float" />
    </div>
  )
}
