import { Bar } from 'react-chartjs-2'
import { Scope3Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function EmployeeCommutingBenchmark({
  data,
  businessTransportsGrouped,
  distanceUnits,
  isMobile,
}: {
  data: Scope3Results
  businessTransportsGrouped: any
  distanceUnits: any
  isMobile: boolean
}) {
  interface Dataset {
    label: string
    data: number[]
    color: string
    hoverColor: string
  }

  let labels: string[] = []
  const datasets: Dataset[] = [
    {
      label: 'Your company',
      data: [],
      color: 'rgba(31, 216, 171, 0.5)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
    {
      label: 'Average company',
      data: [],
      color: 'rgba(31, 149, 216, 0.5)',
      hoverColor: 'rgba(31, 149, 216, 1)',
    },
  ]
  Object.keys(businessTransportsGrouped).forEach((transportCode: string) => {
    if (data.benchmarks.EmployeeCommuting[transportCode]) {
      labels.push(businessTransportsGrouped[transportCode])
      datasets[0].data.push(data.benchmarks.EmployeeCommuting[transportCode].converted_value)
      datasets[1].data.push(data.benchmarks.EmployeeCommuting[transportCode].benchmark_value)
    }
  })
  return (
    <div className="benchmarkSection" id="pdfScope3EmployeeCommutingBenchmarkChart">
      <div className="chartInfo">
        <div className="textStrong">Employee Commuting Distance Benchmarking</div>
        <div>
          <p>
            This graph shows a comparison between the distance travelled by your employees commuting to/from work and
            the average for companies with a similar number of employees. This should help you get an idea of which
            forms of transport your employees use to get to work, and how it compares with employees of other companies.
          </p>
          <p>
            A good way to reduce emissions here is by offering incentives to cycle to work, take public transport, or
            car-pool. You could also cut down on emissions from employee commuting by encouraging employees to work from
            home a few days per week.
          </p>
        </div>
      </div>
      <div className="chartContainer">
        <Bar
          id="EmployeeCommutingBenchmarkChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    return context[0].label + ' distance travelled'
                  },
                  label: (context) => {
                    return (
                      formatResult(context.raw, 2) +
                      ' ' +
                      distanceUnits[Object.values(data.benchmarks.BusinessTravel)[context.dataIndex].final_unit]
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (tickValue: any) {
                    return isMobile
                      ? String.fromCharCode(tickValue + 'A'.charCodeAt(0))
                      : this.getLabelForValue(tickValue)
                  },
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  count: 6,
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: Dataset) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                hoverBackgroundColor: row.hoverColor,
                borderRadius: 10,
                barPercentage: 0.9,
                categoryPercentage: 0.6,
              }
            }),
          }}
        />
        {isMobile && (
          <div>
            {labels.map((label, index) => {
              return (
                <span key={index}>
                  {String.fromCharCode(index + 'A'.charCodeAt(0)) + '. ' + label}
                  <br />
                </span>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
