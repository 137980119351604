import { HowDoICompareResults } from '../../interface/result.interface'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, defaults } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import formatResult from '../../utils/formatResult'
import { useState } from 'react'
import LockedTextBox from '../boxes/LockedTextBox'
import CollapsibleBox from '../boxes/CollapsibleBox'

defaults.font.family = 'Gilroy'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
export default function HowDoICompare({ data, isFreeUser }: { data: HowDoICompareResults; isFreeUser: any }) {
  const COMPANY = 'Your company'
  const AVERAGE = 'Average company'

  const [showBarChart, setShowBarChart] = useState(true)

  interface Dataset {
    label: string
    data: number[]
    color: string
    hoverColor: string
  }

  const labels = ['Scope 1', 'Scope 2', 'Scope 3.1', 'Scope 3.6', 'Scope 3.7']
  const datasets: Dataset[] = [
    {
      label: COMPANY,
      data: [
        data.scope_1.pct_total,
        data.scope_2.pct_total,
        data.scope_3_1.pct_total,
        data.scope_3_6.pct_total,
        data.scope_3_7.pct_total,
      ],
      color: 'rgba(31, 216, 171, 0.5)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
    {
      label: AVERAGE,
      data: [
        data.scope_1.benchmark_value,
        data.scope_2.benchmark_value,
        data.scope_3_1.benchmark_value,
        data.scope_3_6.benchmark_value,
        data.scope_3_7.benchmark_value,
      ],
      color: 'rgba(31, 149, 216, 0.5)',
      hoverColor: 'rgba(31, 149, 216, 1)',
    },
  ]
  return datasets[0].data.length > 0 ? (
    <CollapsibleBox
      header={
        <div className="resultSubTitle" id="pdfHowDoICompareTitle">
          How do I compare?
          {isFreeUser && <div className="textSmallSection">This section shows how your emissions compare to the average company in your industry</div>}
        </div>
      }
      body={
        isFreeUser ? (
          <>
            <LockedTextBox dataCy="how-do-i-compare-locked" />
          </>
        ) : (
          <div data-cy="how-do-i-compare-section">
            <BarChart />
            <GraphInformation />
          </div>
        )
      }
    ></CollapsibleBox>
  ) : (
    <>{setShowBarChart(false)}</>
  )

  function BarChart() {
    return (
      <Bar
        id="HowDoICompareChart"
        options={{
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
              },
            },
            tooltip: {
              callbacks: {
                title: (context) => {
                  return context[0].label + ' share of total'
                },
                label: (context) => {
                  return formatResult(context.raw, 2) + ' %'
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              ticks: {
                count: 6,
              },
              grid: {
                display: true,
              },
            },
          },
        }}
        data={{
          labels,
          datasets: datasets.map((row: Dataset) => {
            return {
              data: row.data,
              label: row.label,
              backgroundColor: row.color,
              hoverBackgroundColor: row.hoverColor,
              borderRadius: 10,
              barPercentage: 0.9,
              categoryPercentage: 0.6,
            }
          }),
        }}
      />
    )
  }

  function GraphInformation() {
    return (
      <div className="graphInformation">
        <div className="textStrong">What does this graph show?</div>
        <p>
          This bar chart shows the distribution of emissions across sources for your company (in green), and, in
          comparison, the average company in your industry (blue). This is not a comparison of absolute emissions
          values. Think of it as a way to understand the <b>shape of your carbon footprint, not the size of it.</b>
        </p>
        <p>
          If you have reported on all your sources of emissions, you can see which sources represent a larger proportion
          of your overall emissions, and hence which ones to focus your efforts on. You can also see how the average
          company compares, which indicates where your <b>underlying business activities</b> are <b>similar</b> to the
          average, or <b>different</b> from it.
        </p>
        <p>
          If you still have data to add, this chart can tell you which areas to <b>focus</b> on. You can see the areas
          that similar companies have the most emissions from and that you have not reported data on, and you should
          focus your data collection efforts here.
        </p>
      </div>
    )
  }
}
