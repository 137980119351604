export const machineFuelTypes: any = {
  COAL: 'Coal and Coke',
  //BIOMS: 'Biomass Fuels - Solid',
  NAGA: 'Natural Gas',
  //BIOMG: 'Biomass Fuels - Gaseous',
  OTHE: 'Other Fuels - Gaseous',
  PROP: 'Propane',
  DIES: 'Diesel',
  LPG: 'LPG',
  MOTO: 'Petrol',
  //BIOML: 'Biomass Fuels - Liquid',
  OTHPET: 'Other Petroleum Products',
}
export const unitsPerFuel: any = {
  COAL: ['KWH', 'MJ', 'KG', 'MTON', 'LBS'],
  //BIOMS: ['KWH', 'MJ', 'KG', 'MTON', 'LBS'],
  NAGA: ['KWH', 'MJ', 'M3'],
  //BIOMG: ['KWH', 'MJ', 'KG', 'MTON', 'LBS'],
  OTHE: ['KWH', 'MJ'],
  //BIOML: ['KWH', 'MJ', 'LIT'],
  PROP: ['KWH', 'MJ', 'LIT'],
  LPG: ['KWH', 'MJ', 'LIT'],
  MOTO: ['KWH', 'MJ', 'LIT'],
  DIES: ['KWH', 'MJ', 'LIT'],
  OTHPET: ['KWH', 'MJ', 'LIT'],
}
export const stationaryFuelUnits: any = {
  LBS: 'pounds (lbs)',
  KG: 'kilograms (kg)',
  MTON: 'metric ton (mt)',
  M3: 'cubic metre (m3)',
  LIT: 'litres (L)',
  KWH: 'kilowatt-hour (kWh)',
  MJ: 'megajoules (MJ)',
}

export const stationaryCombustionInputsUK = { machineFuelTypes, unitsPerFuel, stationaryFuelUnits }
