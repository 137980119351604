import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getData, saveData } from '../api/reportStore'
import { useSession } from '../context/SessionProvider'
import { buildInput } from '../data/builders/inputBuilder'
import { InputData } from '../interface/inputData.interface'
import BusinessTravelReview from './review/BusinessTravelReview'
import CompanyInformationReview from './review/CompanyInformationReview'
import ElectricityConsumptionReview from './review/ElectricityConsumptionReview'
import EmployeeCommutingReview from './review/EmployeeCommutingReview'
import FuelConsumptionReview from './review/FuelConsumptionReview'
import HeatConsumptionReview from './review/HeatConsumptionReview'
import PurchasedGoodsReview from './review/PurchasedGoodsReview'
import SectionHeader from './shared/SectionHeader'

export default function Review() {
  const { sessionId } = useSession()
  const [inputData, setInputData] = useState<InputData>()
  const navigate = useNavigate()

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      navigate('/?sessionId=' + sessionId)
    }
    getData(sessionId).then((data: InputData) => {
      setInputData(buildInput(data))
    })
  }, [sessionId])

  async function confirmReview() {
    await saveData(sessionId, { Review_Status: 'Completed' })
    navigate('/results?sessionId=' + sessionId)
  }

  if (!!inputData) {
    const {
      CompanyInformation,
      DistanceTravelledMobile,
      FuelConsumptionStationary,
      ElectricityConsumption,
      HeatConsumption,
      EmployeeCommuting,
      BusinessTravel,
      HotelStays,
      PurchasedGoods,
    } = inputData

    return (
      <div>
        <div className="formContainer">
          <SectionHeader title="Review" />
          <CompanyInformationReview fields={CompanyInformation} />
          <FuelConsumptionReview ownedVehicles={DistanceTravelledMobile} ownedMachines={FuelConsumptionStationary} />
          <ElectricityConsumptionReview locations={ElectricityConsumption} />
          <HeatConsumptionReview locations={HeatConsumption} />
          <BusinessTravelReview transports={BusinessTravel} hotelStays={HotelStays} />
          <EmployeeCommutingReview transports={EmployeeCommuting} />
          <PurchasedGoodsReview purchasedGoods={PurchasedGoods} />
          <div className="buttonContainer">
            <button type="submit" onClick={async () => await confirmReview()}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    )
  }
  return null
}
