import { faLeaf, faMagnifyingGlass, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from '../../context/SessionProvider'
import CollapsibleBox from '../boxes/CollapsibleBox'
import GhgpInfo from '../popups/GhgpInfo'
import Disclaimer from '../popups/Disclaimer'
import { useClient } from '../../context/ClientProvider'
import SectionHeader from '../shared/SectionHeader'
import { getMastercardTransactions } from '../../api/getMastercardTransactions'
import { uploadReportTransactions } from '../../api/uploadTransactions'
import { deleteAllReportTransactions } from '../../api/deleteAllReportTransactions'
import { processTransactions } from '../../api/processTransactions'
import Login from '../popups/Login'

export default function Intro() {
  const [showPopup, setShowPopup] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [transactions, setTransactions] = useState<any[]>([])
  const { sessionId } = useSession()
  const navigate = useNavigate()
  const { companyName, appName } = useClient()
  const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false)

  async function mapTransactions(transactions: any[]) {
    let mappedTransactions = []
    for (let i = 0; i < transactions.length; i++) {
      mappedTransactions[i] = {
        description: transactions[i].description,
        mcc: transactions[i].mcc,
        merchant: transactions[i].merchant,
        price: transactions[i].amount,
        currencyISO: 'USD',
        transactionId: transactions[i].id,
        transactionDate: transactions[i].date.slice(0, 10),
      }
    }
    return mappedTransactions
  }

  useEffect(() => {
    let accountId = localStorage.getItem('accountId')
    let username = localStorage.getItem('username')
    let password = localStorage.getItem('password')
    if (accountId === null || username === null || password === null) {
      setShowLoginPopup(true)
    } else {
      getMastercardTransactions({ accountId, username, password }).then(async (transactions) => {
        setTransactions(transactions)
        const mappedTransactions = await mapTransactions(transactions)
        await deleteAllReportTransactions(sessionId)
        await uploadReportTransactions(sessionId, mappedTransactions)
        const resp = await processTransactions(sessionId, '2022-01-01T23:00:00.000Z', '2022-12-31T23:00:00.000Z', 'US')
      })
    }
  }, [showLoginPopup])

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const openDisclaimer = () => {
    setShowDisclaimer(true)
  }

  const closeDisclaimer = () => {
    setShowDisclaimer(false)
  }

  const closeLoginPopup = () => {
    setShowLoginPopup(false)
  }

  function TransactionItem({ transaction }: { transaction: any }) {
    return (
      <div className="electricityHelperTableRow">
        <div className="electricityHelperTableItem">{transaction.description}</div>
        <div className="electricityHelperTableItem">{transaction.date.slice(0, 10)}</div>
        <div className="electricityHelperTableItem">{transaction.merchant}</div>
        <div className="electricityHelperTableItem">{transaction.amount} USD</div>
      </div>
    )
  }

  return (
    <>
      <div className="formContainer">
        {showLoginPopup && <Login closePopup={closeLoginPopup} />}
        {showPopup && <GhgpInfo closePopup={closePopup} />}
        <SectionHeader
          title={`Welcome to ${companyName || 'Connect Earth'}'s business carbon accounting solution!`}
          showDates={false}
        />

        <CollapsibleBox
          header={<div className="subtitle">Why should I use {appName || 'Connect Report'}?</div>}
          body={
            <>
              <div className="listItem">
                The purpose of {appName || 'Connect Report'} is to help you calculate your business' carbon emissions.
                This will allow you to identify the primary emitters in your business, so you can:
              </div>
              <div className="infoBoxesContainer">
                <div className="infoBox">
                  <div>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="primaryColor" size="2x" />
                  </div>
                  <div>See where to focus your emissions-reduction efforts</div>
                </div>
                <div className="infoBox">
                  <div>
                    <FontAwesomeIcon icon={faLeaf} className="primaryColor" size="2x" />
                  </div>
                  <div>Track the impact of the actions you take to reduce emissions</div>
                </div>
                <div className="infoBox">
                  <div>
                    <FontAwesomeIcon icon={faUsers} className="primaryColor" size="2x" />
                  </div>
                  <div>Prove your commitment to reducing emissions to your customers and stakeholders</div>
                </div>
              </div>
              <br />
              <div className="listItem">
                To find out more about the Greenhouse Gas Protocol and what the different Scopes (1, 2 and 3) mean,
                click{' '}
                <span className="link" onClick={openPopup}>
                  here
                </span>
                .
              </div>
            </>
          }
        ></CollapsibleBox>
        <CollapsibleBox
          header={<div className="subtitle">Getting Started</div>}
          body={
            <>
              <div className="listItem">
                Throughout the form, you will be asked to input information regarding your company's business
                activities. A few things to note:
              </div>
              <div className="grayBox">
                <p>The more information you provide, the more accurate and insightful your results will be</p>
                <p>For fields that don't apply to your business, leave them blank</p>
                <p>
                  For fields that are relevant, but you do not have precise data for, enter an estimate for now and you
                  can return with better data later
                </p>
                <p>
                  As you progress through the form, you will find tips and additional information to help you understand
                  what data is required
                </p>
              </div>
              <div className="listItem">
                Once you have submitted as much information as you can, you will be presented with your business'
                calculated emissions, and a breakdown of the contributing factors!
              </div>
              <div className="listItem">
                By using {appName || 'Connect Report'}, you acknowledge our{' '}
                <span className="link" onClick={openDisclaimer}>
                  methodology and limitations
                </span>
              </div>
            </>
          }
        ></CollapsibleBox>
        <CollapsibleBox
          isCollapsed={true}
          header={<div className="subtitle">What do I need?</div>}
          body={
            <>
              <div className="listItem">If you own machinery</div>
              <div className="grayBox">
                <b>Needed</b>
                <p className="subListItem">
                  The fuel type, and fuel consumption for all machines you own and used in the reporting period
                </p>
                <p className="subListItem">This includes boilers</p>
              </div>
              <div className="listItem">If you own vehicles</div>
              <div className="grayBox">
                <b>Needed</b>
                <p className="subListItem">
                  The vehicle type, fuel type and mileage per vehicle during reporting period
                </p>
                <b>Recommended</b>
                <p className="subListItem">
                  Find the gross vehicle weight rating (maximum weight), wheelbase and engine size info for proper
                  vehicle classification - this may be found in the owner's manual, manufacturer's website, or driver's
                  side door/jamb sticker
                </p>
                <p className="subListItem">
                  Determine mileage using odometer, maintenance records, or GPS tracking if available
                </p>
              </div>
              <div className="listItem">Electricity and Heat Consumption </div>
              <div className="grayBox">
                <b>Needed</b>
                <p className="subListItem">
                  Purchased heating and electricity consumption - use utility bills, check electricity and heat meters
                  if they are accessible, or use the online option for digital meters
                </p>
                <b>Recommended</b>
                <p className="subListItem">
                  Your supplier’s emissions factor. Usually in pounds of CO2e emitted per kilo-watt-hour of electricity
                  or heat consumed
                </p>
              </div>
              <div className="listItem">Employee Commuting </div>
              <div className="grayBox">
                <b>Needed</b>
                <p className="subListItem">
                  Commutes distances of employees, modes of transport used, and number of days commuting per year
                </p>
              </div>
              <div className="listItem">Business Travel </div>
              <div className="grayBox">
                <b>Needed</b>
                <p className="subListItem">
                  The modes of transport used for business travel and the distances of each journey - online tools can
                  help with flight and train distances
                </p>
                <p className="subListItem">Region and number of nights per person of hotel stays</p>
              </div>
            </>
          }
        ></CollapsibleBox>
        <CollapsibleBox
          isCollapsed={true}
          header={<div className="subtitle">Tips for future data collection</div>}
          body={
            <>
              <p>
                Collecting data to determine your business's carbon footprint can be a challenging task, especially if
                it is your first time. In the following section, we have provided some best practices to make data
                collection easier for your company the next time you want to calculate your emissions. You may already
                be using some of these as part of your everyday operations.
              </p>
              <div className="listItem">Owned Vehicles</div>
              <div className="grayBox">
                <p>
                  Create a fleet management system to efficiently manage your vehicles. It includes features to track
                  and monitor vehicles, optimize routes, reduce costs, and improve fleet performance. You can add
                  vehicle readings.
                </p>
                <p>
                  Alternatively, use a spreadsheet with vehicle info, fuel usage, distance traveled, and frequent
                  odometer readings. Fuel usage information would also be helpful.
                </p>
              </div>
              <div className="listItem">Owned Machines</div>
              <div className="grayBox">
                <p>
                  Creating an inventory can be very helpful for collecting information on your owned machinery. Collect
                  data on machine type, specs, manuals, and maintenance records. Use a spreadsheet to track fuel type
                  and total usage per top-up.
                </p>
              </div>
              <div className="listItem">Electricity and Heat Consumption</div>
              <div className="grayBox">
                <p>
                  It can be a good idea to routinely check and record your electricity and heat consumption and
                  purchases over time.
                </p>
                <p>
                  We recommend reaching out to your supplier to get the exact emissions factor for the heat and/or
                  electricity you are consuming.
                </p>
              </div>
              <div className="listItem">Employee Commuting</div>
              <div className="grayBox">
                <p>
                  It's hard to track emissions from employee commuting year-round due to changing employee numbers and
                  potential moves. To get an accurate representation, you'll need to collect data on the number of
                  employees commuting, distance traveled, mode of transportation, and frequency of trips. Use a
                  spreadsheet to track this information and collect data quarterly.{' '}
                </p>
                <p>
                  Additionally, make it easy for employees to disclose their travel information, for example, by using a
                  simple survey. Explaining why it needs to be collected and considering additional incentives can make
                  it easier to collect this data.
                </p>
              </div>
              <div className="listItem">Business Travel</div>
              <div className="grayBox">
                <p>
                  Setting up a business travel spreadsheet would make collecting data to measure business travel a lot
                  easier. There are also different software platforms available that make it easier to collect business
                  travel data.
                </p>
                <p>
                  Recording vehicle type, trip origin/destination, distance, number of tickets, and price after every
                  trip will save a lot of time in the long run. Do the same for hotel stays: hotel name, location,
                  nights at the hotel, price, and number of rooms.
                </p>
              </div>
            </>
          }
        ></CollapsibleBox>
        <CollapsibleBox
          isCollapsed={true}
          header={<div className="subtitle">Bank account transactions included</div>}
          body={
            <>
              <div className="sectionBox">
                <div className="electricityHelperTable mt4" id="ElectricityHelperTable">
                  <div className="electricityHelperTableRow">
                    <div className="electricityHelperTableHeader">
                      <p>Description</p>
                    </div>
                    <div className="electricityHelperTableHeader">
                      <p>Date</p>
                    </div>
                    <div className="electricityHelperTableHeader">
                      <p>Merchant</p>
                    </div>
                    <div className="electricityHelperTableHeader">
                      <p>Amount</p>
                    </div>
                  </div>

                  {transactions?.map((transaction: any) => (
                    <TransactionItem transaction={transaction} />
                  ))}
                </div>
              </div>
            </>
          }
        ></CollapsibleBox>
        <div className="buttonContainer">
          <button type="submit" onClick={() => navigate('/company-information?sessionId=' + sessionId)}>
            Start
          </button>
        </div>
      </div>
      {showDisclaimer && <Disclaimer closeDisclaimer={closeDisclaimer} />}
    </>
  )
}
