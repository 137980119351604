import { useRegion } from '../../context/RegionProvider'
import { mobileCombustionMapping } from '../../data/inputs/mobile-combustion/mobile-combustion.mapping'
import { stationaryCombustionMapping } from '../../data/inputs/stationary-combustion/stationary-combustion.mapping'
import { DistanceTravelledMobile, FuelConsumptionStationary } from '../../interface/inputData.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function FuelConsumptionReview({
  ownedVehicles,
  ownedMachines,
}: {
  ownedVehicles: DistanceTravelledMobile[]
  ownedMachines: FuelConsumptionStationary[]
}) {
  const { country } = useRegion()
  const { distanceUnits, fuelTypes, vehicleTypes } = mobileCombustionMapping[country.code]
  const { machineFuelTypes, stationaryFuelUnits } = stationaryCombustionMapping[country.code]

  return (
    <CollapsibleBox
      header={<div className="subtitle">Fuel Consumption</div>}
      body={
        <>
          <div className="listItem">Owned Vehicles</div>
          {!ownedVehicles.length ? (
            <div className="grayBox notSpecified">No owned vehicles specified</div>
          ) : (
            <div className="reviewTable mt2">
              {ownedVehicles.map((vehicle, index) => (
                <VehicleSection vehicle={vehicle} index={index} key={'vehicle' + index} />
              ))}
            </div>
          )}

          <div className="listItem mt4">Owned Machines</div>
          {!ownedMachines.length ? (
            <div className="grayBox notSpecified">No owned machines specified</div>
          ) : (
            <div className="reviewTable">
              {ownedMachines.map((machine, index) => (
                <MachineSection machine={machine} index={index} key={'machine' + index} />
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function VehicleSection({ vehicle, index }: { vehicle: DistanceTravelledMobile; index: number }) {
    return (
      <>
        <div className="tableHeader" data-cy={`review-vehicle-${index}-header`}>
          Vehicle {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Vehicle Type</div>
        <div className="tableItem">{vehicleTypes[vehicle.vehicleCode]}</div>

        <div className="tableItem">Fuel Type</div>
        <div className="tableItem">{fuelTypes[vehicle.fuelCode]}</div>

        <div className="tableItem">Travelled Distance</div>
        <div className="tableItem">
          {vehicle.value} {distanceUnits[vehicle.unitCode]}
        </div>
      </>
    )
  }

  function MachineSection({ machine, index }: { machine: FuelConsumptionStationary; index: number }) {
    return (
      <>
        <div className="tableHeader" data-cy={`review-machine-${index}-header`}>
          Machine {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Fuel Type</div>
        <div className="tableItem">{machineFuelTypes[machine.fuelCode]}</div>

        <div className="tableItem">Fuel Consumption</div>
        <div className="tableItem">
          {machine.value} {stationaryFuelUnits[machine.unitCode]}
        </div>
      </>
    )
  }
}
