import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useClient } from '../../context/ClientProvider'
import { useReportDates } from '../../context/ReportDatesProvider'
import useMediaQuery from '../../hooks/useMediaQuery'
import ErrorBox from '../boxes/ErrorBox'
import MobileNavbar from '../navbar/MobileNavbar'

export default function SectionHeader({
  title,
  errors,
  showDates = true,
}: {
  title: string
  errors?: string[]
  showDates?: boolean
}) {
  const { startDate, endDate } = useReportDates()
  const { hideTopBar } = useClient()
  const isMobile = useMediaQuery('(max-width: 780px)')
  const [navShow, setNavShow] = useState(false)
  const ref = useRef<any>()

  return (
    <>
      {navShow && <MobileNavbar context={ref} setNavShow={setNavShow} />}
      {!!errors?.length && <ErrorBox errors={errors} />}
      {hideTopBar && isMobile && (
        <div className="mobileMenuIcon" onClick={() => setNavShow(true)}>
          <img src="./icons/layout-web-10.svg" className="collasibleNavbarIcon" alt="" />
          Menu{' '}
        </div>
      )}
      <div className="title">{title}</div>
      {showDates && startDate && endDate && (
        <div className="reportDates" id="reportDates">
          <FontAwesomeIcon icon={faCalendar} /> {startDate} to {endDate}
        </div>
      )}
    </>
  )
}
