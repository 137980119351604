export const heatConsumptionUnits: { [key: string]: string } = {
  KWH: 'kWh',
  MWH: 'MWh',
  MJ: 'MJ',
  MMBTU: 'mmBtu',
}

export const supplierEmissionsFactorUnits: { [key: string]: string } = {
  KGKWH: 'kg CO2e per kWh',
  KGMWH: 'kg CO2e per MWh',
  LBSKWH: 'lbs CO2e per kWh',
  LBSMWH: 'lbs CO2e per MWh',
  MTONKWH: 'metric tons CO2e per kWh',
  MTONMWH: 'metric tons CO2e per MWh',
}

export const heatConsumptionInputsUK = { heatConsumptionUnits, supplierEmissionsFactorUnits }
