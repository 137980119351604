import { Bar } from 'react-chartjs-2'
import { useRegion } from '../../../../context/RegionProvider'
import { mobileCombustionMapping } from '../../../../data/inputs/mobile-combustion/mobile-combustion.mapping'
import { Scope1Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function BenchmarkMobileChart({ data }: { data: Scope1Results }) {
  const { country } = useRegion()
  const { distanceUnits, vehicleTypes } = mobileCombustionMapping[country.code]

  interface Dataset {
    label: string
    data: number[]
    color: string
    hoverColor: string
  }

  let labels: string[] = []
  const datasets: Dataset[] = [
    {
      label: 'Your company',
      data: [],
      color: 'rgba(31, 216, 171, 0.5)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
    {
      label: 'Average company',
      data: [],
      color: 'rgba(31, 149, 216, 0.5)',
      hoverColor: 'rgba(31, 149, 216, 1)',
    },
  ]
  Object.keys(vehicleTypes).forEach((vehicleTypeCode: string) => {
    if (data.benchmarks.DistanceTravelledMobile[vehicleTypeCode]) {
      labels.push(vehicleTypes[vehicleTypeCode])
      datasets[0].data.push(data.benchmarks.DistanceTravelledMobile[vehicleTypeCode].converted_value)
      datasets[1].data.push(data.benchmarks.DistanceTravelledMobile[vehicleTypeCode].benchmark_value)
    }
  })
  return (
    <div className="benchmarkSection" id="pdfScope1BenchmarkMobileChart">
      <div className="chartInfo">
        <div className="textStrong">Vehicle Usage Benchmarking</div>
        <div>
          <p>
            This graph shows a comparison of the distance you drove with your company vehicles, compared to national
            average distances driven by over the same time period, in similar vehicles.
          </p>
          <p>
            This can give you an idea for if you use vehicles more or less than similar companies do. If you are driving
            significantly further than other companies, it may be worth exploring alternatives that help you achieve the
            same business activity with fewer miles travelled in private vehicles.
          </p>
        </div>
      </div>
      <div className="chartContainer">
        <Bar
          id="BenchmarkMobileChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    return context[0].label + ' distance travelled'
                  },
                  label: (context) => {
                    return (
                      formatResult(context.raw, 2) +
                      ' ' +
                      distanceUnits[data.benchmarks.DistanceTravelledMobile.final_unit]
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  count: 6,
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: Dataset) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                hoverBackgroundColor: row.hoverColor,
                borderRadius: 10,
                barPercentage: 0.9,
                categoryPercentage: 0.6,
              }
            }),
          }}
        />
      </div>
    </div>
  )
}
