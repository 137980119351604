import { Outlet } from 'react-router-dom'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSession } from '../context/SessionProvider'
import { useTheme } from '../context/ThemeProvider'
import useMediaQuery from '../hooks/useMediaQuery'
import { useRef, useState, useEffect } from 'react'
import ScrollToTop from '../utils/scrollToTop'
import { useClient } from '../context/ClientProvider'
import EventEmitter from '../utils/EventEmitter'
import DesktopCollapsibleNavbar from './navbar/DesktopCollapsibleNavbar'
import MobileNavbar from './navbar/MobileNavbar'
import { hotjar } from 'react-hotjar'

export default function Root() {
  const { sessionId } = useSession()
  const { logo } = useTheme()
  const isMobile = useMediaQuery('(max-width: 780px)')
  const ref = useRef<any>()
  const { fontURL, hideTopBar, isHotjarEnabled } = useClient()

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_SITE_ID && process.env.REACT_APP_HOTJAR_VERSION) {
      if (isHotjarEnabled) {
        hotjar.initialize(+process.env.REACT_APP_HOTJAR_SITE_ID, +process.env.REACT_APP_HOTJAR_VERSION)
      }
    }
  })

  if (!sessionId) {
    return <div className="loader"></div>
  }

  return (
    <>
      {fontURL && <link href={fontURL} rel="stylesheet"></link>}
      {isMobile ? <MobileLayout /> : <DestktopLayout />}
    </>
  )

  function MobileLayout() {
    return (
      <div className="mobileGrid">
        {!hideTopBar && <MobileTopHeader />}
        <div className="main">
          <ScrollToTop />
          <EventEmitter />
          <Outlet context={ref} />
        </div>
      </div>
    )
  }

  function DestktopLayout() {
    return (
      <div className="desktopGrid">
        {!hideTopBar && (
          <div className="header">
            <img src={logo} alt="logo" className="logo" id="logo" />
          </div>
        )}
        <DesktopCollapsibleNavbar context={ref} />
        <div className="main">
          <ScrollToTop />
          <EventEmitter />
          <Outlet context={ref} />
        </div>
      </div>
    )
  }

  function MobileTopHeader() {
    const [navShow, setNavShow] = useState(isMobile ? false : true)
    return (
      <div className="header">
        <div className="headerBar">
          <img src={logo} alt="logo" className="logo" id="logo" />
          <FontAwesomeIcon
            data-cy="mobile-menu"
            icon={faBars}
            size="2x"
            className="mr-4 mt-4 clickable primaryColor"
            onClick={() => {
              setNavShow(!navShow)
            }}
          ></FontAwesomeIcon>
        </div>
        {navShow && <MobileNavbar context={ref} setNavShow={setNavShow} />}
      </div>
    )
  }
}
