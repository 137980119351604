import { Scope3Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'
import DoughnutChart from '../../../graphs/DoughnutChart'

export default function BusinessTravelPie({
  data,
  businessTransports,
  businessTravelAvailable,
  hotelStaysAvailable,
}: {
  data: Scope3Results
  businessTransports: any
  businessTravelAvailable: boolean
  hotelStaysAvailable: boolean
}) {
  const travelData = Object.entries(
    data.sections_results.BusinessTravel.sections_results.BusinessTravel.section_category_results,
  ).map(([code, item]) => {
    return { name: businessTransports[code], value: item.kg_CO2e }
  })
  const hotelData = [
    { name: 'Hotel Stays', value: data.sections_results.BusinessTravel.sections_results.HotelStays.kg_CO2e },
  ]
  const { largest_subscope_category_display_name, largest_subscope_category_kg_CO2e } =
    data.results_text.BusinessTravel.BusinessTravel.variables
  const { nr_of_nights, nr_of_regions, hotels_kg_CO2e } = data.results_text.BusinessTravel.HotelStays.variables
  return (
    <div className="chartSection40" id="pdfScope3BusinessTravelPieChart">
      <div className="chartContainer">
        <DoughnutChart id="BusinessTravelPieChart" data={[...travelData, ...hotelData]} />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          {businessTravelAvailable && (
            <p>
              This graph highlights distance travelled and emissions per transport type used for business travel. The
              largest portion of your emissions came from <b>{largest_subscope_category_display_name}</b> emitting a
              total of <b>{formatResult(largest_subscope_category_kg_CO2e, 2)}</b> kg CO2e.
            </p>
          )}
          {hotelStaysAvailable && (
            <p>
              Your employees spent <b>{nr_of_nights}</b> days across <b>{nr_of_regions}</b> regions in hotels resulting
              in <b>{formatResult(hotels_kg_CO2e, 0)}</b> kg CO2e of emissions.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
