import React, { createContext, useContext, useEffect, useState } from 'react'
import { getData } from '../api/reportStore'
import { useSession } from './SessionProvider'
import { useRegion } from './RegionProvider'

const ReportDatesContext = createContext<{
  startDate: string | undefined
  endDate: string | undefined
  setDates: Function
}>({
  startDate: undefined,
  endDate: undefined,
  setDates: () => {},
})

export function useReportDates() {
  return useContext(ReportDatesContext)
}

export function ReportDatesProvider({ children }: { children: React.ReactNode }) {
  const { sessionId } = useSession()
  const { country } = useRegion()

  const setDates = (startDate: string, endDate: string) => {
    setState({
      ...state,
      startDate: new Date(startDate).toLocaleDateString(country?.localisationFormat),
      endDate: new Date(endDate).toLocaleDateString(country?.localisationFormat),
    })
  }

  const [state, setState] = useState<{
    startDate: string | undefined
    endDate: string | undefined
    setDates: Function
  }>({
    startDate: undefined,
    endDate: undefined,
    setDates,
  })

  useEffect(() => {
    getData(sessionId).then((data: any) => {
      const companyInformation = data['CompanyInformation']
      setState({
        startDate: new Date(companyInformation?.reportingStartDate).toLocaleDateString(country?.localisationFormat),
        endDate: new Date(companyInformation?.reportingEndDate).toLocaleDateString(country?.localisationFormat),
        setDates,
      })
    })
  }, [sessionId, country])

  return <ReportDatesContext.Provider value={state}>{children}</ReportDatesContext.Provider>
}
