import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Vehicle } from '../../../../interface/formFields.interface'
import { objectToOptions } from '../../../../utils/objectToOptions'
import { useRegion } from '../../../../context/RegionProvider'
import { mobileCombustionMapping } from '../../../../data/inputs/mobile-combustion/mobile-combustion.mapping'

export default function OwnedVehicles({
  vehicleFields,
  appendVehicle,
  getValues,
  register,
  removeVehicle,
  watch,
}: {
  vehicleFields: any
  appendVehicle: any
  getValues: any
  register: any
  removeVehicle: any
  watch: any
}) {
  const { country } = useRegion()
  const defaultVehicle: Vehicle = {
    vehicleCode: 'NONE',
    fuelCode: 'NONE',
    value: 0,
    unitCode: country ? country.defaultUnits.distance : '',
  }

  const { distanceUnits, vehicleTypes, fuelTypes, fuelsPerVehicle } = mobileCombustionMapping[country.code]
  const vehicleTypeOptions: Array<JSX.Element> = objectToOptions(vehicleTypes)
  const vehicleUnitOptions: Array<JSX.Element> = objectToOptions(distanceUnits)

  function getVehicleFuelTypeOptions(vehicleCode: string) {
    const fuelTypeOptions = []
    for (let key in fuelTypes) {
      if (fuelsPerVehicle?.[vehicleCode]?.includes(key)) {
        fuelTypeOptions.push(
          <option value={key} key={key}>
            {fuelTypes[key]}
          </option>,
        )
      }
    }
    return fuelTypeOptions
  }

  return (
    <div>
      {!vehicleFields.length && (
        <div className="emptyPlaceholder">Add vehicles or leave blank if you do not own any vehicles.</div>
      )}
      {vehicleFields.map((field: any, index: number) => (
        <VehicleTemplate index={index} key={field.id} />
      ))}
      <div className="addButton" data-cy="add-vehicle-button" onClick={() => appendVehicle(defaultVehicle)}>
        <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add vehicle
      </div>
    </div>
  )

  function VehicleTemplate({ index }: { index: number }) {
    watch(`vehicles.${index}.vehicleCode`)
    const vehicle = getValues(`vehicles.${index}`)
    const vehicleFuelTypeOptions = getVehicleFuelTypeOptions(vehicle.vehicleCode)

    return (
      <div className="itemCard">
        <div className="itemTitle">
          <div>Vehicle {index + 1}</div>
          <FontAwesomeIcon
            className="clickable gray6Color"
            data-cy={`delete-vehicle-${index}-icon`}
            icon={faTrash}
            onClick={() => removeVehicle(index)}
          ></FontAwesomeIcon>
        </div>
        <div key={'vehicle' + index}>
          <div className="itemInputsContainer">
            <div className="itemInput">
              <div className="itemLabel">Vehicle Type</div>
              <select {...register(`vehicles.${index}.vehicleCode`)}>{vehicleTypeOptions}</select>
            </div>
            <div className="itemInput">
              <div className="itemLabel">Fuel Type</div>
              <select {...register(`vehicles.${index}.fuelCode`)}>{vehicleFuelTypeOptions}</select>
            </div>
            <div className="itemInput">
              <div className="itemLabel">Travelled Distance</div>
              <div className="inlineInputs">
                <input
                  className="width100"
                  type="number"
                  step="any"
                  inputMode="decimal"
                  {...register(`vehicles.${index}.value`, {
                    valueAsNumber: true,
                  })}
                />
                <select className="width100" {...register(`vehicles.${index}.unitCode`)}>
                  {vehicleUnitOptions}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
