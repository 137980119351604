export function processDelete(data: any, code: string, mcc: string) {
  const prevValue = data.categories[code]['mccs'][mcc].userDeleted
  data.categories[code]['mccs'][mcc].userDeleted = !prevValue
  data = recalculateTotals(data, code)
  return data
}

function recalculateTotals(data: any, code: string) {
  data.categories[code].totalAmount = 0
  data.categories[code].totalKgCO2e = 0
  Object.values(data.categories[code]['mccs']).forEach((mccGroup: any) => {
    if (!mccGroup.userDeleted) {
      data.categories[code].totalAmount += mccGroup.spend
      data.categories[code].totalKgCO2e += mccGroup.kgCO2e
    }
  })
  data.totalAmount = 0
  data.totalKgCO2e = 0
  Object.values(data.categories).forEach((category: any) => {
    data.totalAmount += category.totalAmount
    data.totalKgCO2e = category.totalKgCO2e
  })
  return data
}
