interface AdditionalParams {
  wholeNumber?: boolean
  greaterThanZero?: boolean
}

export default function validateNumericInput(value: number, params?: AdditionalParams): true | string {
  if (value >= Number.MAX_SAFE_INTEGER) {
    return 'The value you entered exceeds the maximum allowed limit.'
  }
  if (isNaN(value)) {
    return 'Please enter a valid number.'
  }
  if (params?.wholeNumber && value % 1 !== 0) {
    return 'Please enter a whole number.'
  }
  if (value <= 0 && params?.greaterThanZero) {
    return 'Please enter a value greater than 0.'
  }
  if (value < 0) {
    return 'Please enter a non-negative number.'
  }
  return true
}
