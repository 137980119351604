import axios from 'axios'
import { Session } from '../interface/reportSession.interface'

export function getSession(sessionId: string): Promise<Session> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/validate-session',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        sessionId,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        console.error(err)
      })
  })
}
