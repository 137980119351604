import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useClient } from '../../context/ClientProvider'

export default function BoxHeader({
  closePopup,
  subtitle,
  children,
}: {
  closePopup: Function
  subtitle: String
  children: React.ReactNode
}) {
  const { isFullScreenEnabled } = useClient()
  return (
    <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
      <div className={isFullScreenEnabled ? 'modalContent fullScreen' : 'modalContent'}>
        <div className="boxHeader subtitle">
          <div className="subtitle">{subtitle}</div>
          <div className="icons">
            <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => closePopup()} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
