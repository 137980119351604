import { ElectricityTransaction } from '../../../../interface/transactionData.interface'
import ReactSlider from 'react-slider'
import { useState, useEffect } from 'react'
import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function ElectricityConsumptionHelper({
  electricityTransactions,
}: {
  electricityTransactions: ElectricityTransaction[]
}) {
  const [arrayOfConsumptions, setArrayOfConsumptions] = useState<number[]>([])
  const [arrayofValues, setArrayOfValues] = useState<number[]>([])
  const [totalConsumption, setTotalConsumption] = useState<number>(0)

  useEffect(() => {
    let newArrayOfConsumptions: number[] = []
    let newArrayOfValues: number[] = []
    if (electricityTransactions && electricityTransactions.length) {
      for (let i = 0; i < electricityTransactions.length; i++) {
        newArrayOfConsumptions[i] = electricityTransactions[i].share * electricityTransactions[i].avgConsumptionKwh
        newArrayOfValues[i] = electricityTransactions[i].share * 100
      }
    }
    setArrayOfConsumptions(newArrayOfConsumptions)
    setArrayOfValues(newArrayOfValues)
    calculateTotalConsumption(newArrayOfConsumptions)
  }, [electricityTransactions])

  function calculateTotalConsumption(arrayOfConsumptions: number[]) {
    let newTotalConsumption: number = 0
    if (arrayOfConsumptions && arrayOfConsumptions.length) {
      for (let i = 0; i < arrayOfConsumptions.length; i++) {
        newTotalConsumption += arrayOfConsumptions[i]
      }
    }
    setTotalConsumption(newTotalConsumption)
  }

  useEffect(() => {
    calculateTotalConsumption(arrayOfConsumptions)
  }, [arrayOfConsumptions])

  function ElectricityTransactionItem({
    electricityTransaction,
    i,
  }: {
    electricityTransaction: ElectricityTransaction
    i: number
  }) {
    const [currentValue, setCurrentValue] = useState(Math.round(arrayofValues[i]))
    const [currentConsumption, setCurrentConsumption] = useState(
      Math.round(arrayofValues[i] * 0.01 * electricityTransaction.avgConsumptionKwh * 10) / 10,
    )

    return (
      <div className="electricityHelperTableRow">
        <div className="electricityHelperTableItem">{electricityTransaction.name}</div>
        <div className="electricityHelperTableItem">{electricityTransaction.transactionDate.slice(0, 10)}</div>
        <div className="electricityHelperTableItem">{currentConsumption} kWh</div>
        <div className="electricityHelperTableItemSlider">
          <div className="electricityHelperTableItemSliderItemPercentage">
            <p>{currentValue} %</p>
          </div>
          <div className="electricityHelperTableItemSliderItemElement">
            <ReactSlider
              className="customSlider"
              trackClassName="customSlider-track"
              thumbClassName="customSlider-thumb"
              value={arrayofValues[i]}
              onChange={(value) => {
                setCurrentValue(value)
              }}
              onAfterChange={(value) => {
                let newArrayOfValues = arrayofValues
                newArrayOfValues[i] = value
                setArrayOfValues(newArrayOfValues)
                setCurrentConsumption(Math.round(value * 0.01 * electricityTransaction.avgConsumptionKwh * 10) / 10)
                let newArrayOfConsumptions = arrayOfConsumptions
                newArrayOfConsumptions[i] =
                  Math.round(value * 0.01 * electricityTransaction.avgConsumptionKwh * 10) / 10
                setArrayOfConsumptions(newArrayOfConsumptions)
                calculateTotalConsumption(newArrayOfConsumptions)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  if (electricityTransactions && electricityTransactions.length > 0) {
    return (
      <CollapsibleBox
        isCollapsed={true}
        header={
          <div className="textLarge">
            Not sure what your total electricity consumption is? Try estimating based on your transactions below
          </div>
        }
        body={
          <>
            <div className="sectionBox">
              <div className="electricityHelperTable mt4" id="ElectricityHelperTable">
                <div className="electricityHelperTableRow">
                  <div className="electricityHelperTableHeader">
                    <p>Name of Transaction</p>
                  </div>
                  <div className="electricityHelperTableHeader">
                    <p>Date of Transaction</p>
                  </div>
                  <div className="electricityHelperTableHeader">
                    <p>Estimated Consumption</p>
                  </div>
                  <div className="electricityHelperTableHeader">
                    How much of this transaction was spent on electricity?
                  </div>
                </div>

                {electricityTransactions?.map((electricityTransaction: ElectricityTransaction, i: number) => (
                  <ElectricityTransactionItem electricityTransaction={electricityTransaction} i={i} />
                ))}
              </div>

              <div className="textLarge">
                Your estimated electricity consumption is:{' '}
                <span className="bold">{Math.round(totalConsumption * 10) / 10} kWh </span>
              </div>
              <div className="textSmall">
                <p>
                  To use this estimate as your electricity consumption amount for your report, please copy and paste the
                  kWh amount into the 'location' input boxes below
                </p>
              </div>
            </div>
          </>
        }
      ></CollapsibleBox>
    )
  } else {
    return null
  }
}
