import { faXmark, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { countriesList } from '../../../../data/countriesList'

export function ConfirmCountryPopup({
  isFullScreenEnabled,
  closePopup,
  initialCountry,
  values,
  confirmReset,
}: {
  isFullScreenEnabled: boolean | undefined
  closePopup: any
  initialCountry: any
  values: any
  confirmReset: any
}) {
  return (
    <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
      <div className={isFullScreenEnabled ? 'modalContent fullScreen confirmation' : 'modalContent confirmation'}>
        <div className="displayEnd">
          <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => closePopup()} />
        </div>
        <div className="displayCenter">
          <FontAwesomeIcon icon={faWarning} className="warningColor" size="3x" />
        </div>
        <div className="subtitle">Are you sure you want to change the report country? </div>
        <div className="grayBox">
          <div className="listItem">
            <span className="bold">Current Country: </span>
            {countriesList[initialCountry].name}
          </div>
          <div className="listItem">
            <span className="bold">New Country: </span>
            {countriesList[values.countryCode].name}
          </div>
        </div>
        <p>
          By modifying the report country, you may be affecting the accuracy of the data.
          <span className="bold">
            {' '}
            All sections will need to be entered again and reviewed to ensure data integrity
          </span>
          .
        </p>
        <div className="buttonContainer">
          <div className="destructiveButton" onClick={() => confirmReset()}>
            Confirm
          </div>
          <div className="secondaryButton" onClick={closePopup}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}
