import html2canvas from 'html2canvas'
import { addLogo } from './common'

export async function generate1PagePDF(pdf: any, pdfWidth: number, pdfHeight: number, logo: any) {
  try {
    const offsetWidth = 50

    //Add Logo
    pdf = await addLogo(pdf, pdfWidth, pdfHeight, logo)

    //Add Title
    const ghgReportTitle = await html2canvas(document.querySelector('#pdfGhgReportTitle')!, {
      scale: 2,
      windowWidth: 1200,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('pdfGhgReportTitle')!.innerHTML = 'Carbon Emissions Report'
        clonedDoc.getElementById('pdfOverview')?.setAttribute('style', 'width:1000px')
      },
    })
    const imgGhgReportTitle = ghgReportTitle.toDataURL('image/png')
    const imgPropertiesGhgReportTitle = pdf.getImageProperties(imgGhgReportTitle)
    const pdfHeightGhgReportTitle = pdfHeight * 0.035
    const pdfWidthGhgReportTitle =
      (imgPropertiesGhgReportTitle.width * pdfHeightGhgReportTitle) / imgPropertiesGhgReportTitle.height
    pdf.addImage(
      imgGhgReportTitle,
      'PNG',
      pdfWidth * 0.05,
      pdfHeight * 0.1,
      pdfWidthGhgReportTitle,
      pdfHeightGhgReportTitle,
    )

    //Add dates
    const dates = await html2canvas(document.querySelector('#reportDates')!, {
      scale: 2,
      windowWidth: 1200,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('reportDates')?.setAttribute('style', 'width:800px')
      },
    })
    const imgDates = dates.toDataURL('image/png')
    const imgPropertiesDates = pdf.getImageProperties(imgDates)
    const pdfWidthDates = pdfWidth - 2 * offsetWidth
    const pdfHeightDates = (imgPropertiesDates.height * pdfWidthDates) / imgPropertiesDates.width
    pdf.addImage(imgDates, 'PNG', pdfWidth * 0.04, pdfHeight * 0.14, pdfWidthDates, pdfHeightDates)

    //Add Overview
    const ghgOverview = await html2canvas(document.querySelector('#pdfOverview')!, {
      scale: 2,
      windowWidth: 1200,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('pdfOverview')?.setAttribute('style', 'width:900px')
      },
    })
    const imgGhgOverview = ghgOverview.toDataURL('image/png')
    const imgPropertiesGhgOverview = pdf.getImageProperties(imgGhgOverview)
    const pdfWidthGhgOverview = pdfWidth - 2 * offsetWidth
    const pdfHeightGhgOverview =
      (imgPropertiesGhgOverview.height * pdfWidthGhgOverview) / imgPropertiesGhgOverview.width
    pdf.addImage(imgGhgOverview, 'PNG', pdfWidth * 0.08, pdfHeight * 0.16, pdfWidthGhgOverview, pdfHeightGhgOverview)

    //Add SubTitle
    const ghgReportSubTitle = await html2canvas(document.querySelector('#pdfGhgReportSubTitle')!, {
      scale: 2,
      windowWidth: 1550,
    })
    const imgGhgReportSubTitle = ghgReportSubTitle.toDataURL('image/png')
    const imgPropertiesGhgReportSubTitle = pdf.getImageProperties(imgGhgReportSubTitle)
    const pdfHeightGhgReportSubTitle = pdfHeight * 0.025
    const pdfWidthGhgReportSubTitle =
      (imgPropertiesGhgReportSubTitle.width * pdfHeightGhgReportSubTitle) / imgPropertiesGhgReportSubTitle.height
    pdf.addImage(
      imgGhgReportSubTitle,
      'PNG',
      pdfWidth * 0.05,
      pdfHeight * 0.325,
      pdfWidthGhgReportSubTitle,
      pdfHeightGhgReportSubTitle,
    )

    //Add P1
    const ghgReportP1 = await html2canvas(document.querySelector('#pdfGhgReportP1')!, {
      scale: 2,
      windowWidth: 1000,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('pdfGhgReportP1')?.setAttribute('style', 'width:900px')
      },
    })
    const imgGhgReportP1 = ghgReportP1.toDataURL('image/png')
    const imgPropertiesGhgReportP1 = pdf.getImageProperties(imgGhgReportP1)
    const pdfWidthGhgReportP1 = pdfWidth - 2 * offsetWidth
    const pdfHeightGhgReportP1 =
      (imgPropertiesGhgReportP1.height * pdfWidthGhgReportP1) / imgPropertiesGhgReportP1.width
    pdf.addImage(imgGhgReportP1, 'PNG', pdfWidth * 0.05, pdfHeight * 0.37, pdfWidthGhgReportP1, pdfHeightGhgReportP1)

    //Add Table
    const ghgReportTable = await html2canvas(document.querySelector('#pdfGhgReportTable')!, {
      scale: 2,
      windowWidth: 1200,
      onclone: function (clonedDoc) {
        clonedDoc.querySelector('.thirdColumn')?.setAttribute('style', 'display:true')
        clonedDoc.getElementById('pdfGhgReportTable')?.setAttribute('style', 'width:900px')
      },
    })
    const imgGhgReportTable = ghgReportTable.toDataURL('image/png')
    const imgPropertiesGhgReportTable = pdf.getImageProperties(imgGhgReportTable)
    const pdfWidthGhgReportTable = pdfWidth - 2 * offsetWidth
    const pdfHeightGhgReportTable =
      (imgPropertiesGhgReportTable.height * pdfWidthGhgReportTable) / imgPropertiesGhgReportTable.width
    pdf.addImage(
      imgGhgReportTable,
      'PNG',
      offsetWidth,
      pdfHeight * 0.42,
      pdfWidthGhgReportTable,
      pdfHeightGhgReportTable,
    )

    //Add Icon
    var imgLogoHeader = new Image()
    const iconResp = await fetch('/img/fa-info-circle.png')
    imgLogoHeader.src = iconResp.url
    const imgPropertiesLogoHeader = pdf.getImageProperties(imgLogoHeader)
    const pdfHeightLogoHeader = pdfHeight * 0.01
    const pdfWidthLogoHeader = (imgPropertiesLogoHeader.width * pdfHeightLogoHeader) / imgPropertiesLogoHeader.height
    pdf.addImage(imgLogoHeader, 'PNG', pdfWidth * 0.05, pdfHeight * 0.96, pdfWidthLogoHeader, pdfHeightLogoHeader)

    //Add Disclaimer
    const overviewDisclaimer = await html2canvas(document.querySelector('#pdfGhgReportP1')!, {
      scale: 2,
      windowWidth: 1200,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('pdfGhgReportP1')!.innerHTML =
          'Please refer to the carbon calculator for the full methodology and limitations of this data'
        clonedDoc.getElementById('pdfGhgReportP1')?.setAttribute('style', 'width:800px')
      },
    })
    const imgOverviewDisclaimer = overviewDisclaimer.toDataURL('image/png')
    const imgPropertiesOverviewDisclaimer = pdf.getImageProperties(imgOverviewDisclaimer)
    const pdfWidthOverviewDisclaimer = pdfWidth - 2 * offsetWidth
    const pdfHeightOverviewDisclaimer =
      (imgPropertiesOverviewDisclaimer.height * pdfWidthOverviewDisclaimer) / imgPropertiesOverviewDisclaimer.width
    pdf.addImage(
      imgOverviewDisclaimer,
      'PNG',
      pdfWidth * 0.075,
      pdfHeight * 0.959,
      pdfWidthOverviewDisclaimer,
      pdfHeightOverviewDisclaimer,
    )
  } catch (error) {
    console.log(error)
  }

  return pdf
}
