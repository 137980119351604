import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useState } from 'react'
import { processTransactions } from '../api/processTransactions'
import { saveData } from '../api/reportStore'
import { useSession } from '../context/SessionProvider'
import { useClient } from '../context/ClientProvider'

export default function ReloadTransactionsButton({ params }: any) {
  const { sessionId } = useSession()
  const { isFullScreenEnabled } = useClient()
  const [showPopup, setShowPopup] = useState(false)

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const reloadTransactions = async () => {
    await processTransactions(
      sessionId,
      moment(params.reportingStartDate, 'YYYY-MM-DD').startOf('day').toISOString(),
      moment(params.reportingEndDate, 'YYYY-MM-DD').endOf('day').toISOString(),
      params.countryCode,
    )
    await saveData(sessionId, { PurchasedGoods: [] })
    closePopup()
    window.location.reload()
  }

  return (
    <>
      <button type="button" onClick={openPopup}>
        Refresh transactions
      </button>
      {showPopup && <AreYouSureConfirmation />}
    </>
  )

  function AreYouSureConfirmation() {
    return (
      <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
        <div className={isFullScreenEnabled ? 'modalContent fullScreen confirmation' : 'modalContent confirmation'}>
          <div className="boxHeader subtitle">
            Are you sure? <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => closePopup()} />
          </div>
          <div>
            Refreshing transactions will process again all the transactions from your bank accounts for the selected
            reporting period and delete all manual changes done in this page.
          </div>
          <div className="buttonContainer">
            <button type="button" onClick={closePopup}>
              Cancel
            </button>
            <button type="button" onClick={async () => await reloadTransactions()}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    )
  }
}
