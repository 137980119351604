import Intro from '../components/forms/Intro'
import CompanyInformation from '../components/forms/company-information/CompanyInformation'
import FuelConsumption from '../components/forms/fuel-consumption/FuelConsumption'
import ElectricityConsumption from '../components/forms/electricity-consumption/ElectricityConsumption'
import HeatConsumption from '../components/forms/heat-consumption/HeatConsumption'
import EmployeeCommuting from '../components/forms/employee-commuting/EmployeeCommuting'
import BusinessTravel from '../components/forms/business-travel/BusinessTravel'
import PurchasedGoods from '../components/forms/purchased-goods/PurchasedGoods'
import Results from '../components/Results'
import Review from '../components/Review'
import Insights from '../components/Insights'
export interface RouteItem {
  name: string
  displayName: string
  path: string
  element: JSX.Element
  delimiter?: boolean
  isDisabled?: boolean
}

export const routes: RouteItem[] = [
  {
    name: 'Introduction',
    displayName: 'Introduction',
    path: '/',
    element: <Intro />,
    delimiter: true,
  },
  {
    name: 'CompanyInformation',
    displayName: 'Company Information',
    path: '/company-information',
    element: <CompanyInformation />,
  },
  {
    name: 'FuelConsumption',
    displayName: 'Fuel Consumption',
    path: '/fuel-consumption',
    element: <FuelConsumption />,
  },
  {
    name: 'ElectricityConsumption',
    displayName: 'Electricity Consumption',
    path: '/electricity-consumption',
    element: <ElectricityConsumption />,
    isDisabled: true,
  },
  {
    name: 'HeatConsumption',
    displayName: 'Heat Consumption',
    path: '/heat-consumption',
    element: <HeatConsumption />,
    isDisabled: true,
  },
  {
    name: 'BusinessTravel',
    displayName: 'Business Travel',
    path: '/business-travel',
    element: <BusinessTravel />,
    isDisabled: true,
  },
  {
    name: 'EmployeeCommuting',
    displayName: 'Employee Commuting',
    path: '/employee-commuting',
    element: <EmployeeCommuting />,
    isDisabled: true,
  },
  {
    name: 'PurchasedGoods',
    displayName: 'Purchased Goods and Services',
    path: '/purchased-goods',
    element: <PurchasedGoods />,
    delimiter: true,
  },
  {
    name: 'Review',
    displayName: 'Review',
    path: '/review',
    element: <Review />,
  },
  { name: 'Results', displayName: 'Results', path: '/results', element: <Results />, delimiter: true },
  { name: 'Insights', displayName: 'Insights & Next Steps', path: '/insights', element: <Insights /> },
]
