import { ThemeProvider } from './context/ThemeProvider'
import { RegionProvider } from './context/RegionProvider'
import { SessionProvider } from './context/SessionProvider'
import { Amplify, API } from 'aws-amplify'
import awsconfig from './aws-exports'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from './components/Root'
import { routes } from './routes/routes'
import { ClientProvider } from './context/ClientProvider'
import { ReportDatesProvider } from './context/ReportDatesProvider'
Amplify.configure(awsconfig)
API.configure(awsconfig)

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: routes,
    },
  ])

  return (
    <SessionProvider>
      <ClientProvider>
        <ThemeProvider>
          <RegionProvider>
            <ReportDatesProvider>
              <RouterProvider router={router} />
            </ReportDatesProvider>
          </RegionProvider>
        </ThemeProvider>
      </ClientProvider>
    </SessionProvider>
  )
}

export default App
