import { Country } from '../../../../data/countriesList'
import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function PurchasedGoodsInfoHeader({ country }: { country: Country }) {
  return (
    <CollapsibleBox
      isCollapsed={false}
      header={
        <div className="textLarge">
          This section allows us to provide you with an estimate for the emissions associated with goods and services
          purchased by your {country.code === 'UK' ? 'organisation' : 'organization'}
        </div>
      }
      body={
        <>
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>
              1. The more information you can provide in this section the better, as it can constitute more than half of
              your {country.code === 'UK' ? 'organisation' : 'organization'}'s carbon footprint
            </p>
            <p>
              2. Some services (e.g., transportation and distribution) are not recorded here in accordance with the GHG
              Protocol definition of Purchased Goods and Services{' '}
            </p>
            <p>
              3. The transactions are {country.code === 'UK' ? 'categorised' : 'categorized'} based on information
              assigned by your bank. However, it is important to review the major transactions below, remove any that
              have been incorrectly classified, and add any spend not captured in your bank account transactions
            </p>
            <p>
              4. Under “Additional spend” please only put total expenses for the category, that are <i>not</i> listed
              under “Included transactions"
            </p>
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
