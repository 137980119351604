import { Bar } from 'react-chartjs-2'
import { Scope2Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function AnalysisBarChart({ data }: { data: Scope2Results }) {
  interface Dataset {
    label: string
    data: number[]
    color: string
    hoverColor: string
  }

  const labels: string[] = ['Electricity', 'Heat']
  const datasets: any[] = [
    {
      label: 'Location based',
      data: [
        data.sections_results.ElectricityConsumption.kg_CO2e_location_based,
        data.sections_results.HeatConsumption.kg_CO2e_location_based,
      ],
      color: 'rgba(31, 216, 171, 0.5)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
    {
      label: 'Market based',
      data: [
        data.sections_results.ElectricityConsumption.kg_CO2e_market_based,
        data.sections_results.HeatConsumption.kg_CO2e_market_based,
      ],
      color: 'rgba(31, 149, 216, 0.5)',
      hoverColor: 'rgba(31, 149, 216, 1)',
    },
  ]

  const {
    largest_subscope_display_name,
    largest_subscope_value,
    largest_subscope_pct,
    smallest_subscope_display_name,
    smallest_subscope_value,
    smallest_subscope_pct,
  } = data.results_text.variables

  return (
    <div className="chartSection60" id="pdfScope2AnalysisChart">
      <div className="chartContainer">
        {' '}
        <Bar
          id="AnalysisChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    return context[0].label + ' consumption emissions'
                  },
                  label: (context) => {
                    return formatResult(context.raw, 2) + ' kg CO2e'
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  count: 6,
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: Dataset) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                hoverBackgroundColor: row.hoverColor,
                borderRadius: 10,
                barPercentage: 0.9,
                categoryPercentage: 0.6,
              }
            }),
          }}
        />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          The majority of your Scope 2 emissions come from <b>{largest_subscope_display_name}</b> at{' '}
          {formatResult(largest_subscope_value, 0)} kg CO2e (<b>{formatResult(largest_subscope_pct, 2)}</b> % of your
          Scope 2 emissions) compared to <b>{formatResult(smallest_subscope_display_name, 2)}</b> with{' '}
          {formatResult(smallest_subscope_value, 0)} kg CO2e (<b>{formatResult(smallest_subscope_pct, 2)}</b> % of your
          Scope 2 emissions).
        </div>
      </div>
    </div>
  )
}
