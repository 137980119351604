import useMediaQuery from '../../../hooks/useMediaQuery'
import { Scope3Results } from '../../../interface/result.interface'
import formatResult from '../../../utils/formatResult'
import CollapsibleBox from '../../boxes/CollapsibleBox'
import LockedTextBox from '../../boxes/LockedTextBox'
import { useRegion } from '../../../context/RegionProvider'
import { mobileCombustionMapping } from '../../../data/inputs/mobile-combustion/mobile-combustion.mapping'
import { businessTravelMapping } from '../../../data/inputs/business-travel/business-travel.mapping'
import { employeeCommutingMapping } from '../../../data/inputs/employee-commuting/employee-commuting.mapping'
import PieChart from './components/PieChart'
import PurchasedGoodsChart from './components/PurchasedGoodsChart'
import BusinessTravelPie from './components/BusinessTravelPie'
import BusinessTravelBenchmark from './components/BusinessTravelBenchmark'
import EmployeeCommutingPie from './components/EmployeeCommutingPie'
import EmployeeCommutingBenchmark from './components/EmployeeCommutingBenchmark'

export default function Scope3({
  data,
  dataAvailable,
  isFreeUser,
}: {
  data: Scope3Results
  dataAvailable: any
  isFreeUser: any
}) {
  const isMobile = useMediaQuery('(max-width: 780px)')
  const { country } = useRegion()
  const { distanceUnits } = mobileCombustionMapping[country.code]
  const { businessTransports, businessTransportsGrouped } = businessTravelMapping[country.code]
  const { modeOfTransports } = employeeCommutingMapping[country.code]

  const purchasedGoodsAvailable =
    Object.keys(data.sections_results.PurchasedGoods.section_category_results).length > 0 &&
    data.sections_results.PurchasedGoods.converted_value > 0
  const businessTravelSections = data.sections_results.BusinessTravel.sections_results
  const businessTravelAvailable = Object.keys(businessTravelSections.BusinessTravel.section_category_results).length > 0
  const hotelStaysAvailable = Object.keys(businessTravelSections.HotelStays.section_category_results).length > 0
  const businessTravelBenchmarkAvailable = data.benchmarks.BusinessTravel.benchmark_value
  const employeeCommutingAvailable =
    Object.keys(data.sections_results.EmployeeCommuting.section_category_results).length > 0
  const employeeCommutingBenchmarkAvailable = data.benchmarks.EmployeeCommuting.benchmark_value
  const body = isFreeUser ? (
    <LockedTextBox dataCy="scope-3-locked" />
  ) : !!dataAvailable ? (
    <div>
      <PieChart data={data} />
      {purchasedGoodsAvailable ? (
        <>
          <div className="divider" />
          <div className="resultSectionTitle">Purchased Goods and Services</div>
          <PurchasedGoodsChart data-cy="purchased-goods-chart" data={data} isMobile={isMobile} />
        </>
      ) : (
        <div className="noDataText">No data for Purchased Goods and Services</div>
      )}
      <div className="divider" />
      <div className="resultSectionTitle" id="pdfScope3BusinessTravelTitle">
        Business Travel and Hotels
      </div>
      {(businessTravelAvailable || hotelStaysAvailable) && (
        <BusinessTravelPie
          data={data}
          businessTransports={businessTransports}
          businessTravelAvailable={businessTravelAvailable}
          hotelStaysAvailable={hotelStaysAvailable}
        />
      )}
      {businessTravelBenchmarkAvailable && (
        <BusinessTravelBenchmark
          data={data}
          businessTransportsGrouped={businessTransportsGrouped}
          distanceUnits={distanceUnits}
          isMobile={isMobile}
        />
      )}
      {!businessTravelAvailable && !hotelStaysAvailable && !businessTravelBenchmarkAvailable && (
        <div className="noDataText">No data for Business Travel and Hotels</div>
      )}
      <div className="divider" />
      <div className="resultSectionTitle" id="pdfScope3EmployeeCommutingTitle">
        Employee Commuting
      </div>
      {employeeCommutingAvailable && <EmployeeCommutingPie data={data} modeOfTransports={modeOfTransports} />}
      {employeeCommutingBenchmarkAvailable && (
        <EmployeeCommutingBenchmark
          data={data}
          businessTransportsGrouped={businessTransportsGrouped}
          distanceUnits={distanceUnits}
          isMobile={isMobile}
        />
      )}
      {!employeeCommutingAvailable && !employeeCommutingBenchmarkAvailable && (
        <div className="noDataText">No data for Employee Commuting</div>
      )}
    </div>
  ) : (
    <div>No data available for Scope 3</div>
  )
  return (
    <CollapsibleBox
      header={
        <>
          {isMobile ? (
            <div className="resultSubTitle" id="pdfScope3Title">
              <div>Other Indirect Emissions (Scope 3)</div>
              <div className="scopeTotalEmissions">
                Total Emissions: <span className="textStrong">{formatResult(data.kg_CO2e, 0)} kg</span>
              </div>
            </div>
          ) : (
            <div className="resultSubTitle" id="pdfScope3Title">
              Other Indirect Emissions (Scope 3){' '}
              <span className="ml4 textStrong" data-cy="scope-3-other-indirect-emissions-value">
                {formatResult(data.kg_CO2e, 0)}
              </span>{' '}
              kg
              {isFreeUser && (
                <div className="textSmallSection">
                  This section shows a more detailed breakdown of your Scope 3 emissions
                </div>
              )}
            </div>
          )}
        </>
      }
      body={body}
    ></CollapsibleBox>
  )
}
