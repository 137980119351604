import { useRegion } from '../../context/RegionProvider'
import { employeeCommutingMapping } from '../../data/inputs/employee-commuting/employee-commuting.mapping'
import { mobileCombustionMapping } from '../../data/inputs/mobile-combustion/mobile-combustion.mapping'
import { EmployeeCommuting } from '../../interface/inputData.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function EmployeeCommutingReview({ transports }: { transports: EmployeeCommuting[] }) {
  const { country } = useRegion()
  const { distanceUnits } = mobileCombustionMapping[country.code]
  const { modeOfTransports } = employeeCommutingMapping[country.code]

  return (
    <CollapsibleBox
      header={<div className="subtitle">Employee Commuting</div>}
      body={
        <>
          {!transports.length ? (
            <div className="grayBox notSpecified">No employee commuting specified</div>
          ) : (
            <div className="reviewTable mt2">
              {transports.map((transport, index) => (
                <TransportSection transport={transport} index={index} key={'transport' + index} />
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function TransportSection({ transport, index }: { transport: EmployeeCommuting; index: number }) {
    const { transportModeCode, value, unitCode } = transport
    return (
      <>
        <div className="tableHeader" data-cy={`review-mode-of-transport-${index}-employee-commuting-header`}>
          Mode of Transport {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Transport Type</div>
        <div className="tableItem">{modeOfTransports[transportModeCode]}</div>

        <div className="tableItem">Travelled Distance</div>
        <div className="tableItem">{value + ' ' + distanceUnits[unitCode]}</div>
      </>
    )
  }
}
