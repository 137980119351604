import axios from 'axios'

export function processTransactions(
  sessionId: string,
  startDate: string,
  endDate: string,
  country: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/transactions/process',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        sessionId,
        startDate,
        endDate,
        country,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        console.error(`Unable to process transactions. Reason: ${err}`)
      })
  })
}
