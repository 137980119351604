import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import NavBarContent from './NavBarContent'

export default function DesktopCollapsibleNavbar({ context }: { context: React.MutableRefObject<any> }) {
  const [navShow, setNavShow] = useState(true)
  if (navShow) {
    return (
      <div className="navbar">
        <div className="navbarHeader">
          <div className="navbarTitle">My Tasks</div>
          <FontAwesomeIcon
            icon={faAngleDoubleLeft}
            className="clickable"
            onClick={() => setNavShow(false)}
            data-cy="collapse-navbar-button"
          />
        </div>
        <NavBarContent context={context} />
      </div>
    )
  }
  return (
    <div className="collapsedNavbar">
      <img src="./icons/layout-web-10.svg" alt="" className="clickable gray5Color" onClick={() => setNavShow(true)} />
    </div>
  )
}
