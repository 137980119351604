import React, { createContext, useContext, useEffect, useState } from 'react'
import { getData } from '../api/reportStore'
import { countriesList, Country } from '../data/countriesList'
import { useSession } from './SessionProvider'

interface Region {
  country: Country
  setCountry: Function
}

const RegionContext = createContext<Region>({
  country: countriesList['US'],
  setCountry: () => {},
})

export function useRegion() {
  return useContext(RegionContext)
}

export function RegionProvider({ children }: { children: React.ReactNode }) {
  const setCountry = (country: Country) => {
    setState({ ...state, country })
  }

  const initState = {
    country: countriesList['US'],
    setCountry,
  }

  const [state, setState] = useState<Region>(initState)
  const { sessionId } = useSession()

  useEffect(() => {
    if (sessionId) {
      getData(sessionId).then((data: any) => {
        const countryCode = data?.CompanyInformation?.countryCode
        if (countryCode) {
          setState({
            country: countriesList[countryCode],
            setCountry,
          })
        }
      })
    }
  }, [sessionId])

  return <RegionContext.Provider value={state}>{children}</RegionContext.Provider>
}
