import useMediaQuery from '../../../hooks/useMediaQuery'
import { Scope2Results } from '../../../interface/result.interface'
import formatResult from '../../../utils/formatResult'
import CollapsibleBox from '../../boxes/CollapsibleBox'
import LockedTextBox from '../../boxes/LockedTextBox'
import AnalysisBarChart from './components/AnalysisBarChart'
import BenchmarkBarChart from './components/BenchmarkBarChart'

export default function Scope2({
  data,
  dataAvailable,
  isFreeUser,
}: {
  data: Scope2Results
  dataAvailable: any
  isFreeUser: any
}) {
  const isMobile = useMediaQuery('(max-width: 780px)')

  const body = isFreeUser ? (
    <LockedTextBox dataCy="scope-2-locked" />
  ) : !!dataAvailable ? (
    <div>
      <AnalysisBarChart data={data} />
      {(data.benchmarks.ElectricityConsumption.benchmark_value || data.benchmarks.HeatConsumption.benchmark_value) && (
        <>
          <div className="divider" />
          <BenchmarkBarChart data={data} />
        </>
      )}
    </div>
  ) : (
    <div>No data available for Scope 2</div>
  )
  return (
    <CollapsibleBox
      header={
        <>
          {isMobile ? (
            <div className="resultSubTitle" id="pdfScope2Title">
              <div>Indirect Emissions (Scope 2)</div>
              <div className="scopeTotalEmissions">
                Total Emissions: <span className="textStrong">{formatResult(data.kg_CO2e, 0)} kg</span>
              </div>
            </div>
          ) : (
            <div className="resultSubTitle" id="pdfScope2Title">
              Indirect Emissions (Scope 2){' '}
              <span className="ml4 textStrong" data-cy="scope-2-indirect-emissions-value">
                {formatResult(data.kg_CO2e, 0)}
              </span>{' '}
              kg
              {isFreeUser && (
                <div className="textSmallSection">
                  This section shows a more detailed breakdown of your Scope 2 emissions
                </div>
              )}
            </div>
          )}
        </>
      }
      body={body}
    ></CollapsibleBox>
  )
}
