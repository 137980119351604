import axios from 'axios'

export function connectPlaidAccount(sessionId: string, publicToken: string): Promise<any> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/plaid/connect/item',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        sessionId,
        publicToken,
      },
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        console.error(err)
      })
  })
}
