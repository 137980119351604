export const businessTransports: any = {
  PASCA: 'Passenger Car',
  LDTR: 'Light-Duty Truck',
  MOTO: 'Motorcycle',
  INRAINA: 'Intercity Rail',
  COMRAI: 'Commuter Rail',
  TRANRAI: 'Transit Rail (i.e. Subway, Tram)',
  BUS: 'Bus',
  AIRSHO: 'Air Travel - Short Haul (< 300 miles)',
  AIRMED: 'Air Travel - Medium Haul (>= 300 miles, < 2300 miles)',
  AIRLON: 'Air Travel - Long Haul (>= 2300 miles)',
}

export const businessTransportsGrouped: any = {
  PASCALDTR: 'Passenger Cars and Light-Duty Trucks',
  MOTO: 'Motorcycles',
  INRAINACOMRAI: 'Intercity Commuter Rail',
  TRANRAI: 'Transit Rail',
  BUS: 'Buses',
  AIRSHOMEDLON: 'Airplanes (Short and Long Haul)',
}

export const businessTravelInputsUS = { businessTransports, businessTransportsGrouped }
