import { Bar } from 'react-chartjs-2'
import { useRegion } from '../../../../context/RegionProvider'
import { stationaryCombustionMapping } from '../../../../data/inputs/stationary-combustion/stationary-combustion.mapping'
import { Scope1Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function BenchmarkStationaryChart({ data }: { data: Scope1Results }) {
  const { country } = useRegion()
  const { stationaryFuelUnits } = stationaryCombustionMapping[country.code]
  const labels = ['Fuel consumption']
  const datasets = [
    { label: 'Your company', data: [data?.benchmarks?.FuelConsumptionStationary.converted_value], color: '#20D8AB' },
    {
      label: 'Industry Average',
      data: [data?.benchmarks?.FuelConsumptionStationary.benchmark_value],
      color: '#2095D8',
    },
  ]

  return (
    <div className="benchmarkSection" id="pdfScope1BenchmarkStationaryChart">
      <div className="chartInfo">
        <div className="textStrong">Fuel Consumption Benchmarking</div>
        <div>
          This graph shows a comparison between your stationary fuel consumption and the average for companies with
          similar sized premises.
        </div>
      </div>
      <div className="chartContainer">
        <Bar
          id="BenchmarkStationaryChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return (
                      formatResult(context.raw, 2) +
                      ' ' +
                      stationaryFuelUnits[data.benchmarks.FuelConsumptionStationary.final_unit]
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  display: false,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  count: 6,
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: { label: string; data: number[]; color?: string }, i: number) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                borderRadius: 10,
                barPercentage: 1,
                categoryPercentage: 0.99,
              }
            }),
          }}
        />{' '}
      </div>
    </div>
  )
}
