import React, { createContext, useContext, useEffect, useState } from 'react'
import { getClient } from '../api/getClient'
import { Client } from '../interface/reportClient.interface'
import isValidUUID from '../utils/isValidUUID'

const ClientContext = createContext<Client>({
  clientId: '',
  isOpenBankingEnabled: false,
  isFullScreenEnabled: false,
  isHotjarEnabled: false,
  isFreeUser: false
})

export function useClient() {
  return useContext(ClientContext)
}

export function ClientProvider({ children }: { children: React.ReactNode }) {
  const [client, setClient] = useState<Client>({
    clientId: '',
    isOpenBankingEnabled: false,
    isFullScreenEnabled: false,
    isHotjarEnabled: false,
    isFreeUser: false
  })

  useEffect(() => {
    const queryParameters: URLSearchParams = new URLSearchParams(window.location.search)
    const sessionId = queryParameters.get('sessionId')
    if (sessionId && isValidUUID(sessionId)) {
      getClient(sessionId)
        .then((client) => {
          if (client) {
            setClient(client)
          }
        })
        .catch((err) => console.error(err))
    }
  }, [])

  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
}
