export const objectToOptions = (obj: any) => {
  const options = []
  for (let key in obj) {
    options.push(
      <option value={key} key={key}>
        {obj[key].name || obj[key]}
      </option>,
    )
  }
  return options
}
