import { faXmark, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function ConfirmDatesPopup({
  isFullScreenEnabled,
  closePopup,
  initialDates,
  values,
  confirmReset,
}: {
  isFullScreenEnabled: boolean | undefined
  closePopup: any
  initialDates: any
  values: any
  confirmReset: any
}) {
  return (
    <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
      <div className={isFullScreenEnabled ? 'modalContent fullScreen confirmation' : 'modalContent confirmation'}>
        <div className="displayEnd">
          <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => closePopup()} />
        </div>
        <div className="displayCenter">
          <FontAwesomeIcon icon={faWarning} className="warningColor" size="3x" />
        </div>
        <div className="subtitle">Are you sure you want to change the report dates? </div>
        <div className="grayBox">
          <div className="listItem">
            <span className="bold">Current period: </span>
            {initialDates.startDate} to {initialDates.endDate}
          </div>
          <div className="listItem">
            <span className="bold">New period: </span>
            {values.reportingStartDate} to {values.reportingEndDate}
          </div>
        </div>
        <p>
          By modifying the report dates, you may be affecting the accuracy of the data.
          <span className="bold"> All sections will need to be entered and reviewed to ensure data integrity</span>.
        </p>
        <div className="buttonContainer">
          <div className="destructiveButton" onClick={() => confirmReset()}>
            Confirm
          </div>
          <div className="secondaryButton" onClick={closePopup}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  )
}
