export type Country = {
  name: string
  code: string
  defaultUnits: {
    currency: string
    capacity: string
    area: string
    distance: string
    mass: string
  }
  localisationFormat: string
}

export const countriesList: { [key: string]: Country } = {
  US: {
    name: 'United States',
    code: 'US',
    defaultUnits: {
      currency: 'USD',
      capacity: 'GAL',
      area: 'FT2',
      distance: 'MILE',
      mass: 'LB',
    },
    localisationFormat: 'en-US',
  },
  UK: {
    name: 'United Kingdom',
    code: 'UK',
    defaultUnits: {
      currency: 'GBP',
      capacity: 'LIT',
      area: 'M2',
      distance: 'KM',
      mass: 'KG',
    },
    localisationFormat: 'en-UK',
  },
}
