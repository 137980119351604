import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRegion } from '../../../../context/RegionProvider'
import { heatConsumptionMapping } from '../../../../data/inputs/heat-consumption/heat-consumption.mapping'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function LocationItem({
  index,
  watch,
  remove,
  register,
  getValues,
}: {
  index: number
  watch: any
  remove: any
  register: any
  getValues: any
}) {
  const { country } = useRegion()

  const { heatConsumptionUnits, supplierEmissionsFactorUnits } = heatConsumptionMapping[country.code]
  const heatConsumptionUnitOptions: Array<JSX.Element> = objectToOptions(heatConsumptionUnits)
  const supplierEmissionsFactorUnitOptions: Array<JSX.Element> = objectToOptions(supplierEmissionsFactorUnits)
  watch(`locations.${index}.isSefKnown`)

  return (
    <div className="itemCard">
      <div className="itemTitle">
        <div>Location {index + 1}</div>
        <FontAwesomeIcon
          className="clickable gray6Color"
          data-cy={`delete-location-${index}-icon`}
          icon={faTrash}
          onClick={() => remove(index)}
        ></FontAwesomeIcon>
      </div>
      <div key={'location' + index}>
        <div className="checkboxFlex">
          <label className="checkboxContainer">
            <input type="checkbox" {...register(`locations.${index}.isSefKnown`)}></input>
            <span className="checkmark"></span>
          </label>
          <div>I know my supplier's emissions factor</div>
        </div>
        {getValues(`locations.${index}.isSefKnown`) && (
          <div className="locationInput">
            <label className="label">Supplier Emission Factor</label>
            <div className="input inlineInputs">
              <input
                className="width100"
                type="number"
                step="any"
                inputMode="decimal"
                {...register(`locations.${index}.supplierEmissionsFactor`, {
                  valueAsNumber: true,
                })}
              ></input>
              <select className="width100" {...register(`locations.${index}.supplierUnitCode`)}>
                {supplierEmissionsFactorUnitOptions}
              </select>
            </div>
          </div>
        )}
        <div className="locationInput">
          <label className="label">Heat Consumption</label>
          <div className="input inlineInputs">
            <input
              className="width100"
              type="number"
              step="any"
              inputMode="decimal"
              {...register(`locations.${index}.value`, {
                valueAsNumber: true,
              })}
            ></input>
            <select className="width100" {...register(`locations.${index}.unitCode`)}>
              {heatConsumptionUnitOptions}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
