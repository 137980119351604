import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function ElectricityConsumptionInfoHeader({
  openPopup,
  countryCode,
}: {
  openPopup: any
  countryCode: string
}) {
  return (
    <CollapsibleBox
      isCollapsed={true}
      header={<div className="textLarge">This section covers purchased electricity for your company's consumption</div>}
      body={
        <>
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>1. Do not include any information about transmission and distribution losses</p>
            <p>
              2. Look for a supplier-specific emissions factor for increased accuracy. This information can be found on
              your electricity bill or Power Purchase Agreement, your electricity provider's website, or by contacting
              your electricity provider. If you do not know this factor, but know you are on a 100% renewable
              electricity tariff, put 0. Otherwise, if your supplier's emissions factor is not available, please leave
              the field empty and the average emissions factor for your region will be used instead
            </p>
            {countryCode === 'US' && (
              <p>
                3. For more information about the different electricity grids, please{' '}
                <span className="link" onClick={openPopup}>
                  check this map
                </span>
              </p>
            )}
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
