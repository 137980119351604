import BoxHeader from '../boxes/BoxHeader'

export default function GhgpInfo({ closePopup }: { closePopup: Function }) {
  return (
    <BoxHeader closePopup={closePopup} subtitle={'What is the Greenhouse Gas Protocol?'}>
      <div>
        The calculation follows the Greenhouse Gas protocol (GHG Protocol) official corporate emissions reporting
        standard. This categorises emissions sources into 3 scopes, so that businesses across different industries can
        report using a single, unified framework. These 3 scopes are:
        <div className="grayBox">
          <ul className='ghgpList'>
            <li className='ghgpListItem'>
              <span className="primaryColor">Scope 1 (Direct Emissions)</span>: These are emissions directly produced by
              your company.
            </li>
            <li className='ghgpListItem'>
              <span className="primaryColor">Scope 2 (Indirect Emissions)</span>: These are emissions associated with
              the generation of any energy your company uses (mainly electricity and heat). There are two ways of
              measuring Scope 2 emissions: Location Based, which takes an average emissions factor for your region, and
              Market Based, which uses supplier-specific emissions factors.
            </li>
            <li className='ghgpListItem'>
              <span className="primaryColor">Scope 3 (Indirect Other Emissions)</span>: These are emissions generated in
              your company's value chain. This calculator focuses on three subcategories of Scope 3, namely 3.1)
              Purchased Goods and Services, 3.6) Employee Commuting and 3.7) Business Travel. These three subcategories
              are all upstream emissions sources.
            </li>
          </ul>
        </div>
      </div>
      <div>
        Emissions are quantified in kilograms of carbon dioxide equivalent (CO2e). This is a way of combining all the
        different greenhouse gases into a single, easy to track metric. The quantities of other gases are converted to
        CO2e based on their Global Warming Potential values.
      </div>
      <div>
        Find more information about the{' '}
        <a href="https://ghgprotocol.org/corporate-standard" className="link" rel="noreferrer" target="_blank">
          GHG Protocol
        </a>{' '}
        and{' '}
        <a
          href="https://www.ghgprotocol.org/sites/default/files/ghgp/Global-Warming-Potential-Values%20%28Feb%2016%202016%29_1.pdf"
          className="link"
          rel="noreferrer"
          target="_blank"
        >
          Global Warming Potential values
        </a>
        .
      </div>
    </BoxHeader>
  )
}
