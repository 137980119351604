import axios from 'axios'

export async function uploadReportTransactions(sessionId: string, transactions: any[]) {
  try {
    await axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/transactions/upload',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        sessionId,
        geo: 'US',
        transactions,
      },
    })
  } catch (err) {
    console.error(`Unable to upload transactions. Reason: ${err}`)
  }
}
