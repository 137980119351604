import { useRegion } from '../../context/RegionProvider'
import { businessTravelMapping } from '../../data/inputs/business-travel/business-travel.mapping'
import { hotelRegions } from '../../data/inputs/hotelStaysUS'
import { mobileCombustionMapping } from '../../data/inputs/mobile-combustion/mobile-combustion.mapping'
import { BusinessTravel, HotelStay } from '../../interface/inputData.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function BusinessTravelReview({
  transports,
  hotelStays,
}: {
  transports: BusinessTravel[]
  hotelStays: HotelStay[]
}) {
  const { country } = useRegion()
  const { distanceUnits } = mobileCombustionMapping[country.code]
  const { businessTransports } = businessTravelMapping[country.code]

  return (
    <CollapsibleBox
      header={<div className="subtitle">Business Travel</div>}
      body={
        <>
          <div className="listItem">Travel Transports</div>
          {!transports.length ? (
            <div className="grayBox notSpecified">No travel transports specified</div>
          ) : (
            <div className="reviewTable mt2">
              {transports.map((transport, index) => (
                <TransportSection transport={transport} index={index} key={'transport' + index} />
              ))}
            </div>
          )}
          <div className="listItem mt4">Hotel Stays</div>
          {!hotelStays.length ? (
            <div className="grayBox notSpecified">No hotel stays specified</div>
          ) : (
            <div className="reviewTable mt2">
              {hotelStays.map((hotelStay, index) => (
                <HotelStaySection hotelStay={hotelStay} index={index} key={'hotel' + index} />
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function TransportSection({ transport, index }: { transport: BusinessTravel; index: number }) {
    const { transportModeCode, value, unitCode } = transport
    return (
      <>
        <div className="tableHeader" data-cy={`review-mode-of-transport-${index}-business-travel-header`}>
          Mode of Transport {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Transport Type</div>
        <div className="tableItem">{businessTransports[transportModeCode]}</div>

        <div className="tableItem">Travelled Distance</div>
        <div className="tableItem">{value + ' ' + distanceUnits[unitCode]}</div>
      </>
    )
  }

  function HotelStaySection({ hotelStay, index }: { hotelStay: HotelStay; index: number }) {
    const { hotelRegionCode, value } = hotelStay
    return (
      <>
        <div className="tableHeader" data-cy={`review-hotel-${index}-header`}>
          Hotel Stay Region {index + 1}
        </div>
        <div className="tableHeader"></div>

        <div className="tableItem">Region</div>
        <div className="tableItem">{hotelRegions[hotelRegionCode]}</div>

        <div className="tableItem">Number of Room-Nights</div>
        <div className="tableItem">{value}</div>
      </>
    )
  }
}
