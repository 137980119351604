import axios from 'axios'

export function saveData(sessionId: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/save',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        sessionId,
        data,
      },
    })
      .then((res: any) => {
        resolve(res)
      })
      .catch((err: any) => {
        console.error(err)
      })
  })
}

export function getData(sessionId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (sessionId) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_CONNECT_API_URL + '/report/get',
        headers: {
          'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
        },
        data: {
          sessionId,
        },
      })
        .then((res: any) => {
          resolve(res.data)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  })
}

export function calculateResults(sessionId: string, data: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/report/calculate-results',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
      },
      data: {
        sessionId,
        data,
      },
    })
      .then((res: any) => {
        resolve(res.data)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}

export async function resetForm(sessionId: string) {
  await saveData(sessionId, {
    HeatConsumption: [],
    FuelConsumption_Status: 'Incompleted',
    EmployeeCommuting_Status: 'Incompleted',
    ElectricityConsumption: [],
    HeatConsumption_Status: 'Incompleted',
    vehicles: [],
    PurchasedGoods_Status: 'Incompleted',
    Review_Status: 'Incompleted',
    DistanceTravelledMobile: [],
    BusinessTravel: [],
    ElectricityConsumption_Status: 'Incompleted',
    HotelStays: [],
    PurchasedGoods: [],
    BusinessTravel_Status: 'Incompleted',
    EmployeeCommuting: [],
    FuelConsumptionStationary: [],
    machines: [],
  })
}
