import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function EmployeeCommutingInfoHeader({ openPopup }: { openPopup: any }) {
  return (
    <CollapsibleBox
      isCollapsed={true}
      header={
        <div className="textLarge">This section covers employees' regular commuting to and from their workplace</div>
      }
      body={
        <>
          {' '}
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>
              1. This does not include travel using company-owned vehicles, which should be reported instead under{' '}
              <i>Fuel Consumption</i>, or any travel for business trips, which should be reported instead under{' '}
              <i>Business Travel</i>
            </p>
            <p>2. There are 252 working days in the average year. Or approximately 230, including paid annual leave </p>
            <p>
              3. You may wish to report a mode of transport per employee, or sum multiple employees’ travel under one
              mode of transport
            </p>
            <p>
              4. To calculate total distance per vehicle, sum, for each employee, their daily return commute journey
              multiplied by the number of days commuted annually by the employee
            </p>
            <p>
              5. See more information about the different modes of transport{' '}
              <span className="link" onClick={openPopup}>
                here
              </span>{' '}
            </p>
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
