export const machineFuelTypes: any = {
  COAL: 'Coal and Coke',
  //BIOMS: 'Biomass Fuels - Solid',
  OTHFUE: 'Other Fuels - Solid',
  NAGA: 'Natural Gas',
  //BIOMG: 'Biomass Fuels - Gaseous',
  OTHE: 'Other Fuels - Gaseous',
  PROP: 'Propane',
  DIES: 'Diesel / Distillate Fuel Oil',
  ETHA: 'Ethane',
  LPG: 'LPG',
  MOTO: 'Motor Gasoline',
  //BIOML: 'Biomass Fuels - Liquid',
  OTHPET: 'Other Petroleum Products',
}

export const unitsPerFuel: any = {
  COAL: ['KWH', 'MJ', 'MMBTU', 'LBS', 'KG', 'STON'],
  //BIOMS: ['KWH', 'MJ', 'MMBTU', 'LBS', 'KG', 'STON'],
  OTHFUE: ['KWH', 'MJ', 'MMBTU', 'LBS', 'KG', 'STON'],
  NAGA: ['KWH', 'MJ', 'MMBTU', 'SCF', 'M3'],
  //BIOMG: ['KWH', 'MJ', 'MMBTU', 'SCF', 'M3'],
  OTHE: ['KWH', 'MJ', 'MMBTU', 'SCF', 'M3'],
  //BIOML: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  ETHA: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  PROP: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  LPG: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  MOTO: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  DIES: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
  OTHPET: ['KWH', 'MJ', 'MMBTU', 'GAL', 'LIT'],
}

export const stationaryFuelUnits: any = {
  LBS: 'pounds (lbs)',
  KG: 'kilograms (kg)',
  STON: 'short ton (tn)',
  SCF: 'standard cubic feet (scf)',
  M3: 'cubic metre (m3)',
  GAL: 'US gallon (gal)',
  LIT: 'litres (L)',
  KWH: 'kilowatt-hour (kWh)',
  MJ: 'megajoules (MJ)',
  MMBTU: 'million British thermal units (mmBtu)',
}

export const stationaryCombustionInputsUS = { machineFuelTypes, unitsPerFuel, stationaryFuelUnits }
