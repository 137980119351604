import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Legend, Tooltip, defaults } from 'chart.js'
import formatResult from '../../utils/formatResult'
defaults.font.family = 'Gilroy'
Chart.register(ArcElement, Legend, Tooltip)

export default function DoughnutChart({ data, colors, id }: any) {
  let defaultColors = ['#20D8AB', '#2095D8', '#7D21DA', '#2054D8', '#B320D8']
  return (
    <Doughnut
      id={id}
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                return context[0].label + ' emissions: '
              },
              label: (context) => {
                const totalEmissions = context.dataset.data.reduce((a, b) => a + b)
                return `${formatResult(context.parsed, 0)} kg CO2e (${formatResult(
                  (context.parsed / totalEmissions) * 100,
                  2,
                )} %)`
              },
            },
          },
          legend: {
            display: true,
            fullSize: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded',
              filter: (label: any, chart: any) => {
                return chart.datasets?.[0]?.data[label.index] > 0
              },
            },
          },
        },
      }}
      data={{
        labels: data.map((row: { name: string; value: number }) => {
          return row.name
        }),
        datasets: [
          {
            data: data.map((row: { name: string; value: number }) => {
              return row.value
            }),
            backgroundColor: colors ? colors : defaultColors,
            hoverOffset: 4,
          },
        ],
      }}
    />
  )
}
