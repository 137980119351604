import BoxHeader from '../boxes/BoxHeader'

export default function GridMap({ closePopup }: { closePopup: Function }) {
  return (

      <BoxHeader closePopup={closePopup} subtitle={''}>
        <img className="mapImage" src="/GridMap.png" alt="Grid Map" />
      </BoxHeader>

  )
}
