import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function HeatConsumptionInfoHeader() {
  return (
    <CollapsibleBox
      isCollapsed={true}
      header={
        <div className="textLarge">
          This section covers purchased heat, typically in the form of hot water or steam supplied by your utility
          provider
        </div>
      }
      body={
        <>
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>
              1. Only applicable where you do not control the heat generator. For example, if your workspace is heated
              using your own boiler, please record this under <i>Fuel Consumption</i>
            </p>
            <p>2. Do not include any information about transmission and distribution losses here</p>
            <p>
              3. Look for a supplier-specific emissions factor for increased accuracy. This information can be found on
              your heating bill, your heating provider’s website, or by contacting your heating provider. If not
              available, please leave the field empty and the average emissions factor for your region will be used
              instead
            </p>
            <p>
              4. Heating appliances that run on electricity should be accounted for in <i>Electricity Consumption</i>
            </p>
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
