import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function EventEmitter() {
  const location = useLocation()

  //Emits the route to the parent when it changes
  useEffect(() => {
    window.parent.postMessage({ type: 'ROUTE_CHANGE_EVENT', payload: { route: location.pathname } }, '*')
  }, [location])

  //Emits the size of the screen every time it resizes
  useEffect(() => {
    const handleResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      window.parent.postMessage({ type: 'RESIZE_EVENT', payload: entries[0].contentRect }, '*')
    }
    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return null
}
