import { purchasedGoodCategories } from '../inputs/purchasedGoods'
import { PurchasedGoodsFields, PurchasedGood } from './../../interface/formFields.interface'
import validateNumericInput from './number.validation'
export default function getErrors(values: PurchasedGoodsFields) {
  const errors: string[] = []
  const { purchasedGoods } = values
  purchasedGoods.forEach((purchasedGood: PurchasedGood) => {
    const validation = validateNumericInput(purchasedGood.value)
    if (validation !== true) {
      errors.push(`${purchasedGoodCategories[purchasedGood.spendCategoryCode]}: ${validation}`)
    }
  })
  return errors
}
