import { getPlaidLinkToken } from '../../api/open-banking/getPlaidLinkToken'
import { useSession } from '../../context/SessionProvider'
import { usePlaidLink } from 'react-plaid-link'
import { useEffect, useState } from 'react'
import { connectPlaidAccount } from '../../api/open-banking/connectPlaidItem'
import { Account, InstitutionsAccounts, Item } from '../../api/open-banking/interface/institutionsAccounts.interface'
import { getPlaidUserAccounts } from '../../api/open-banking/getPlaidUserAccounts'
import { faBuildingColumns, faPlusCircle, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deletePlaidItem } from '../../api/open-banking/deletePlaidItem'
import { useClient } from '../../context/ClientProvider'
import LockedTextBox from '../boxes/LockedTextBox'

export default function OpenBankingSection() {
  const { sessionId } = useSession()
  const { isFullScreenEnabled, isFreeUser } = useClient()
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [connectedItems, setConnectedItems] = useState<InstitutionsAccounts>([])
  const [accountDataLoading, setAccountDataLoading] = useState<Boolean>(true)

  const exampleitem = {
    institution: {
      name: 'Example Bank',
    },
    item_id: 'test',
    request_id: 'test',
  }

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      setAccountDataLoading(true)
      //TODO check metadata to avoid duplicates
      connectPlaidAccount(sessionId, public_token)
        .then(() => {
          getConnectedItems()
        })
        .catch((error) => {
          console.error(`Error connecting plaid item. Reason: ${error}`)
        })
    },
  })

  const [showPopup, setShowPopup] = useState(false)

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const getConnectedItems = async () => {
    try {
      const items = await getPlaidUserAccounts(sessionId)
      items.push(exampleitem)
      setConnectedItems(items)
      setAccountDataLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteItem = async (itemId: string) => {
    try {
      await deletePlaidItem(sessionId, itemId)
    } catch (error) {
      console.error(error)
    }
    closePopup()
    getConnectedItems()
  }

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        const response = await getPlaidLinkToken(sessionId)
        setLinkToken(response.link_token)
      } catch (error) {
        console.log(error)
      }
    }
    fetchLinkToken()
    getConnectedItems()
  }, [sessionId])

  return (
    <div className="sectionBox">
      <div className="textLarge mb-2">Connected Bank Accounts</div>
      {isFreeUser ? (
        <>
          <div className="textSmallSection">
            This section contains all your connected bank accounts through open banking
          </div>
          <LockedTextBox dataCy="open-banking-section-locked" />
        </>
      ) : (
        <div>
          {accountDataLoading ? (
            <div className="mt2">Loading...</div>
          ) : !accountDataLoading && !connectedItems.length ? (
            <div className="mt2">No connected accounts yet.</div>
          ) : (
            <div className="institutionsGrid mt4">
              {connectedItems.map((item: Item) => (
                <PlaidItem item={item} key={item.institution.institution_id} />
              ))}
            </div>
          )}
          <div
            className={isPlaidLinkReady ? 'addButton mt4' : 'addButton disabled mt4'}
            onClick={() => openPlaidLink()}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add new institution
          </div>
        </div>
      )}
    </div>
  )

  function PlaidItem({ item }: { item: Item }) {
    return (
      <div className="institutionBox">
        <div className="institutionHeader">
          <div className="bold institutionTitle">
            {item.institution.logo ? (
              <img className="bankLogo" src={'data:image/png;base64,' + item.institution.logo} alt="" />
            ) : (
              <div className="bankLogo gray6Color">
                <FontAwesomeIcon icon={faBuildingColumns} size="2x"></FontAwesomeIcon>
              </div>
            )}
            {item.institution.name}
          </div>
          <FontAwesomeIcon className="clickable gray6Color" icon={faTrash} onClick={openPopup}></FontAwesomeIcon>
        </div>
        <div>
          {item.accounts?.map((account: Account) => {
            return (
              <div className="mt2 listItem" key={account.account_id}>
                {account.name}
              </div>
            )
          })}
        </div>
        {showPopup && <AreYouSureConfirmation item={item} />}
      </div>
    )
  }

  function AreYouSureConfirmation({ item }: { item: Item }) {
    return (
      <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
        <div className={isFullScreenEnabled ? 'modalContent fullScreen confirmation' : 'modalContent confirmation'}>
          <div className="boxHeader subtitle">
            Are you sure? <FontAwesomeIcon icon={faXmark} className="clickable" onClick={() => closePopup()} />
          </div>
          <div>
            By doing this all the information related with{' '}
            {item.accounts!.length > 1 ? 'these accounts' : 'this account'} will be removed, including the transactions,
            and therefore the calculations based on them will be impacted.
          </div>
          <div className="buttonContainer">
            <button type="button" onClick={closePopup}>
              Cancel
            </button>
            <button type="button" onClick={() => deleteItem(item.item_id)}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    )
  }
}
