import { Country } from '../../data/countriesList'
import BoxHeader from '../boxes/BoxHeader'

export default function VehiclesInfo({ closePopup, country }: { closePopup: Function; country: Country }) {
  return (
    <BoxHeader closePopup={closePopup} subtitle={'Vehicles Classification'}>
      {country.code === 'UK' ? (
        <>
          <p>
            To find the weight and engine sizes of your vehicle, check the owner’s manual, manufacturer's website, or a
            sticker on driver's side door. If not found, look online, contact manufacturer or ask your dealership for
            help.{' '}
          </p>
          <p>
            Make sure to double-check your vehicle specification because some vehicles can be classed in more than 1
            category depending on the model such as the <i>Mercedes Benz Sprinter</i>. If you are unsure on your
            vehicles engine size, class it as an <i>Average Car</i>.
          </p>
        </>
      ) : (
        <p>
          To find weight and wheelbase of your vehicle, check the owner's manual, manufacturer's website, or a sticker
          on driver's side door. If not found, look online, contact manufacturer or ask your dealership for help.
        </p>
      )}
      <img
        src={country.code === 'UK' ? '/img/vehicle-classification-uk.png' : '/img/vehicle-classification-us.png'}
        alt="Vehicles Classification"
        style={{ maxWidth: '100%', margin: 'auto', display: 'block' }}
      />
      <div className="buttonContainer">
        <button type="button" onClick={() => closePopup()}>
          Close
        </button>
      </div>
    </BoxHeader>
  )
}
