import { faCheck, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getData } from '../../api/reportStore'
import { useSession } from '../../context/SessionProvider'
import { RouteItem, routes } from '../../routes/routes'
import { useClient } from '../../context/ClientProvider'

export default function NavBarContent({ context, setNavShow }: any) {
  const { sessionId } = useSession()
  const { isInsightsEnabled } = useClient()
  const location = useLocation()
  const navigate = useNavigate()
  const [sectionCompleted, setSectionCompleted] = useState<{ [key: string]: boolean }>({ Introduction: true })
  const slicedRoutes = routes.slice(0, -1)

  useEffect(() => {
    getData(sessionId).then((data: any) => {
      const sectionCompleted: any = { Introduction: true }
      Object.keys(data).forEach((section: string) => {
        if (section.includes('Status') && data[section] === 'Completed') {
          sectionCompleted[section.split('_')[0]] = true
        }
      })
      if (Object.values(sectionCompleted).every((item) => item === true)) {
        sectionCompleted.Results = true
      }
      setSectionCompleted(sectionCompleted)
    })
  }, [sessionId, location])

  async function onItemClicked(item: RouteItem) {
    if (setNavShow) {
      setNavShow(false)
    }
    const sectionName = context?.current?.sectionName
    // When the section don't have an imperativeHandler (because there's no need to save data e.g Intro, Review) we just go the target section
    if (!context?.current?.sectionName) {
      navigate(`${item.path}?sessionId=${sessionId}`)
      // When we are going to a previous section and the current section is not completed we save but don't validate the errors or complete the current section
    } else if (isPreviousRoute(sectionName, item) && !sectionCompleted[sectionName]) {
      await context?.current?.save({ sectionCompleted: false })
      navigate(`${item.path}?sessionId=${sessionId}`)
      // Else we treat it as a submit (validate, save, and complete if no errors
    } else {
      await context?.current?.submit(`${item.path}?sessionId=${sessionId}`)
    }
  }

  function isPreviousRoute(sectionName: string, route: RouteItem) {
    const currentIndex = routes.findIndex((item) => item.name === sectionName)
    const routeIndex = routes.findIndex((item) => item.name === route.name)
    if (currentIndex > routeIndex) {
      return true
    }
    return false
  }

  return (
    <>
      {isInsightsEnabled
        ? routes.map((item: RouteItem, i: number) => {
            const onResultsPage = i === routes.length - 2 && location.pathname === item.path
            const onInsightsPage = i === routes.length - 1 && location.pathname === item.path
            return (
              <div
                key={'item' + i}
                className={i !== 0 && !sectionCompleted[routes[i - 1].name] ? 'cursorNotAllowed' : ''}
              >
                <div
                  data-cy={'nav-item-' + i}
                  className={`navItem ${location.pathname === item.path ? ' selected' : ''} ${
                    (sectionCompleted[item.name] && item.name !== 'Results') || onResultsPage ? ' highlight' : ''
                  } ${i !== 0 && !sectionCompleted[routes[i - 1].name] ? ' disabled' : ' clickable'}`}
                  onClick={async () => await onItemClicked(item)}
                >
                  <div
                    className={`navItemCheck ${
                      (sectionCompleted[item.name] && item.name !== 'Results') || onResultsPage ? 'highlight ' : ''
                    }`}
                  >
                    {onResultsPage ? (
                      <FontAwesomeIcon icon={faStar} />
                    ) : item.displayName === 'Insights & Next Steps' || onInsightsPage ? (
                      <FontAwesomeIcon icon={faLightbulb} />
                    ) : (
                      sectionCompleted[item.name] && <FontAwesomeIcon icon={faCheck} />
                    )}{' '}
                  </div>
                  <div>{item.displayName}</div>
                </div>
                {i !== routes.length - 1 && (
                  <div className={`navItemSeparator ${sectionCompleted[item.name] ? 'highlight ' : ''}`}>
                    {item.delimiter && <div className="navDelimiter" />}
                  </div>
                )}
              </div>
            )
          })
        : slicedRoutes.map((item: RouteItem, i: number) => {
            const onResultsPage = i === slicedRoutes.length - 1 && location.pathname === item.path
            return (
              <div
                key={'item' + i}
                className={i !== 0 && !sectionCompleted[slicedRoutes[i].name] ? 'cursorNotAllowed' : ''}
              >
                <div
                  data-cy={'nav-item-' + i}
                  className={`navItem ${location.pathname === item.path ? ' selected' : ''} ${
                    ((sectionCompleted[item.name] && item.name !== 'Results') || onResultsPage) && !item.isDisabled
                      ? ' highlight'
                      : ''
                  } ${
                    (i !== 0 && !sectionCompleted[slicedRoutes[i].name]) || item.isDisabled ? ' disabled' : ' clickable'
                  }`}
                  onClick={async () => await onItemClicked(item)}
                >
                  <div
                    className={`navItemCheck ${
                      ((sectionCompleted[item.name] && item.name !== 'Results') || onResultsPage) && !item.isDisabled
                        ? 'highlight '
                        : ''
                    }`}
                  >
                    {onResultsPage ? (
                      <FontAwesomeIcon icon={faStar} />
                    ) : (
                      sectionCompleted[item.name] && item.name !== 'Results' && <FontAwesomeIcon icon={faCheck} />
                    )}{' '}
                  </div>
                  <div>{item.displayName}</div>
                </div>
                {i !== slicedRoutes.length - 1 && (
                  <div className={`navItemSeparator ${sectionCompleted[item.name] ? 'highlight ' : ''}`}>
                    {item.delimiter && <div className="navDelimiter" />}
                  </div>
                )}
              </div>
            )
          })}
    </>
  )
}
