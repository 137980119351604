export const purchasedGoodCategories: any = {
  OTHS: 'General Services',
  OTHP: 'General Products',
  PROSCIS: 'Professional, Scientific and Technical Services',
  ITCOMS: 'Information and Communication Services',
  CONS: 'Construction and Maintenance Services',
  ERENS: 'Equipment Rental Services',
  MEDIA: 'Printed and Recorded Media',
  PLARUB: 'Plastic and Rubber Products',
  WOOD: 'Wood Products Incl Paper',
  METAL: 'Metal Products',
  AGRI: 'Agricultural Products Excl Livestock',
  CHEM: 'Chemical Products',
  MNEC: 'Materials Not Elsewhere Classified',
  TXTL: 'Clothing, Textile and Leather Products',
  MGNEC: 'Furniture, Electronics and Other Manufactured Goods',
  FOOD: 'Food and Beverages (Excl. Restaurants)',
}

export const purchasedGoodTooltips: any = (countryCode: string) => {
  return {
    PROSCIS: `Includes management, scientific, technical consulting services, legal services, accounting and tax services, ${
      countryCode === 'UK' ? 'specialised' : 'specialized'
    } design services, advertising and public relations, marketing research, public opinion polling firms, photographic studios, translators and interpreters.`,
    ITCOMS:
      'Includes postal and telecommunication services, computer systems design services, database-related services, hardware consultancy services, and data processing services.',
    CONS: 'Includes work done by construction contractors for general construction, heavy construction, building installation activities (e.g. installation of air-conditioning and heating equipment), and building completion activities (e.g. glazing, painting, plastering, and decorating).',
    ERENS:
      'Includes any operational leasing of machinery or equipment where you do not have operational control over the vehicle or machinery (this includes hiring contractors). Any vehicle leased for employee commuting or business travel should not be included. ',
    OTHS: `Covers all services not mentioned in the other categories. This includes public administration services, recreational, cultural, sporting services (e.g., team socials, restaurants, hotel dining, bars, cafes, entertainment activities), washing and dry cleaning, industrial cleaning, packaging services, call ${
      countryCode === 'UK' ? 'centre' : 'center'
    } services, etc.`,
    MEDIA:
      'Includes any form of printed matter (books, magazines, and other periodicals, posters, photos), published recorded media (CDs, DVDs, etc.), and the steps to produce media and printed matter (acquisition, editing, design and layout, production, distribution and marketing).',
    PLARUB: `Natural or synthetic rubber products including ${
      countryCode === 'UK' ? 'tyres' : 'tires'
    }, tubes, hoses, and flooring. Plastic products include plastic pipes, utensils, bags, containers, office supplies, and the plastic material itself.`,
    WOOD: 'Includes any wood products (including cork) excluding furniture, as well as any paper products excluding printed material.',
    METAL: `This includes precious metals, ${
      countryCode === 'UK' ? 'aluminium' : 'aluminum'
    }, copper, and other non-ferrous metals, as well as fabricated metal products (excluding machinery and equipment).`,
    AGRI: `Includes vegetables, grains, fruits, flowers, ${
      countryCode === 'UK' ? 'fibres' : 'fibers'
    } and other agricultural products derived from plants. Biofuels are not included in this category.`,
    CHEM: `Includes industrial gases, ${
      countryCode === 'UK' ? 'fertilisers' : 'fertilizers'
    }, chemicals, lubricants, dyes and pigments, as well as white spirits or mineral spirits.`,
    MNEC: 'Materials purchased not elsewhere classified. Includes stone, sand, clay, glass, glass products, ceramic goods, bricks, tiles, construction products, baked clay, cement, lime, plaster, secondary raw materials (recycled materials used instead of virgin materials, e.g., recycled paper, glass, metals, etc.), other non-metallic mineral products such as mineral wool and products containing peat.',
    TXTL: 'Includes apparels and textiles, leather and fur products.',
    MGNEC:
      'Includes office machinery, computers, communication equipment, motors, pumps, ovens, lifting equipment, cooling equipment, agricultural machinery, machine tools, industrial machinery for specific purposes, and domestic appliances (both electrical and non-electrical).',
    FOOD: 'This category includes purchased foods and beverages, but excludes purchases from restaurants, cafes, and bars, as these are classified as General Services’.',
    OTHP: 'Includes all products that do not fit into any of the other purchased goods categories.',
  }
}
