import { ElectricityConsumption, HeatConsumption } from './../../interface/inputData.interface'
import { ElectricityConsumptionLocation, HeatConsumptionLocation } from './../../interface/formFields.interface'
import { InputData } from './../../interface/inputData.interface'

export function buildInput(rawData: any): InputData {
  return {
    CompanyInformation: rawData.CompanyInformation || [],
    DistanceTravelledMobile: rawData.vehicles || [],
    FuelConsumptionStationary: rawData.machines || [],
    ElectricityConsumption: buildElectricityConsumption(rawData.ElectricityConsumption || []),
    HeatConsumption: buildHeatConsumption(rawData.HeatConsumption || []),
    EmployeeCommuting: rawData.EmployeeCommuting || [],
    BusinessTravel: rawData.BusinessTravel || [],
    HotelStays: rawData.HotelStays || [],
    PurchasedGoods: buildPuchasedGoodsSection(rawData),
  }
}

function buildElectricityConsumption(locations: ElectricityConsumptionLocation[]): ElectricityConsumption[] {
  const values: ElectricityConsumption[] = []
  locations.forEach(({ isSefKnown, ...location }: ElectricityConsumptionLocation) => {
    values.push({
      ...location,
      supplierEmissionsFactor:
        !isSefKnown || isNaN(location.supplierEmissionsFactor) ? 'Unknown' : location.supplierEmissionsFactor,
    })
  })
  return values
}

function buildHeatConsumption(locations: HeatConsumptionLocation[]): HeatConsumption[] {
  const values: HeatConsumption[] = []
  locations.forEach(({ isSefKnown, ...location }: HeatConsumptionLocation) => {
    values.push({
      ...location,
      supplierEmissionsFactor:
        !isSefKnown || isNaN(location.supplierEmissionsFactor) ? 'Unknown' : location.supplierEmissionsFactor,
      heatCode: 'HEAT',
    })
  })
  return values
}

function buildPuchasedGoodsSection(rawData: any) {
  let result: any[] = []
  const { Scope_3_1, PurchasedGoods } = rawData
  if (PurchasedGoods?.length) {
    rawData.PurchasedGoods.forEach((category: any) => {
      const { spendCategoryCode, currencyCode, value } = category
      result.push({
        spendCategoryCode,
        currencyCode,
        additionalSpend: value,
        mccSpend: Scope_3_1?.categories?.[spendCategoryCode]?.totalAmount || 0,
        mccEmissions: Scope_3_1?.categories?.[spendCategoryCode]?.totalKgCO2e || 0,
      })
    })
  }
  return result
}
