import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useImperativeHandle, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { getData, saveData } from '../../../api/reportStore'
import { useSession } from '../../../context/SessionProvider'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { BusinessTravelFields, HotelStay, Transport } from '../../../interface/formFields.interface'
import getErrors from '../../../data/validation/businessTravel.validation'
import ModeOfTransportsInfo from '../../popups/ModeOfTransportsInfo'
import SectionHeader from '../../shared/SectionHeader'
import { useRegion } from '../../../context/RegionProvider'
import BusinessTravelInfoHeader from './components/BusinessTravelInfoHeader'
import TransportItem from './components/TransportItem'
import HotelStayItem from './components/HotelStayItem'

const sectionName = 'BusinessTravel'

export default function BusinessTravel() {
  const { sessionId } = useSession()
  const navigate = useNavigate()
  const ref = useOutletContext<any>()
  const [errors, setErrors] = useState<string[]>([])
  const [showPopup, setShowPopup] = useState(false)
  const [sectionCompleted, setSectionCompleted] = useState<boolean>(false)
  const { country } = useRegion()

  const defaultTransport: Transport = {
    transportModeCode: 'NONE',
    value: 0,
    unitCode: country.defaultUnits.distance,
  }

  const defaultHotel: HotelStay = {
    hotelRegionCode: 'NONE',
    value: 0,
    unitCode: 'NIGHT',
  }

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const { control, register, handleSubmit, getValues } = useForm<BusinessTravelFields>({
    defaultValues: {
      transports: [],
      hotelStays: [],
    },
  })

  const {
    fields: transportFields,
    append: appendTransport,
    update: updateTransport,
    remove: removeTransport,
  } = useFieldArray({
    control,
    name: 'transports',
  })

  const {
    fields: hotelStayFields,
    append: appendHotelStay,
    update: updateHotelStay,
    remove: removeHotelStay,
  } = useFieldArray({
    control,
    name: 'hotelStays',
  })

  //Called from the parent when navigating to a different route
  useImperativeHandle(ref, () => ({
    async save(params: { sectionCompleted: boolean }) {
      await save(getValues(), { sectionCompleted: params?.sectionCompleted })
    },
    async submit(navigationOverride: string) {
      await onSubmit(getValues(), navigationOverride)
    },
    sectionName,
  }))

  useEffect(() => {
    getData(sessionId).then((data: any) => {
      if (data['BusinessTravel']) {
        for (let idx = 0; idx < data['BusinessTravel'].length; idx++) {
          updateTransport(idx, data['BusinessTravel'][idx])
        }
      }
      if (data['HotelStays']) {
        for (let idx = 0; idx < data['HotelStays'].length; idx++) {
          updateHotelStay(idx, data['HotelStays'][idx])
        }
      }
      if (data[sectionName + '_Status'] === 'Completed') {
        setSectionCompleted(true)
      }
    })
  }, [sessionId, updateTransport, updateHotelStay])

  async function save(values: BusinessTravelFields, params?: { sectionCompleted: boolean }) {
    const data: any = {
      BusinessTravel: values.transports,
      HotelStays: values.hotelStays,
    }
    if (params?.sectionCompleted) {
      data[sectionName + '_Status'] = 'Completed'
    }
    await saveData(sessionId, data)
  }

  async function onSubmit(values: BusinessTravelFields, navigationOverride?: string) {
    const errors = getErrors(getValues())
    setErrors(errors)
    if (!errors.length) {
      await save(values, { sectionCompleted: true })
      navigate(navigationOverride ?? '/employee-commuting?sessionId=' + sessionId)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  async function goBack() {
    const previousRoute = '/heat-consumption?sessionId=' + sessionId
    if (sectionCompleted) {
      await onSubmit(getValues(), previousRoute)
    } else {
      await save(getValues())
      navigate(previousRoute)
    }
  }

  return (
    <div className="formContainer">
      <SectionHeader title="Business Travel" errors={errors} />
      <BusinessTravelInfoHeader openPopup={openPopup} />
      {showPopup && <ModeOfTransportsInfo closePopup={closePopup} country={country} />}
      <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
        <div className="sectionBox">
          <div className="sectionTitle">Travel Transports</div>
          {!transportFields.length && (
            <div className="emptyPlaceholder">Add business travel transports or leave blank if it doesn't apply.</div>
          )}
          {transportFields.map((field, index) => (
            <TransportItem index={index} key={field.id} register={register} removeTransport={removeTransport} />
          ))}
          <div className="addButton" data-cy="add-transport-button" onClick={() => appendTransport(defaultTransport)}>
            <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add transport
          </div>
          <div className="sectionTitle">Hotel Stays</div>
          {!hotelStayFields.length && (
            <div className="emptyPlaceholder">
              Add hotel stays for different regions or leave blank if it doesn't apply.
            </div>
          )}
          {hotelStayFields.map((field, index) => (
            <HotelStayItem index={index} key={field.id} register={register} removeHotelStay={removeHotelStay} />
          ))}
          <div className="addButton" data-cy="add-hotel-button" onClick={() => appendHotelStay(defaultHotel)}>
            <FontAwesomeIcon icon={faPlusCircle} className="gray6Color" /> Add hotel stay
          </div>
        </div>
        <div className="buttonContainer">
          <button type="button" onClick={goBack}>
            Back
          </button>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  )
}
