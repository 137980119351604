import { CompanyInformationFields } from '../../interface/formFields.interface'
import validateNumericInput from './number.validation'

export default function getErrors(values: CompanyInformationFields) {
  const errors = []
  const {
    countryCode,
    reportingStartDate,
    reportingEndDate,
    numberOfEmployees,
    yearlyRevenueValue,
    facilityArea,
    businessSector,
  } = values
  if (!countryCode) {
    errors.push('Country: This field cannot be empty.')
  }
  if (!businessSector) {
    errors.push('Business Sector: This field cannot be empty.')
  }
  if (!!!reportingStartDate || !!!reportingEndDate) {
    errors.push('Start / End Date: These fields cannot be empty.')
  }
  if (new Date(reportingStartDate).getFullYear() < 2020) {
    errors.push(
      `Start / End Date: We don't have data before 2020. Please select a greater starting date for your report.`,
    )
  }
  if (new Date(reportingEndDate) > new Date()) {
    errors.push('Start / End Date: The report ending date cannot be greater than today.')
  }
  if (reportingStartDate > reportingEndDate) {
    errors.push('Start / End Date: Starting date cannot be greater than end date.')
  }
  const numberOfEmployeesValidation = validateNumericInput(numberOfEmployees, {
    wholeNumber: true,
    greaterThanZero: true,
  })
  if (numberOfEmployeesValidation !== true) {
    errors.push(`Number of Employees: ${numberOfEmployeesValidation}`)
  }
  const yearlyRevenueValueValidation = validateNumericInput(yearlyRevenueValue, { greaterThanZero: true })
  if (yearlyRevenueValueValidation !== true) {
    errors.push(`Yearly Revenue: ${yearlyRevenueValueValidation}`)
  }
  const facilityAreaValidation = validateNumericInput(facilityArea)
  if (facilityAreaValidation !== true) {
    errors.push(`Facility Area: ${facilityAreaValidation}`)
  }
  return errors
}
