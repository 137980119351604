import { Scope3Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'
import DoughnutChart from '../../../graphs/DoughnutChart'

export default function EmployeeCommutingPie({
  data,
  modeOfTransports,
}: {
  data: Scope3Results
  modeOfTransports: any
}) {
  const chartData = Object.entries(data.sections_results.EmployeeCommuting.section_category_results).map(
    ([code, item]) => {
      return { name: modeOfTransports[code], value: item.kg_CO2e }
    },
  )

  const {
    largest_subscope_category_display_name,
    largest_subscope_category_kg_CO2e,
    private_transport_kg_CO2e,
    public_transport_kg_CO2e,
  } = data.results_text.EmployeeCommuting.variables
  return (
    <div className="chartSection40" id="pdfScope3EmployeeCommutingPieChart">
      <div className="chartContainer">
        <DoughnutChart id="EmployeeCommutingPieChart" data={chartData} />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          <p>
            This graph highlights distance travelled and emissions per transport type used by your employees commuting.
          </p>
          <p>
            The largest portion of your emissions came from <b>{largest_subscope_category_display_name}</b> emitting a
            total of <b>{formatResult(largest_subscope_category_kg_CO2e, 2)}</b> kg CO2e.
          </p>
          <p>
            Your employees produced <b>{formatResult(public_transport_kg_CO2e, 2)}</b> kg CO2e from public transport,
            and <b>{formatResult(private_transport_kg_CO2e, 2)}</b> kg CO2e from private transport.
          </p>
        </div>
      </div>
    </div>
  )
}
