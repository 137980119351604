import axios from 'axios'
import { LoginFields } from '../interface/formFields.interface'

export async function getMastercardTransactions(values: LoginFields): Promise<any[]> {
  return new Promise((resolve, reject) => {
    const accountId = '7633405982'
    axios({
      method: 'post',
      url: process.env.REACT_APP_CONNECT_API_URL + '/mastercard/' + values.accountId + '/transactions',
      headers: {
        'x-api-key': process.env.REACT_APP_CONNECT_API_KEY,
        'demo-api-key': process.env.REACT_APP_DEMO_API_KEY,
        username: values.username,
        password: values.password,
      },
    })
      .then((res: any) => {
        let transactions = res.data?.transactions?.map(transformFinconectaTransaction) ?? []
        transactions.sort((a: any, b: any) => {
          if (a.date < b.date) {
            return -1
          }
          if (a.date > b.date) {
            return 1
          }
          return 0
        })
        resolve(transactions)
      })
      .catch((err: any) => {
        console.error(`Unable to fetch transactions. Reason: ${err}`)
        reject()
      })
  })
}

const transformFinconectaTransaction = (transaction: any): any => ({
  accountNumber: transaction.accountNumber,
  amount: transaction.amount,
  description: transaction.description,
  mcc: transaction.mcc,
  category: transaction.category,
  merchant: transaction.merchant,
  date: transaction.date,
  id: transaction.id,
  kgCo2: transaction.kg_of_CO2e_emissions,
  icon: '',
  iconType: '',
})
