import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { generateOverviewPDF } from './pdf/overview'
import { generate1PagePDF } from './pdf/1-page-pdf'
import { generateHowDoIComparePDF } from './pdf/how-do-i-compare'
import { generateScope1PDF } from './pdf/scope-1'
import { generateScope2PDF } from './pdf/scope-2'
import { generateScope3PDF } from './pdf/scope-3'

export async function createPDF(logo: any) {
  let pdf = new jsPDF('portrait', 'pt', 'a4')

  const pdfWidth = pdf.internal.pageSize.getWidth()
  const pdfHeight = pdf.internal.pageSize.getHeight()

  //pdf = await generateOverviewPDF(pdf, pdfWidth, pdfHeight)
  pdf = await generate1PagePDF(pdf, pdfWidth, pdfHeight, logo)
  // pdf = await generateHowDoIComparePDF(pdf, pdfWidth, pdfHeight)
  // pdf = await generateScope1PDF(pdf, pdfWidth, pdfHeight)
  // pdf = await generateScope2PDF(pdf, pdfWidth, pdfHeight)
  // pdf = await generateScope3PDF(pdf, pdfWidth, pdfHeight)

  pdf.save('results.pdf')
}
