export const electricityGrids: { [key: string]: string } = {
  ELECAKGD: 'AKGD (ASCC Alaska Grid)',
  ELECAKMS: 'AKMS (ASCC Miscellaneous)',
  ELECAZNM: 'AZNM (WECC Southwest)',
  ELECCAMX: 'CAMX (WECC California)',
  ELECERCT: 'ERCT (ERCOT All)',
  ELECFRCC: 'FRCC (FRCC All)',
  ELECHIMS: 'HIMS (HICC Miscellaneous)',
  ELECHIOA: 'HIOA (HICC Oahu)',
  ELECMROE: 'MROE (MRO East)',
  ELECMROW: 'MROW (MRO West)',
  ELECNEWE: 'NEWE (NPCC New England)',
  ELECNWPP: 'NWPP (WECC Northwest)',
  ELECNYCW: 'NYCW (NPCC NYC/Westchester)',
  ELECNYLI: 'NYLI (NPCC Long Island)',
  ELECNYUP: 'NYUP (NPCC Upstate NY)',
  ELECPRMS: 'PRMS (Puerto Rico Miscellaneous)',
  ELECRFCE: 'RFCE (RFC East)',
  ELECRFCM: 'RFCM (RFC Michigan)',
  ELECRFCW: 'RFCW (RFC West)',
  ELECRMPA: 'RMPA (WECC Rockies)',
  ELECSPNO: 'SPNO (SPP North)',
  ELECSPSO: 'SPSO (SPP South)',
  ELECSRMV: 'SRMV (SERC Mississippi Valley)',
  ELECSRMW: 'SRMW (SERC Midwest)',
  ELECSRSO: 'SRSO (SERC South)',
  ELECSRTV: 'SRTV (SERC Tennessee Valley)',
  ELECSRVC: 'SRVC (SERC Virginia/Carolina)',
  ELECUSAVR: 'US Average',
}

export const electricityConsumptionUnits: any = {
  KWH: 'kWh',
  MWH: 'MWh',
}

export const supplierEmissionsFactorUnits: { [key: string]: string } = {
  KGKWH: 'kg CO2e per kWh',
  KGMWH: 'kg CO2e per MWh',
  LBSKWH: 'lbs CO2e per kWh',
  LBSMWH: 'lbs CO2e per MWh',
  STONKWH: 'short tons CO2e per kWh',
  STONMWH: 'short tons CO2e per MWh',
  MTONKWH: 'metric tons CO2e per kWh',
  MTONMWH: 'metric tons CO2e per MWh',
}

export const electricityConsumptionInputsUS = {
  electricityGrids,
  electricityConsumptionUnits,
  supplierEmissionsFactorUnits,
}
