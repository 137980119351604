import { useClient } from '../../context/ClientProvider'
import { LoginFields } from '../../interface/formFields.interface'
import { getMastercardTransactions } from '../../api/getMastercardTransactions'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import ErrorBox from '../boxes/ErrorBox'

export default function Login({ closePopup }: { closePopup: () => void }) {
  const { isFullScreenEnabled } = useClient()
  const [showWrongCredentials, setshowWrongCredentials] = useState(false)

  const { register, getValues, watch, setValue, handleSubmit } = useForm<LoginFields>({
    defaultValues: {
      username: '',
      password: '',
      accountId: '',
    },
  })

  async function onSubmit(values: LoginFields) {
    const button = document.getElementById('login-button') as HTMLButtonElement
    button.disabled = true

    getMastercardTransactions(values)
      .then((data) => {
        localStorage.setItem('accountId', getValues().accountId)
        localStorage.setItem('username', getValues().username)
        localStorage.setItem('password', getValues().password)
        closePopup()
      })
      .catch(() => {
        setshowWrongCredentials(true)
        button.disabled = false
      })
  }

  return (
    <div className={isFullScreenEnabled ? 'modal fullScreen' : 'modal'}>
      <div className={isFullScreenEnabled ? 'modalContent privacyPolicy fullScreen' : 'modalContent privacyPolicy'}>
        <div className="boxHeader subtitle">
          <div className="privacyNoticeTitle">Log in to the Connect Report Demo App</div>
        </div>

        <form onSubmit={handleSubmit(async (values) => onSubmit(values))}>
          <div className="loginGrid sectionBox">
            <label className="item1">Username</label>
            <input className="item2" {...register('username')} />
            <label className="item3">Password</label>
            <input className="item4" {...register('password')} />
            <label className="item5">AccountId</label>
            <input className="item6" {...register('accountId')} />
          </div>
          <div className="grayBox">
            <p>You can find these credentials in the Mastercard platform.</p>
            <p>
              By using this demo you are using our{' '}
              <a href="https://connect.earth/privacy-policy/" target="_blank">
                privacy policy
              </a>
              .
            </p>
          </div>
          {showWrongCredentials && (
            <div>
              <ErrorBox errors={['WRONG CREDENTIALS']} />
            </div>
          )}
          <div className="buttonContainer">
            <button type="submit" id="login-button">
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
