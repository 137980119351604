export const categories: any = {
  ActionCategories: [
    'Electronics/Applications',
    'Energy and Utilties',
    'Materials',
    'Suppliers',
    'Technology',
    'Transport',
    'Waste',
    'Office Supplies',
    'Employees',
  ],
  GeneralEducation: ['Netzero', 'Reporting'],
}

export const recommendations: any = {
  'Electronics/Applications': [
    'Buy refurbished devices',
    'Use greener refrigerants',
    'Responsibly dispose of refrigerants',
    'Buy more efficient electronics',
  ],
  'Energy and Utilties': [
    'Use smart meters',
    'Switch to renewable energy providers',
    'Use solar panels',
    'Incorporate smart plugs and timers',
    'Switch out old light bulbs for LED light bulbs',
    'Change the boiler setting',
    'Use natural light when you can',
    'Reduce heating temperature',
  ],
  'Materials': ["Transition away from paper to software", "Use recycled paper", "Reduce consumables purchases"],
  "Suppliers": ["Source sustainable suppliers"],
  "Technology": ["Move to serverless", "Use serverless services that have renewable energy certificates"],
  "Transport": ["Switch to electric vehicles", "Introduce cycle to work scheme", "Choose trains over planes", "Prioritise online meetings", "Encourage carpooling", "Work remotely"],
  "Waste": ["Implement the three R's", "Focus on reusing and reducing"],
  "Office Supplies": ["Choose second hand office furniture"],
  "Employees": ["Engaging employees"],
  "Netzero": ["Get started with Netzero", "Understand the basics of Netzero", "Netzero benefits"],
  "Reporting": ["Use the GRI for reporting your total impact", "Improve understanding of the GHG protocol", "Reporting Benefits"]
}

export const actions: any = {
  'Buy refurbished devices': 'Around 80% of an electronic product\'s carbon footprint comes from the manufacturing process, not its lifetime use. Buying second-hand avoids high emissions from manufacturing new devices.',
  'Use greener refrigerants': 'You can reduce your businesses environmental impact by opting for energy-efficient refrigeration and eco-friendly refrigerants.',
  'Responsibly dispose of refrigerants': 'Disposing of old fridges and appliances through services like Responsible Appliance Disposal (RAD) significantly minimizes harmful and greenhouse gas emissions into the atmosphere.',
  'Buy more efficient electronics': 'Purchasing devices with higher efficiency ratings can greatly reduce your energy use and emissions.',
  'Incorporate smart plugs and timers': 'If you are leasing premises, consider utilising Smart Plugs and timers to gauge the electricity consumption of devices and automatically switch them off based on a schedule.',
  'Use smart meters': 'Smart meters can give you control over your business\' energy consumption and expenses, removing estimated billing, manual meter readings, and simplifying energy usage monitoring over time.',
  'Switch to renewable energy providers': 'Switching to renewable and fossil-free energy providers is a simple way to reduce emissions from your electricity usage, causing a significant reduction of your businesses overall carbon footprint.',
  'Switch out old light bulbs for LED light bulbs': 'Switching to LED light bulbs can save you energy. They use as much as 75% less energy and last 25 times longer than traditional incandescent bulbs. Adding in motion sensors and timers can further reduce energy usage.',
  'Use solar panels': 'Consider integrating renewable energy sources into your operations. Install solar panels in your office or workspace to generate clean energy and decrease dependence on fossil fuels.',
  'Change the boiler setting': 'The default setting in many condensing boilers in homes and workplaces can be adjusted to increase efficiency and save up to 8% of the energy used to heat rooms and water.',
  'Use natural light when you can': 'Many offices are artificially lit unnecessarily. Utilising natural light saves energy and has been found to improve sleep and reduce health risks associated with unnatural lighting such as stress.',
  'Reduce heating temperature': 'A 1°C decrease in office thermostat lowers heating energy consumption by approximately 13%.',
  'Transition away from paper to software': 'Cut paper usage: go electronic with docs, online invoices, and utilise cloud storage. When printing, use recycled paper and print double-sided.',
  'Use recycled paper': 'Try switching to recycled paper, it has a better impact on our environment as it has 55% lower emissions then non recycled alternatives.',
  'Reduce consumables purchases': 'Reducing your purchases of consumables can lead to long-term cost savings and a decrease in your emissions. Start by swapping single-use items for durable, sustainable alternatives.',
  'Source sustainable suppliers': 'Generally the majority of a businesses emissions are from its supply chain. Consider incorporating greener companies both upstream and downstream.',
  'Move to serverless': 'Consider switching your on site server to the cloud, they are usually more efficient and often utilise renewable energy; meaning your company only uses resources needed, reducing on demand consumption and energy usage.',
  'Use serverless services that have renewable energy certificates': 'Choosing a serverless provider who is purchasing Renewable Energy Certificates makes sure your emissions are kept as low as possible.',
  'Switch to electric vehicles': 'The lifetime emissions of an electric vehicle are 50% lower than petrol cars on average, and as much as 70% in countries with primarily renewable energy.',
  'Introduce cycle to work scheme': 'Introducing a cycle-to-work scheme is a great way to reduce indirect emissions from employees\' commutes. This scheme would also save your employees money whilst improving their health.',
  'Choose trains over planes': 'Opting to travel by train for business purposes over shorter distances has a significantly reduced environmental impact compared to air travel.',
  'Prioritise online meetings': 'Prioritising online meetings over business travel can drastically reduce your emissions.',
  'Encourage carpooling': 'Promote employee carpooling to cut commuting emissions and foster colleague bonds. Exploring local carpooling programs can be a great starting point.',
  'Work remotely': 'Remote working can reduce your emissions from employee commuting even when accounting for work-from-home emissions.',
  'Implement the three R\'s': 'Implementing the three R\'s (Reduce, Reuse, Recycle) can reduce your emissions by minimizing waste generation, promoting resource conservation, and reducing the need for new production.',
  'Focus on reusing and reducing': 'By focusing on waste reduction and reusing materials, you can reduce your environmental impact and contribute to a more sustainable future, whilst simultaneously cutting down on emissions.',
  'Choose second hand office furniture': 'Choosing second-hand furniture or opting for long-term furniture leasing can be a great way to reduce emissions from your purchases and promote cost savings.',
  'Engaging employees': 'Encouraging employees to share sustainability ideas fosters a sense of purpose in tackling climate change and may unlock new avenues for emission reduction.',
  'Get started with Netzero': 'The first step to Net Zero is measuring your emissions. Continuous measurement is key to focus efforts and effectively reduce emissions.',
  'Understand the basics of Netzero': 'The basics of achieving net-zero involve increasing energy efficiency, opting for renewable energy, and compensating for hard-to-remove emissions with long-lasting carbon removals.',
  'Netzero benefits': 'Lowering emissions sets your company apart and gives you a competitive advantage. Studies show that consumers prioritise ecological products and services, making commitments to Net Zero and sustainable practices a strategic move for success.',
  'Use the GRI for reporting your total impact': 'To comprehensively report on your global impact, choose the Global Reporting Initiative (GRI) Sustainability Reporting Standards - covering all 17 SDGs',
  'Improve understanding of the GHG protocol': 'The GHG Protocol: Global standard to measure and manage greenhouse gas emissions, boost sustainability, and align with climate goals across all company operations',
  'Reporting Benefits': 'Transparency and reliability in sustainability reporting attracts investors. Emission reporting and sustainability strategy implemenation could enhance your investment opportunities.'
}
