import { Bar } from 'react-chartjs-2'
import { useRegion } from '../../../../context/RegionProvider'
import { electricityConsumptionMapping } from '../../../../data/inputs/electricity-consumption/electricity-consumption.mapping'
import { Scope2Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function BenchmarkBarChart({ data }: { data: Scope2Results }) {
  const { country } = useRegion()
  const { electricityConsumptionUnits } = electricityConsumptionMapping[country.code]

  interface Dataset {
    label: string
    data: number[]
    color: string
    hoverColor: string
  }

  const labels: string[] = ['Electricity', 'Heat']
  const datasets: any[] = [
    {
      label: 'Your company',
      data: [data.benchmarks.ElectricityConsumption.converted_value, data.benchmarks.HeatConsumption.converted_value],
      color: 'rgba(31, 216, 171, 0.5)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
    {
      label: 'Average company',
      data: [data.benchmarks.ElectricityConsumption.benchmark_value, data.benchmarks.HeatConsumption.benchmark_value],
      color: 'rgba(31, 149, 216, 0.5)',
      hoverColor: 'rgba(31, 149, 216, 1)',
    },
  ]

  return (
    <div className="benchmarkSection" id="pdfScope2ElecAndHeatChart">
      <div className="chartInfo">
        <div className="textStrong">Electricity and Heat Consumption Benchmarking</div>
        <p>
          This graph shows a comparison between your electricity and heat consumption and the average for companies with
          similarly sized premises. This should help you understand whether your company is using more or less
          electricity and heating than similar businesses.
        </p>
        <p>
          If your consumption is higher than average, then it may be worth considering energy-saving measures. This can
          reduce your carbon footprint and reduce your energy bills at the same time. Please note that we only show
          benchmarks for electricity if you have consumed electricity, and for heat if you have consumed heat.
        </p>
      </div>
      <div className="chartContainer">
        <Bar
          id="ElecAndHeatChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'rectRounded',
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    return context[0].label + ' consumption'
                  },
                  label: (context) => {
                    return (
                      formatResult(context.raw, 2) +
                      ' ' +
                      electricityConsumptionUnits[data.benchmarks.ElectricityConsumption.final_unit]
                    )
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  count: 6,
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: Dataset) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                hoverBackgroundColor: row.hoverColor,
                borderRadius: 10,
                barPercentage: 0.9,
                categoryPercentage: 0.6,
              }
            }),
          }}
        />
      </div>
    </div>
  )
}
