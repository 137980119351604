import { useRegion } from '../../context/RegionProvider'
import { electricityConsumptionMapping } from '../../data/inputs/electricity-consumption/electricity-consumption.mapping'
import { ElectricityConsumption } from '../../interface/inputData.interface'
import CollapsibleBox from '../boxes/CollapsibleBox'

export default function ElectricityConsumptionReview({ locations }: { locations: ElectricityConsumption[] }) {
  const { country } = useRegion()
  const { electricityGrids, electricityConsumptionUnits, supplierEmissionsFactorUnits } =
    electricityConsumptionMapping[country.code]

  return (
    <CollapsibleBox
      header={<div className="subtitle">Electricity Consumption</div>}
      body={
        <>
          {!locations.length ? (
            <div className="grayBox notSpecified">No electricity consumption specified</div>
          ) : (
            <div className="reviewTable mt2">
              {locations.map((location, index) => (
                <LocationSection location={location} index={index} key={'location' + index} />
              ))}
            </div>
          )}
        </>
      }
    />
  )

  function LocationSection({ location, index }: { location: ElectricityConsumption; index: number }) {
    const { gridCode, supplierEmissionsFactor, supplierUnitCode, value, unitCode } = location
    return (
      <>
        <div className="tableHeader" data-cy={`review-location-${index}-electricity-consumption-header`}>
          Location {index + 1}
        </div>
        <div className="tableHeader"></div>

        {country.code === 'US' && (
          <>
            <div className="tableItem">Electricity Grid</div>
            <div className="tableItem">{electricityGrids[gridCode]}</div>
          </>
        )}

        <div className="tableItem">Supplier Emissions Factor</div>
        <div className="tableItem">
          {supplierEmissionsFactor && supplierEmissionsFactor !== 'Unknown' ? (
            supplierEmissionsFactor + ' ' + supplierEmissionsFactorUnits[supplierUnitCode]
          ) : (
            <div className="notSpecified">Not specified</div>
          )}
        </div>

        <div className="tableItem">Electricity Consumption</div>
        <div className="tableItem">
          {value ? (
            value + ' ' + electricityConsumptionUnits[unitCode]
          ) : (
            <div className="notSpecified">Not specified</div>
          )}
        </div>
      </>
    )
  }
}
