import React, { createContext, useContext, useEffect, useState } from 'react'
import { getSession } from '../api/validateSession'
import { Session } from '../interface/reportSession.interface'
import isValidUUID from '../utils/isValidUUID'

const SessionContext = createContext<Session>({
  sessionId: '',
  clientId: '',
  userId: '',
  expirationTs: 3600,
})

export function useSession() {
  return useContext(SessionContext)
}

export function SessionProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session>({
    sessionId: '',
    clientId: '',
    userId: '',
    expirationTs: 3600,
  })

  useEffect(() => {
    const queryParameters: URLSearchParams = new URLSearchParams(window.location.search)
    const sessionId = queryParameters.get('sessionId')
    if (sessionId && isValidUUID(sessionId)) {
      getSession(sessionId)
        .then((session) => {
          setSession(session)
        })
        .catch((err) => console.log(err))
    }
  }, [])

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}
