import useMediaQuery from '../../../hooks/useMediaQuery'
import { Scope1Results } from '../../../interface/result.interface'
import formatResult from '../../../utils/formatResult'
import CollapsibleBox from '../../boxes/CollapsibleBox'
import LockedTextBox from '../../boxes/LockedTextBox'
import PieChart from './components/PieChart'
import BenchmarkStationaryChart from './components/BenchmarkStationaryChart'
import BenchmarkMobileChart from './components/BenchmarkMobileChart'

export default function Scope1({
  data,
  dataAvailable,
  isFreeUser,
}: {
  data: Scope1Results
  dataAvailable: any
  isFreeUser: any
}) {
  const isMobile = useMediaQuery('(max-width: 780px)')
  const body = isFreeUser ? (
    <LockedTextBox dataCy="scope-1-locked" />
  ) : !!dataAvailable ? (
    <div>
      {Object.keys(data.results_text.variables).length > 0 && <PieChart data={data} />}
      {data.benchmarks.FuelConsumptionStationary?.benchmark_value && (
        <>
          <div className="divider" />
          <BenchmarkStationaryChart data={data} />
        </>
      )}
      {data.benchmarks.DistanceTravelledMobile?.benchmark_value && (
        <>
          <div className="divider" />
          <BenchmarkMobileChart data={data} />
        </>
      )}
    </div>
  ) : (
    <div>No data available for Scope 1</div>
  )
  return (
    <CollapsibleBox
      header={
        <>
          {isMobile ? (
            <div className="resultSubTitle" id="pdfScope1Title">
              <div>Direct Emissions (Scope 1)</div>
              <div className="scopeTotalEmissions">
                Total Emissions: <span className="textStrong">{formatResult(data.kg_CO2e, 0)} kg</span>
              </div>
            </div>
          ) : (
            <div className="resultSubTitle" id="pdfScope1Title">
              Direct Emissions (Scope 1){' '}
              <span className="ml4 textStrong" data-cy="scope-1-direct-emissions-value">
                {formatResult(data.kg_CO2e, 0)}
              </span>{' '}
              kg
              {isFreeUser && (
                <div className="textSmallSection">
                  This section shows a more detailed breakdown of your Scope 1 emissions
                </div>
              )}
            </div>
          )}
        </>
      }
      body={body}
    ></CollapsibleBox>
  )
}
