import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

export default function CollapsibleBox({
  header,
  body,
  isCollapsed,
}: {
  header: JSX.Element
  body: JSX.Element
  isCollapsed?: boolean
}) {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(isCollapsed || false)

  return (
    <div className="sectionBox">
      <div className="textLarge collapsibleHeader clickable" onClick={() => setIsHeaderCollapsed((prevVal) => !prevVal)}>
        {header}
        <div>
          {isHeaderCollapsed && <FontAwesomeIcon className="clickable" icon={faChevronUp} />}
          {!isHeaderCollapsed && <FontAwesomeIcon className="clickable" icon={faChevronDown} />}
        </div>
      </div>
      {!isHeaderCollapsed && <>{body}</>}
    </div>
  )
}
