export const vehicleTypes: { [key: string]: string } = {
  PASCAR: 'Passenger Car',
  LDT: 'Light-Duty Truck',
  MDT: 'Medium-Duty Truck',
  HDT: 'Heavy-Duty Truck',
  MOTO: 'Motorcycle',
}

export const fuelTypes: { [key: string]: string } = {
  //BIODIE: 'Biodiesel',
  CNG: 'CNG',
  DIE: 'Diesel',
  //ETH: 'Ethanol',
  GASO: 'Gasoline',
  LNG: 'LNG',
  LPG: 'LPG',
}

export const distanceUnits: { [key: string]: string } = {
  MILE: 'Mile',
  KM: 'Km',
}

export const fuelsPerVehicle: { [key: string]: string[] } = {
  MOTO: ['GASO'],
  PASCAR: ['DIE', 'GASO'],
  LDT: ['CNG', 'DIE', 'GASO', 'LNG', 'LPG'],
  MDT: ['CNG', 'DIE', 'GASO', 'LNG', 'LPG'],
  HDT: ['CNG', 'DIE', 'GASO', 'LNG', 'LPG'],
}

export const mobileCombustionInputsUS = { vehicleTypes, fuelTypes, distanceUnits, fuelsPerVehicle }
