import CollapsibleBox from '../../../boxes/CollapsibleBox'

export default function BusinessTravelInfoHeader({ openPopup }: { openPopup: any }) {
  return (
    <CollapsibleBox
      isCollapsed={true}
      header={
        <div className="textLarge">In this section, please enter details of business travel for all employees</div>
      }
      body={
        <>
          <div className="listItem mt2">Tips:</div>
          <div className="grayBox">
            <p>
              1. This does not include travel using company-owned vehicles, which should be reported instead under{' '}
              <i>Fuel Consumption</i>, or any travel for employee commuting, which should be reported instead under{' '}
              <i>Employee Commuting</i>
            </p>
            <p>
              2. Business travel refers to employees or company representatives traveling for work-related purposes,
              such as attending meetings, conferences, or training sessions. This travel may involve any mode of
              transportation, including rental cars, planes, or trains. See more information about the different modes
              of{' '}
              <span className="link" onClick={openPopup}>
                here
              </span>
            </p>
            <p>
              3. Social events with the team are not considered part of business trips unless the event involves travel
              outside of the employee's normal commuting
            </p>
            <p>
              4. Hotel stays are also included under business travel. They should be accounted on a total room-night
              basis. For example, 3 rooms for 3 nights is 9 room-nights
            </p>
          </div>
        </>
      }
    ></CollapsibleBox>
  )
}
