import { FuelConsumptionFields, Machine, Vehicle } from '../../interface/formFields.interface'
import validateNumericInput from './number.validation'
import { mobileCombustionMapping } from '../inputs/mobile-combustion/mobile-combustion.mapping'
import { stationaryCombustionMapping } from '../inputs/stationary-combustion/stationary-combustion.mapping'

export default function getErrors(values: FuelConsumptionFields, countryCode: string) {
  const { fuelsPerVehicle } = mobileCombustionMapping[countryCode]
  const { unitsPerFuel } = stationaryCombustionMapping[countryCode]
  const errors: string[] = []
  const { vehicles, machines } = values
  vehicles.forEach((vehicle: Vehicle, i: number) => {
    if (!vehicle.vehicleCode) {
      errors.push(`Vehicle ${i + 1} - Vehicle Type: Please select an option.`)
    }
    if (!vehicle.fuelCode) {
      errors.push(`Vehicle ${i + 1} - Fuel Type: Please select an option.`)
    }
    if (!vehicle.fuelCode || !fuelsPerVehicle[vehicle.vehicleCode]?.includes(vehicle.fuelCode)) {
      errors.push(`Vehicle ${i + 1} - Fuel type: Please select an option.`)
    }
    const validation = validateNumericInput(vehicle.value, { greaterThanZero: true })
    if (validation !== true) {
      errors.push(`Vehicle ${i + 1} - Travelled Distance: ${validation}`)
    }
  })
  machines.forEach((machine: Machine, i: number) => {
    if (!machine.fuelCode) {
      errors.push(`Machine ${i + 1} - Fuel Type: Please select an option.`)
    }
    if (!machine.unitCode || !unitsPerFuel[machine.fuelCode]?.includes(machine.unitCode)) {
      errors.push(`Machine ${i + 1} - Fuel Consumption: Unit is missing.`)
    }
    const validation = validateNumericInput(machine.value, { greaterThanZero: true })
    if (validation !== true) {
      errors.push(`Machine ${i + 1} - Fuel Consumption: ${validation}`)
    }
  })
  return errors
}
