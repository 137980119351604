import { MccGroup } from './../interface/transactionData.interface'
export function sortBySpend(data: [string, MccGroup][]) {
  return data.sort((a: [string, MccGroup], b: [string, MccGroup]) => {
    if (a[1].spend < b[1].spend) {
      return 1
    }
    if (a[1].spend > b[1].spend) {
      return -1
    }
    return 0
  })
}
