import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRegion } from '../../../../context/RegionProvider'
import { businessTravelMapping } from '../../../../data/inputs/business-travel/business-travel.mapping'
import { distanceUnits } from '../../../../data/units/distanceUnits'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function TransportItem({
  index,
  register,
  removeTransport,
}: {
  index: number
  register: any
  removeTransport: any
}) {
  const { country } = useRegion()
  const { businessTransports } = businessTravelMapping[country.code]
  const transportModeOptions: Array<JSX.Element> = objectToOptions(businessTransports)
  const unitOptions: Array<JSX.Element> = objectToOptions(distanceUnits)

  return (
    <div className="itemCard">
      <div className="itemTitle">
        <div>Mode of Transport {index + 1}</div>
        <FontAwesomeIcon
          className="clickable"
          data-cy={`delete-transport-${index}-icon`}
          icon={faTrash}
          onClick={() => removeTransport(index)}
        ></FontAwesomeIcon>
      </div>
      <div className="itemInputsContainer" key={'transport' + index}>
        <div className="itemInput">
          <div className="itemLabel">Transport Type</div>
          <select {...register(`transports.${index}.transportModeCode`)}>{transportModeOptions}</select>
        </div>
        <div className="itemInput">
          <div className="itemLabel">Travelled Distance</div>
          <div className="inlineInputs">
            <input
              className="width100"
              type="number"
              step="any"
              inputMode="decimal"
              {...register(`transports.${index}.value`, {
                valueAsNumber: true,
              })}
            />
            <select className="width100" {...register(`transports.${index}.unitCode`)}>
              {unitOptions}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
