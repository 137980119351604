export const businessTransports: any = {
  AVCAR: 'Car',
  MOTO: 'Motorcycle',
  LGV: 'Van',
  TAXI: 'Taxi',
  BUS: 'Bus',
  NATRAIL: 'National Rail',
  ITLRAIL: 'International Rail',
  TRANRAIL: 'Transit Rail (i.e. Underground, Tram)',
  AIRDOM: 'Air Travel - Domestic (UK)',
  AIRSHO: 'Air Travel - International Short Haul (< 3700 kilometres)',
  AIRLON: 'Air Travel - International Long Haul (>= 3700 kilometres)',
  FERR: 'Ferry',
}

//USED FOR BENCHMARKS THAT WE DON'T HAVE FOR NOW
export const businessTransportsGrouped: any = {
  // PASCALDTR: 'Passenger Cars and Light-Duty Trucks',
  // MOTO: 'Motorcycles',
  // INRAINACOMRAI: 'Intercity Commuter Rail',
  // TRANRAI: 'Transit Rail',
  // BUS: 'Buses',
  // AIRSHOMEDLON: 'Airplanes (Short and Long Haul)',
}

export const businessTravelInputsUK = { businessTransports, businessTransportsGrouped }
