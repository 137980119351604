import { Bar } from 'react-chartjs-2'
import { purchasedGoodCategories } from '../../../../data/inputs/purchasedGoods'
import { Scope3Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'

export default function PurchasedGoodsChart({ data, isMobile }: { data: Scope3Results; isMobile: boolean }) {
  const sortedData = Object.entries(data.sections_results.PurchasedGoods.section_category_results)
    .filter(([, item]) => item.kg_CO2e > 0)
    .sort(([, itemA], [, itemB]) => itemB.kg_CO2e - itemA.kg_CO2e)
  const labels = sortedData.map((category) => purchasedGoodCategories[category[0]])
  const labelledData = sortedData.map((category) => category[1].kg_CO2e)
  const datasets = [
    {
      label: 'Emissions',
      data: labelledData,
      color: 'rgba(31, 216, 171, 1)',
      hoverColor: 'rgba(31, 216, 171, 1)',
    },
  ]
  return (
    <div className="chartSection60">
      <div className={isMobile ? 'chartContainer mobile' : 'chartContainer'}>
        <Bar
          id="PurchasedGoodsChart"
          options={{
            responsive: true,
            maintainAspectRatio: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    return context[0].label + ' emissions'
                  },
                  label: (context) => {
                    return formatResult(context.raw, 2) + ' kg CO2e'
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  font: {
                    size: isMobile ? 8 : 10,
                  },
                  padding: 0,
                  autoSkip: false,
                  callback: (value, index, values) => {
                    if (typeof value === 'number') {
                      return labels[value]?.substring(0, 12) + '...'
                    }
                    return value
                  },
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels,
            datasets: datasets.map((row: any) => {
              return {
                data: row.data,
                label: row.label,
                backgroundColor: row.color,
                hoverBackgroundColor: row.hoverColor,
                borderRadius: 5,
                barPercentage: 0.9,
                categoryPercentage: 0.9,
              }
            }),
          }}
        />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          {!isMobile &&
            'This graph indicates your total spend and emissions per purchased good and service that your company has purchased.'}{' '}
          Your greatest emissions come from{' '}
          {labels.length >= 3 && labelledData.length >= 3 ? (
            <>
              <b>{labels[0]}</b> at <b>{formatResult(labelledData[0], 2)}</b> kg CO2e, <b>{labels[1]}</b> at{' '}
              <b>{formatResult(labelledData[1], 2)}</b> kg CO2e and <b>{labels[2]}</b> at{' '}
              <b>{formatResult(labelledData[2], 2)}</b> kg CO2e.
            </>
          ) : labels.length < 3 && labels.length > 1 && labelledData.length < 3 && labelledData.length > 1 ? (
            <>
              <b>{labels[0]}</b> at <b>{formatResult(labelledData[0], 2)}</b> kg CO2e and <b>{labels[1]}</b> at{' '}
              <b>{formatResult(labelledData[1], 2)}</b> kg CO2e
            </>
          ) : (
            <>
              <b>{labels[0]}</b> at <b>{formatResult(labelledData[0], 2)}</b> kg CO2e
            </>
          )}
          <br />
        </div>
      </div>
    </div>
  )
}
