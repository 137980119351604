export const disclaimerList = (appName?: string): string[] => {
  return [
    `${
      appName || 'Connect Report'
    } uses emissions factors from government and trusted third-party sources to estimate your organization’s carbon emissions. These estimates use averaged figures for many types of activities, to reduce the amount of user input required. Activities refers to all activities conducted in the course of business that have associated emissions. We do our best to minimize any resulting inaccuracy in these estimates.`,
    `The accuracy of the emissions estimate depends on the quality of activity and spend data you provide. Activity data is data that quantifies activities conducted in the course of business, for example, distance driven in vehicles. Spend data is data about the amount of expenditure by your company categorized according to the nature of the purchased goods and services.`,
    `${
      appName || 'Connect Report'
    } is intended to help you make sustainable choices. However, we cannot guarantee the accuracy of the emissions data. We are not responsible for decisions you make based on ${
      appName || 'Connect Report'
    } data.`,
    `The emissions estimate we provide is not an official certification or audit of a company's carbon footprint. ${
      appName || 'Connect Report'
    } is not suitable for meeting any regulatory requirements.`,
    `${
      appName || 'Connect Report'
    } is designed to estimate your organization’s carbon emissions, helping you identify areas for improvement and implement reduction strategies.`,
    `${
      appName || 'Connect Report'
    } is intended for use by service-oriented, office-based SMEs. It might not be a suitable emissions estimation tool for other organizations.`,
    `${
      appName || 'Connect Report'
    } calculates the scopes, as defined by the Greenhouse Gas Protocol, that are material to most SMEs, but not all. For official reporting purposes, you should assess the materiality of the emissions from your business activities for which you cannot enter data, such as process emissions from manufacturing processes. If you find that you cannot enter data on an activity that would likely contribute in a significant way to your overall emissions, then you should make efforts to disclose these omissions wherever you use the emissions estimate generated by ${
      appName || 'Connect Report'
    }.`,
  ]
}
