import { Scope3Results } from '../../../../interface/result.interface'
import formatResult from '../../../../utils/formatResult'
import DoughnutChart from '../../../graphs/DoughnutChart'

export default function PieChart({ data }: { data: Scope3Results }) {
  const chartData = [
    { name: 'Business Travel', value: data.sections_results.BusinessTravel.kg_CO2e },
    { name: 'Employee Commuting', value: data.sections_results.EmployeeCommuting.kg_CO2e },
    { name: 'Purchased Goods and Services', value: data.sections_results.PurchasedGoods.kg_CO2e },
  ]

  const variables = data.results_text.scope_3_distribution.variables
  delete variables.largest_subscope_text

  return (
    <div className="chartSection40" id="pdfScope3PieChart">
      <div className="chartContainer">
        <DoughnutChart id="Scope3PieChart" data={chartData} />
      </div>
      <div className="chartInfo">
        <div className="textStrong">Analysis</div>
        <div>
          This pie chart shows your Scope 3 emissions across the following subcategories:{' '}
          {Object.keys(variables).map((key) => {
            if (variables[key]?.subscope_display_name) {
              return (
                <div key={key}>
                  {variables[key]?.subscope_display_name} (<b>{formatResult(variables[key]?.subscope_pct, 2)}</b> %),{' '}
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
