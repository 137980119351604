import { formatToComma } from './formatToComma'

export default function formatResult(value: number | string | unknown, decimals: number) {
  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'number') {
    return formatToComma(+value.toFixed(decimals)).toLocaleString()
  }
  return 'Unknown'
}
