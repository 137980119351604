import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hotelRegions } from '../../../../data/inputs/hotelStaysUS'
import { objectToOptions } from '../../../../utils/objectToOptions'

export default function HotelStayItem({
  index,
  register,
  removeHotelStay,
}: {
  index: number
  register: any
  removeHotelStay: any
}) {
  const hotelRegionOptions: Array<JSX.Element> = objectToOptions(hotelRegions)

  return (
    <div className="itemCard">
      <div className="itemTitle">
        <div>Hotel Stay Region {index + 1}</div>
        <FontAwesomeIcon
          className="clickable gray6Color"
          data-cy={`delete-hotel-${index}-icon`}
          icon={faTrash}
          onClick={() => removeHotelStay(index)}
        ></FontAwesomeIcon>
      </div>
      <div className="itemInputsContainer" key={'hotel' + index}>
        <div className="itemInput">
          <div className="itemLabel">Region</div>
          <select {...register(`hotelStays.${index}.hotelRegionCode`)}>{hotelRegionOptions}</select>
        </div>
        <div className="itemInput">
          <div className="itemLabel">Number of Room-Nights</div>
          <input
            type="number"
            step="any"
            {...register(`hotelStays.${index}.value`, {
              valueAsNumber: true,
            })}
          />
        </div>
      </div>
      <input type="hidden" {...register(`hotelStays.${index}.unitCode`)} />
    </div>
  )
}
