import html2canvas from 'html2canvas'

export async function addLogo(pdf: any, pdfWidth: number, pdfHeight: number, logo: any) {
  try {
    var imgLogoHeader = new Image()
    imgLogoHeader.src = logo
    const imgPropertiesLogoHeader = pdf.getImageProperties(imgLogoHeader)
    const pdfHeightLogoHeader = pdfHeight * 0.05
    const pdfWidthLogoHeader = (imgPropertiesLogoHeader.width * pdfHeightLogoHeader) / imgPropertiesLogoHeader.height
    pdf.addImage(imgLogoHeader, 'PNG', pdfWidth * 0.05, pdfHeight * 0.02, pdfWidthLogoHeader, pdfHeightLogoHeader)
  } catch (error) {
    console.log(error)
  }
  return pdf
}
