export const vehicleTypes: { [key: string]: string } = {
  SMCAR: 'Small Car',
  MDCAR: 'Medium Car',
  LGCAR: 'Large Car',
  AVGCAR: 'Average Car',
  LGV: 'Van (< 3500 kg)',
  MOTO: 'Motorcycle',
  HGV: 'Heavy Goods Vehicle (>= 3500 kg)',
  HGVRFG: 'Refrigerated Heavy Goods Vehicle (>= 3500 kg)',
}

export const fuelTypes: { [key: string]: string } = {
  GASO: 'Petrol',
  DIE: 'Diesel',
  HYB: 'Hybrid (not plug-in)',
  LPG: 'LPG',
  CNG: 'CNG',
}

export const distanceUnits: { [key: string]: string } = {
  KM: 'Km',
  MILE: 'Mile',
}

export const fuelsPerVehicle: { [key: string]: string[] } = {
  SMCAR: ['DIE', 'GASO', 'HYB'],
  MDCAR: ['DIE', 'GASO', 'HYB', 'LPG', 'CNG'],
  LGCAR: ['DIE', 'GASO', 'HYB', 'LPG', 'CNG'],
  AVGCAR: ['DIE', 'GASO', 'HYB', 'LPG', 'CNG'],
  LGV: ['DIE', 'GASO', 'LPG', 'CNG'],
  MOTO: ['GASO'],
  HGV: ['DIE'],
  HGVRFG: ['DIE'],
}

export const mobileCombustionInputsUK = { vehicleTypes, fuelTypes, distanceUnits, fuelsPerVehicle }
