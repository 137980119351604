export const electricityGrids: { [key: string]: string } = {
  ELEC: 'Unique grid',
}

export const electricityConsumptionUnits: any = {
  KWH: 'kWh',
  MWH: 'MWh',
}

export const supplierEmissionsFactorUnits: { [key: string]: string } = {
  KGKWH: 'kg CO2e per kWh',
  KGMWH: 'kg CO2e per MWh',
  LBSKWH: 'lbs CO2e per kWh',
  LBSMWH: 'lbs CO2e per MWh',
  MTONKWH: 'metric tons CO2e per kWh',
  MTONMWH: 'metric tons CO2e per MWh',
}

export const electricityConsumptionInputsUK = {
  electricityGrids,
  electricityConsumptionUnits,
  supplierEmissionsFactorUnits,
}
