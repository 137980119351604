import { useState } from 'react'
import { GhgProtocolResults } from '../../interface/result.interface'
import formatResult from '../../utils/formatResult'
import DoughnutChart from '../graphs/DoughnutChart'
import GhgpInfo from '../popups/GhgpInfo'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useClient } from '../../context/ClientProvider'

export default function GhgReport({ data }: { data: GhgProtocolResults }) {
  const [showPopup, setShowPopup] = useState(false)
  const [showGhgChart, setShowGhgChart] = useState(true)
  const [showIntensityText, setShowIntensityText] = useState(true)
  const { isFreeUser } = useClient()

  const isMobile = useMediaQuery('(max-width: 780px)')

  const openPopup = () => {
    setShowPopup(true)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  return (
    <>
      {showPopup && <GhgpInfo closePopup={closePopup} />}
      <div className="resultTitle" id="pdfGhgReportTitle">
        Greenhouse Gas Report
      </div>
      <div className="sectionBox ghgContainer">
        <div>
          <p id="pdfGhgReportP1">
            This section contains a more detailed breakdown of your footprint, with all the information that you will
            need to share with stakeholders and to comply with regulations. The data here is presented according to the
            Greenhouse Gas Protocol.
          </p>
          <p>
            If you need a reminder about the Greenhouse Gas Protocol and what the different Scopes (1, 2 and 3) mean,
            click{' '}
            <span className="link" onClick={openPopup}>
              here
            </span>
          </p>
          <div className="textLarge" id="pdfGhgReportSubTitle">
            Emissions Breakdown
          </div>
        </div>
        <GhgTable />
        <GhgChart />
        <IntensityText />
      </div>
    </>
  )

  function GhgTable() {
    return (
      <div className="ghgTable mt4" id="pdfGhgReportTable">
        {/* Header */}
        <div className="tableHeader">GHG Protocol Scope</div>
        <div className="tableHeader">Emissions (kg CO2e)</div>
        <div className="tableHeader thirdColumn">Share of total emissions (%)</div>

        {/* Scope 1 */}
        <div className="tableSubHeader">Scope 1</div>
        <div className="tableSubHeader" data-cy="scope-1-ghg-report-total-emissions-value">
          {formatResult(data.scope_1.kg_CO2e, 2)}
        </div>
        <div className="tableSubHeader thirdColumn" data-cy="scope-1-ghg-report-share-emissions-value">
          {formatResult(data.scope_1.pct_total, 1)}
        </div>

        {!isFreeUser && (
          <>
            <div className="tableItem">Mobile combustion</div>
            <div className="tableItem">
              {formatResult(data.scope_1.sections_results.DistanceTravelledMobile.kg_CO2e, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_1.sections_results.DistanceTravelledMobile.pct_total, 1)}
            </div>
            <div className="tableItem">Stationary combustion</div>
            <div className="tableItem">
              {formatResult(data.scope_1.sections_results.FuelConsumptionStationary.kg_CO2e, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_1.sections_results.FuelConsumptionStationary.pct_total, 1)}
            </div>
          </>
        )}

        {/* Scope 2 */}
        <div className="tableSubHeader">Scope 2</div>
        <div className="tableSubHeader" data-cy="scope-2-ghg-report-total-emissions-value">
          {formatResult(data.scope_2.kg_CO2e, 2)}
        </div>
        <div className="tableSubHeader thirdColumn" data-cy="scope-2-ghg-report-share-emissions-value">
          {formatResult(data.scope_2.pct_total, 1)}
        </div>

        {!isFreeUser ? (
          <>
            <div className="tableItem">Electricity consumption (location-based)</div>
            <div className="tableItem">
              {formatResult(data.scope_2.sections_results.ElectricityConsumption?.kg_CO2e_location_based, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_2.sections_results.ElectricityConsumption?.pct_total_loc, 1)}
            </div>
            <div className="tableItem">Electricity consumption (market-based)</div>
            <div className="tableItem">
              {formatResult(data.scope_2.sections_results.ElectricityConsumption?.kg_CO2e_market_based, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_2.sections_results.ElectricityConsumption?.pct_total_mkt, 1)}
            </div>
            <div className="tableItem">Heat consumption (location-based)</div>
            <div className="tableItem">
              {formatResult(data.scope_2.sections_results.HeatConsumption?.kg_CO2e_location_based, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_2.sections_results.HeatConsumption?.pct_total_loc, 1)}
            </div>
            <div className="tableItem">Heat consumption (market-based)</div>
            <div className="tableItem">
              {formatResult(data.scope_2.sections_results.HeatConsumption?.kg_CO2e_market_based, 2)}
            </div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_2.sections_results.HeatConsumption?.pct_total_mkt, 1)}
            </div>
          </>
        ) : null}

        {/* Scope 3 */}
        <div className="tableSubHeader">Scope 3</div>
        <div className="tableSubHeader" data-cy="scope-3-ghg-report-total-emissions-value">
          {formatResult(data.scope_3.kg_CO2e, 2)}
        </div>
        <div className="tableSubHeader thirdColumn" data-cy="scope-3-ghg-report-share-emissions-value">
          {formatResult(data.scope_3.pct_total, 1)}
        </div>

        {!isFreeUser && (
          <>
            <div className="tableItem">3.1 Purchased Goods</div>
            <div className="tableItem">{formatResult(data.scope_3.sections_results.PurchasedGoods.kg_CO2e, 2)}</div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_3.sections_results.PurchasedGoods.pct_total, 1)}
            </div>
            <div className="tableItem">3.6 Business travel</div>
            <div className="tableItem">{formatResult(data.scope_3.sections_results.BusinessTravel.kg_CO2e, 2)}</div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_3.sections_results.BusinessTravel.pct_total, 1)}
            </div>
            <div className="tableItem">3.7 Employee Commuting</div>
            <div className="tableItem">{formatResult(data.scope_3.sections_results.EmployeeCommuting.kg_CO2e, 2)}</div>
            <div className="tableItem thirdColumn">
              {formatResult(data.scope_3.sections_results.EmployeeCommuting.pct_total, 1)}
            </div>
          </>
        )}

        {/* Totals */}
        <div className="tableSubHeader">Total Scope 1 and 2</div>
        <div className="tableSubHeader" data-cy="scope-1-and-2-ghg-report-total-emissions-value">
          {formatResult(data.scope_1_and_2_total.kg_CO2e, 2)}
        </div>
        <div className="tableSubHeader thirdColumn" data-cy="scope-1-and-2-ghg-report-share-emissions-value">
          {formatResult(data.scope_1_and_2_total.pct_total, 1)}
        </div>

        <div className="tableItem textStrong">Total Scope 1, 2 and 3</div>
        <div className="tableItem textStrong" data-cy="scope-1-2-and-3-ghg-report-total-emissions-value">
          {formatResult(data.kg_CO2e, 2)}
        </div>
        <div className="tableItem textStrong thirdColumn"></div>
      </div>
    )
  }

  function GhgChart() {
    const chartData = [
      { name: 'Scope 1', value: data.scope_1.kg_CO2e },
      { name: 'Scope 2', value: data.scope_2.kg_CO2e },
      { name: 'Scope 3', value: data.scope_3.kg_CO2e },
    ]
    const { scope_1_pct, scope_2_pct, scope_3_pct, largest_subscope_display_name, largest_subscope_pct } =
      data.results_text.emissions_spread?.variables
    return Object.keys(data.results_text.emissions_spread.variables).length > 0 ? (
      <div className="chartSection40" id="pdfGhgReportChart">
        <div className="chartContainer">
          <DoughnutChart id="GhgReportChart" data={chartData} />
        </div>
        <div className="chartInfo">
          <p>Your emissions were spread across scopes 1, 2 and 3 like this.</p>
          <p>
            Scope 1 emissions accounted for <b>{formatResult(scope_1_pct, 2)}</b> % of your total emissions, Scope 2
            emissions accounted for <b>{formatResult(scope_2_pct, 2)}</b> % of your total emissions and Scope 3
            emissions accounted <b>{formatResult(scope_3_pct, 2)}</b> % of your total emissions.
          </p>
          {!isFreeUser && <p>
            Your largest single source of emissions was: <b>{largest_subscope_display_name}</b>, accounting for{' '}
            <b>{formatResult(largest_subscope_pct, 2)}</b> % of your total emissions.
          </p>}
        </div>
      </div>
    ) : (
      <>{setShowGhgChart(false)}</>
    )
  }

  function IntensityText() {
    const { employee_intensity, revenue_intensity } = data.results_text.emissions_intensities?.variables
    return employee_intensity > 0 && revenue_intensity > 0 ? (
      <div className="resultText" id="pdfGhgReportIntensityText">
        <p>
          Emissions intensities are ratios between total emissions (Scope 1, 2 and 3) and a metric that reflects the
          size of a company. They allow for comparison of emissions data between different sized companies. The most
          common emissions intensity metrics are total emissions per revenue and total emissions per employee.
        </p>
        <p></p>Your emissions per revenue were <b>{formatResult(revenue_intensity, 2)}</b> kg CO2e/EUR, and your
        emissions per employee were <b>{formatResult(employee_intensity, 0)}</b> kg CO2e/Employee.
      </div>
    ) : (
      <>{setShowIntensityText(false)}</>
    )
  }
}
