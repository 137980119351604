import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBarContent from './NavBarContent'

export default function MobileNavbar({
  context,
  setNavShow,
}: {
  context: React.MutableRefObject<any>
  setNavShow: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <div className="modal fullScreen ">
      <div className="navbar">
        <div className="navbarHeader">
          <div className="navbarTitle">My Tasks</div>
          <FontAwesomeIcon
            icon={faXmark}
            className="clickable"
            onClick={() => setNavShow(false)}
            data-cy="close-mobile-menu"
          />
        </div>
        <NavBarContent context={context} setNavShow={setNavShow} />
      </div>
    </div>
  )
}
