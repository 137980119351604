import { faInfoCircle, faChevronCircleDown, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import ReactTooltip from 'react-tooltip'
import { useClient } from '../../../../context/ClientProvider'
import { useRegion } from '../../../../context/RegionProvider'
import { purchasedGoodCategories, purchasedGoodTooltips } from '../../../../data/inputs/purchasedGoods'
import { sortBySpend } from '../../../../functions/sort-by-spend'
import { formatMoney } from '../../../../utils/formatMoney'
import SubcategoryList from './SubcategoryList'

export default function CategoryItem({
  index,
  field,
  transactionData,
  setTransactionData,
  register,
}: {
  index: number
  field: any
  transactionData: any
  setTransactionData: any
  register: any
}) {
  const { country } = useRegion()
  const [showMcss, setShowMcss] = useState<any>({})
  const { isFreeUser } = useClient()

  const updateShowMcss = (code: string) => {
    setShowMcss({
      ...showMcss,
      [code]: !showMcss[code],
    })
  }

  const getEstimated = (code: string) => {
    const { totalAmount, additionalSpend } = transactionData?.categories?.[code] || {}
    return formatMoney((totalAmount || 0) + (additionalSpend || 0))
  }

  return (
    <div>
      <input type="hidden" {...register(`purchasedGoods.${index}.value`)} />
      <div className="categoryHeader">
        <div className="categoryTitle">
          {purchasedGoodCategories[field.spendCategoryCode]}{' '}
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="gray4Color"
            data-html={true}
            data-tip={ReactDOMServer.renderToString(
              <div className="tooltip">
                <p>{purchasedGoodTooltips(country.code)[field.spendCategoryCode]}</p>
              </div>,
            )}
          />
        </div>
        <div className="categoryTotal">
          {getEstimated(field.spendCategoryCode)} {country.defaultUnits.currency}
        </div>
      </div>
      {!isFreeUser && (
        <div className="estimatedAmount">
          Estimated amount based on transactions{' '}
          {formatMoney(transactionData?.categories?.[field.spendCategoryCode]?.totalAmount || 0)}{' '}
          {country.defaultUnits.currency}
        </div>
      )}

      {transactionData?.categories?.[field.spendCategoryCode]?.['mccs'] && !isFreeUser && (
        <div className="includedTransactions clickable" onClick={() => updateShowMcss(field.spendCategoryCode)}>
          <FontAwesomeIcon icon={showMcss[field.spendCategoryCode] ? faChevronCircleDown : faChevronCircleRight} />{' '}
          Included transactions
        </div>
      )}
      {showMcss[field.spendCategoryCode] && (
        <SubcategoryList
          code={field.spendCategoryCode}
          mccs={sortBySpend(Object.entries(transactionData?.categories?.[field.spendCategoryCode]?.['mccs']))}
          transactionData={transactionData}
          setTransactionData={setTransactionData}
        />
      )}
      <div className="additionalSpendContainer">
        <div className="additionalSpendLabel">
          {isFreeUser ? 'Spend' : 'Addtional spend (optional)'}{' '}
          {index === 0 && (
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="gray4Color"
              data-html={true}
              data-tip={ReactDOMServer.renderToString(
                <div className="tooltip">
                  <p>
                    Please add here the total expenses related to this category{' '}
                    {!isFreeUser &&
                      `that are not listed as "Included
                    transactions".`}
                  </p>
                </div>,
              )}
            />
          )}
        </div>
        <div className="additionalSpendInput">
          <input
            className="width100"
            type="number"
            step="any"
            data-cy={`add-spend-${field.spendCategoryCode}-input`}
            {...register(`purchasedGoods.${index}.value`, {
              valueAsNumber: true,
            })}
          ></input>
          {/* TODO: grab this from the company information currencyCode */}
          {country.defaultUnits.currency}
        </div>
      </div>
      <ReactTooltip place="right" type="dark" effect="float" />
    </div>
  )
}
